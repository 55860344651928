.my-profile-info {
  .blueberry-family-panel__form {
    margin-bottom: 48px;

    .form-button {
      margin-bottom: 24px 0;
    }

    .form-input {
      margin: 16px 0;
    }
  }
}
