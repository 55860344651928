.meta-ranking {

  .meta-ranking__header{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .meta-ranking__title {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    color: var(--ui-primary-color);
    text-transform: uppercase;
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
    letter-spacing: 1px;
  }

  .meta-ranking__content {
    background: rgba(0, 0, 0, 0.38);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 34px;
    margin: 8px 0;
    flex-direction: column;
    padding: 9px;
    border-radius: 12px;
  }

  .meta-ranking__scroll-wrapper {
    @include custom-thin-scrollbar;
    max-height: 320px;
    overflow-y: auto;
    width: 100%;
  }

  .meta-ranking__button {
    color: #FFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    cursor: pointer
  }
}
