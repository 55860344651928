.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.loading--display-default {
    height: 100vh;
  }

  &.loading--display-inline {
    height: 60px;

    .loading__balls {
      transform: scale(0.85) translateY(10px);
    }
  }

  &.loading--color-purple {
    .loading__balls-item {
      background: #7b87b5;
    }
  }
}

.loading__balls {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-item {
    // .LoaderBalls__wrapper__item
    $anim-drt: 0.4s;
    $anim-ease: cubic-bezier(0.6, 0.05, 0.15, 0.95);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;

    &:nth-child(1) {
      animation: bouncing $anim-drt alternate infinite $anim-ease;
    }

    &:nth-child(2) {
      animation: bouncing $anim-drt calc(#{$anim-drt}/ 4) alternate infinite $anim-ease backwards;
    }

    &:nth-child(3) {
      animation: bouncing $anim-drt calc(#{$anim-drt}/ 2) alternate infinite $anim-ease backwards;
    }
  }
}

@keyframes bouncing {
  0% {
    transform: translate3d(0, 10px, 0) scale(1.2, 0.85);
  }

  100% {
    transform: translate3d(0, -20px, 0) scale(0.9, 1.1);
  }
}
