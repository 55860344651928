.card-details__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .card-details__name-container {
    background-image: url('../../../../assets/img/avatar_card_rectangle.png');
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    width: 100%;
    height: 290px;
    margin-top: 24px;

    .card-details__name {
      color: #ffffff;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 34px;
      line-height: 42px;
      margin-top: 15px;
    }

    .card-details__image-coins {
      position: relative;
      margin-top: 29px;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .card-details__image-coins-price {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-29%, 78%);
        font-family: Poppins, sans-serif;
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
        line-height: 31px;
      }
    }
  }

  .card-details__name-container-pet {
    background-image: url('../../../../assets/img/avatar_card_rectangle_pet.png');
  }
}

.card-details__level {
  display: inline-block;
  width: auto;
}

.card-details__image {
  height: 145px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
  }

  .card-details__image-sprite {
    position: relative;
    width: 100%;
    height: calc(100% - 10px);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;

    &.item-detail-img_disabled {
      filter: brightness(0%) contrast(0.7);
    }
  }

  .card-details__image-locked {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.card-details__price {
  margin-bottom: 8px;
}

.card-details__name {
  width: 100%;
  font-family: Quicksand, sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #000000;
}

.card-details__description {
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #4d586c;
  line-height: 24px;
  padding: 0 24px;
}

.card-details__description-coins {
  margin-top: 40px;
}

.card-details__actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;

  div:last-child {
    margin-left: 24px;
  }
}

.item-detail__content-level {
  display: none;
}

@media only screen and (max-width: 520px) {
  .card-details__container .card-details__name-container--disabled {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('../../../../assets/img/card.png');
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    width: 100%;
    height: 290px;
    margin-top: 24px;
  }

  .card-details__image .card-details__image-sprite.item-detail-img_disabled {
    width: 183px;
    height: 183px;
    margin-top: -20px;
  }

  .card-details__content-level {
    left: 28px;
    top: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    z-index: 9;
  }
  .card-details__content-level-text {
    color: var(--Grays-white, #fff);
    text-align: right;
    font-family: Poppins, sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    display: flex;
    width: 96px;
    height: 39px;
    padding: 0px 10px 0px 12px;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    background: var(--Blueberry-Primary-Dark-blue, #3703a4);
    border-radius: 18px 0 0 18px;
    margin-left: -14px;
  }
  .card-details__name--hidden {
    display: none;
  }
}
