.onboarding-panel__backdrop {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
}

.onboarding-panel {
  width: 649px;
  padding: 24px 106px;
  border-radius: 0 8px 0 8px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--ui-bar-background);
  min-height: 175px;

  z-index: 101;
  position: absolute;
  bottom: 42px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1024px) and (orientation: landscape) {
    width: 560px;
  }

  @media (orientation: portrait) {
    bottom: 220px;
  }

  &.centered {
    width: 520px;
    height: 488px;
    align-items: center;
    padding: 18px 18px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #683fac;
    background-image: radial-gradient(50% 50% at 50% 0%,
        rgba(252, 90, 255, 0.5) 0%,
        rgba(198, 106, 255, 0) 100%),
      radial-gradient(50% 50% at 50% 100%,
        rgba(252, 90, 255, 0.5) 0%,
        rgba(198, 106, 255, 0) 100%);
    background-position: top, bottom, center;
    background-repeat: no-repeat;
    background-size: 100% 50%, 100% 50%, 50% 100%;

    @media (orientation: portrait) {
      bottom: unset;
    }
  }

  &.onboarding-panel--intro {
    min-height: auto;
  }
}

.onboarding-panel__audio {
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
}

.onboarding-panel__audio path {
  fill: #5d5959;
}

.onboarding-panel__audio .onboarding-panel__close-icon svg path {
  fill: var(--Grays-gray-04, #7a8598);
}

.onboarding-panel__close-icon {
  position: absolute;
  right: -3px;
  top: -11px;
}

.onboarding-white-design {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95vw;
  max-width: 800px;
  padding: 24px 190px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);

  z-index: 101;
  position: absolute;
  bottom: 42px;
  left: 50%;
  transform: translateX(-50%);

  gap: 24px;

  border-radius: 24px;
  background: var(--white, #fff);
  box-shadow: -7px -5px 4px 0px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 600px) {
    padding: 24px 70px;
  }
}

.onboarding-panel-without-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95vw;
  max-width: 800px;
  padding: 24px 70px;

  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-height: 200px;

  gap: 24px;

  z-index: 101;
  position: absolute;
  bottom: 42px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 24px;
  background: var(--white, #fff);
  box-shadow: -7px -5px 4px 0px rgba(0, 0, 0, 0.25);
}

.onboarding-panel__margin {
  p {
    margin-top: 20px;
  }
}

// deprecate

.onboarding-panel__text-title {
  color: var(--Blueberry-Primary-Medium-blue, #5a34e1);
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  margin-top: -30px;
  // width: 418px;
  width: auto;

  h3 {
    margin-bottom: -16px;
    white-space: nowrap;
  }

  p {
    color: var(--Grays-gray-04, #7a8598);
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: center;
    margin-top: 24px;
  }
}

.onboarding-panel__text-title__complete {
  text-align: center;
  font-size: 24px;

  p {
    line-height: 24px;
    color: var(--Grays-gray-04, #7a8598);
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-top: 8px;
    width: auto;
  }
}

// deprecate

.onboarding-panel__title {
  font-family: Quicksand, sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: var(--ui-modal-header-text-color);
  text-align: center;

  strong {
    font-family: Quicksand, sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: var(--ui-modal-strong-color);
  }
}

.onboarding-panel__text {
  font-family: Quicksand, sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: var(--ui-modal-header-text-color);
  text-align: center;
  line-height: 22px;

  strong {
    font-family: Quicksand, sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: var(--ui-modal-strong-color);
  }

  p {
    line-height: 22px;
  }

  path {
    fill: var(--ui-modal-close-color);
  }

  &.left {
    left: 20px;
    right: unset;
  }

  .onboarding-panel__text-with-icon {
    display: inline-block;
    margin-top: 0;

    span {
      margin: 0 8px;
      vertical-align: middle;

      svg path {
        fill: var(--ui-modal-content-text-color);
      }
    }
  }

  &.large {
    font-size: 24px;
    line-height: 28px;
    margin: 0 56px;

    strong {
      font-size: 24px;
    }

    p {
      line-height: 32px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.onboarding-panel__text-auto {
  text-align: center;
  font-size: 24px;

  p {
    color: var(--Grays-gray-04, #7a8598);
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    width: auto;
    margin-top: 0;
  }
}

.onboarding-panel__radius-image {
  border-radius: 8px;
  margin: 0 auto;
}

.onboarding-panel__daily-goal {
  margin: 12px auto;
  height: 44px;
  display: flex;
  align-items: center;
}

.onboarding-panel__image img {
  margin: 12px auto;
}

.onboarding-panel__image--no-margin img {
  margin: 0 auto;
}

.onboarding-panel__level-image {
  position: relative;

  .onboarding-panel__level-image-text {
    font-family: Quicksand, sans-serif;
    font-size: 28px;
    color: white;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 2px;
  }
}

.onboarding-panel__content {

  // deprecate
  p {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    text-align: left;
    color: var(--ui-modal-content-text-color);
  }

  .onboarding-panel__text-with-icon {
    display: inline-block;
    margin-top: 0;

    span {
      margin: 0 6px;
      vertical-align: middle;

      svg path {
        fill: var(--ui-modal-content-text-color);
      }
    }
  }
}

.onboarding-panel__button {
  color: var(--Blueberry-Primary-Medium-blue, #5a34e1);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 36px;
  border: 1px solid var(--Grays-white, #fff);
  background: var(--Grays-white, #fff);
  width: 192px;
  cursor: pointer;
  margin-top: 20px;
}

.onboarding-panel__button--inline {
  width: 100%;
}

.onboarding-panel__button--no-margin {
  margin: 8px auto 0;
  margin-bottom: 20px;
}

.onboarding-panel_text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.onboarding-panel__button-purple-design {
  text-align: center;
  width: 219px;
  padding: 12px 32px;
  border-radius: 36px;
  background: var(--Blueberry-Primary-Medium-blue, #5a34e1);

  color: var(--Grays-white, #fff);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
  margin-top: 8px;
}

.onboarding-panel__bb-image {
  position: absolute;
  bottom: 6px;
  left: -36px;
  z-index: 1;
}

.onboarding-panel__bb-image_new-popup {
  position: absolute;
  bottom: 14px;
  left: -10px;
  z-index: 1;
}

.onboarding-blueberro-mobile-image {
  position: fixed;
  z-index: 1;
  width: 75px;
  height: 75px;
  bottom: calc(100% - 7px);
  left: 40px;
}

.onboarding-humans-cartoons-mobile-image {
  position: absolute;
  width: 131px;
  height: 131px;
  top: -192px;
  left: -30px;
}

.onboarding-panel__bb-image__panel-5 {
  position: absolute;
  bottom: -8px;
  left: -26px;
}

@media screen and (max-width: 600px) {
  .onboarding-blueberro-desktop-image {
    display: none;
  }

  .onboarding-panel__bb-image__panel-5 {
    display: none;
  }
}

// Botón invisible para reset de onboarding
.onboarding__debug {
  position: fixed;
  top: 0;
  left: 10px;
  z-index: 1;
  height: 45px;
  width: 45px;
  cursor: pointer;
}

// Onboarding Introducción
.onboarding-intro {
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: 102;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .onboarding-intro__background {
    position: relative;
    height: 100%;
    width: 100%;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 90%;
      width: 90%;
      object-fit: contain;
    }

    .onboarding-intro__background-img {
      opacity: 0;
      pointer-events: none;
      object-fit: cover;
      width: 100%;
      height: 100%;
      touch-action: none;
      -webkit-overflow-scrolling: none;
      overflow: hidden;
      user-select: none;
      -webkit-user-select: none;
    }
  }

  .shake {
    animation: shake 0.6s;
    animation-delay: 1.3s;
  }

  .fade-out {
    animation: fade-out 1s ease-out both;
  }

  .fade-in {
    animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
  }

  .fade-in-cross {
    animation: fade-in 1s ease-in both;
  }

  .fade-in-start {
    animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }

  &.fade-out-final {
    animation: fade-out 1s ease-out 1s both;
  }

  .onboarding-intro__avatar-selector {
    position: absolute;

    border-radius: 24px;

    height: 70%;
    width: 90vw;
    max-width: 1030px;

    z-index: 101;
    padding: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg) scale(1);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg) scale(1.5);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg) scale(1.5);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg) scale(1.5);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg) scale(1.5);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg) scale(1.5);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg) scale(1.5);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg) scale(1.5);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg) scale(1.5);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg) scale(1.5);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg) scale(1);
  }
}

.information-popup_message-wrapper-in p {
  position: absolute;
  top: 11%;
  left: 23%;
  color: var(--Gray-scale-white, #fff);
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.onboarding-panel_dot-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.onboarding-panel-dot {
  border-radius: 9px;
  background: #fff;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.onboarding-panel-empty-dot {
  cursor: pointer;
}

.onboarding-popup__container {
  width: 649px;
  padding: 24px 106px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  min-height: 175px;

  z-index: 101;
  position: absolute;
  bottom: 42px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  justify-content: center;
  flex-direction: column;

  border-radius: 8px;

  height: fit-content;
  background: #683fac;
  background-image: radial-gradient(50% 50% at 50% 0,
      #fc5aff80 0,
      #c66aff00 100%),
    radial-gradient(50% 50% at 50% 100%, #fc5aff80 0, #c66aff00 100%),
    radial-gradient(50% 50% at 50% 50%, #fc5aff59 0, #c66aff00 100%);

  @media (max-width: 1024px) and (orientation: landscape) {
    width: 560px;
  }

  @media (orientation: portrait) {
    bottom: 220px;
  }

  &.centered {
    width: 520px;
    align-items: center;
    padding: 18px 18px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
    background: #683fac;
    background-image: radial-gradient(50% 50% at 50% 0%,
        rgba(252, 90, 255, 0.5) 0%,
        rgba(198, 106, 255, 0) 100%),
      radial-gradient(50% 50% at 50% 100%,
        rgba(252, 90, 255, 0.5) 0%,
        rgba(198, 106, 255, 0) 100%);
    background-position: top, bottom, center;
    background-repeat: no-repeat;
    background-size: 100% 50%, 100% 50%, 50% 100%;

    @media (orientation: portrait) {
      bottom: -358px;
    }
  }
}

.onboarding-popup__container-with-image {
  width: 405px;
  padding: 24px 106px;

  min-height: 175px;

  z-index: 101;
  position: absolute;
  bottom: 42px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  justify-content: center;
  flex-direction: column;

  border-radius: 8px;
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.25),
    0px 2px 0px 0px #9940c3 inset;

  height: fit-content;
  background-color: #683fac;
  background-image: radial-gradient(50% 50% at 50% 0,
      #fc5aff80 0,
      #c66aff00 100%),
    radial-gradient(50% 50% at 50% 100%, #fc5aff80 0, #c66aff00 100%),
    radial-gradient(50% 50% at 50% 50%, #fc5aff59 0, #c66aff00 100%);

  @media (max-width: 1024px) and (orientation: landscape) {
    width: 560px;
  }

  @media (orientation: portrait) {
    bottom: 220px;
  }

  &.centered {
    width: 405px;
    align-items: center;
    padding: 18px 18px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 228px;
    background: #683fac;
    background-image: radial-gradient(50% 50% at 50% 0%,
        rgba(252, 90, 255, 0.5) 0%,
        rgba(198, 106, 255, 0) 100%),
      radial-gradient(50% 50% at 50% 100%,
        rgba(252, 90, 255, 0.5) 0%,
        rgba(198, 106, 255, 0) 100%);
    background-position: top, bottom, center;
    background-repeat: no-repeat;
    background-size: 100% 50%, 100% 50%, 50% 100%;

    @media (orientation: portrait) {
      bottom: unset;
      margin-right: 15px;
      height: 269px;
    }
  }
}

.onboarding-popup__text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 471px;
  position: relative;
  margin-bottom: 18px;
}

.onboarding-popup__text-container-little {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 471px;
  position: relative;
  margin-bottom: 40px;
}

.onboarding-popup__text-background {
  width: 470px;
  position: absolute;
}

.onboarding-popup__text {
  width: 360px;
  color: var(--Gray-scale-white, #fff);
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

.onboarding-popup__audio {
  cursor: pointer;
  margin-left: 20px;
  z-index: 1;
}

.onboarding-popup__image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 411px;
  height: 205px;
  border-radius: 24px;
  margin-top: 20px;
  margin-bottom: 20px;

  .onboarding-popup__light-image {
    mix-blend-mode: screen;
    z-index: 1;
    margin-top: -158px;
    margin-bottom: 0px;
    width: 60%;
  }
}

.onboarding-popup__image {
  width: 100%;
  height: 205px;
  margin-top: 24px;
  margin-bottom: 24px;
  align-self: stretch;
  border-radius: 24px;
  object-fit: cover;
}

.onboarding-popup__second-image {
  margin-bottom: 24px;
  //align-self: stretch;
  object-fit: cover;
  margin-left: 0px;
  margin-top: 5px;
  width: 50%;
}

.onboarding-popup__dots-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 40px;
  gap: 10px;
}

.onboarding-popup__close-icon-new {
  position: absolute;
  right: -5px;
  top: -7px;
  cursor: pointer;
}

.onboarding-popup__dot {
  cursor: pointer;
}

.onboarding-popup__button {
  color: var(--Blueberry-Primary-Medium-blue, #5a34e1);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 36px;
  border: 1px solid var(--Grays-white, #fff);
  background: var(--Grays-white, #fff);
  cursor: pointer;
  margin-top: 20px;
}

.onboarding-popup__backdrop {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
}

.onboarding-popup__bb-image-container {
  position: absolute;
  top: -148px;
  left: 54px;
}

.onboarding-popup__text-little-background {
  width: 384px;
  height: 90px;
  flex-shrink: 0;
  position: absolute;
  bottom: -56px;
}

.onboarding-popup__little-text {
  color: var(--Gray-scale-white, #fff);
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  bottom: -58px;
  width: 244px;
  left: 115px;
}

.onboarding-popup__little-audio {
  cursor: pointer;
  margin-left: 20px;
  z-index: 1;
  position: absolute;
  right: 76px;
  top: 85px;
}

@media screen and (max-width: 520px) {
  .onboarding-white-design {
    min-width: unset;
    width: 334px;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background: var(--white, #fff);
    box-shadow: -7px -5px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .onboarding-popup__container-with-image {
    &.centered {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .onboarding-popup__close-icon-new {
        position: absolute;
        right: -5px;
        top: -7px;
        display: block;
        z-index: 9999;
      }
    }
  }

  .drawer-box.drawer-box--bottom {
    .onboarding-popup__container.centered {
      .onboarding-popup__text-container {
        margin-top: 0px;
        margin-bottom: -18px;
      }
    }
  }

  .onboarding-popup__text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    position: relative;
    margin-top: -100px;
  }

  .onboarding-popup__text-little-background {
    width: 100%;
    bottom: unset;
    height: 100%;
    margin-bottom: 10px;
  }

  .onboarding-popup__little-audio {
    top: 22px;
    right: 20px;

    svg {
      width: 25px;
      height: 25px;
    }
  }

  .onboarding-popup__bb-image-container {
    position: relative;
    top: -102px;
    margin: 0 auto;
    right: unset;
    left: unset;
    transform: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .onboarding-popup__little-text {
    position: static;
    bottom: unset;
    left: unset;
    width: 245px;
    font-size: 18px;
    margin-top: 10px;
  }

  .onboarding-panel-without-image {
    min-width: unset;
    width: 334px;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background: var(--white, #fff);
    box-shadow: -7px -5px 4px 0px rgba(0, 0, 0, 0.25);

    .onboarding-panel__text-auto {
      p {
        color: var(--Grays-gray-04, #7a8598);
        text-align: center;

        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }

  .onboarding-panel__text-title {
    h3 {
      color: var(--Blueberry-Primary-Medium-blue, #5a34e1);
      text-align: center;
      font-family: Poppins, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    p {
      color: var(--Grays-gray-04, #7a8598);
      text-align: center;

      font-family: Poppins, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }

  .onboarding-panel_text-container {
    .onboarding-panel__text-title {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 250px;
    }
  }

  .onboarding-intro .onboarding-intro__background {
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    .onboarding-intro__background-img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .onboarding-panel__audio {
    right: 12px;
    top: 14px;
  }

  .onboarding-popup__close-icon-new {
    display: none;
  }

  .onboarding-popup__container.centered {
    border-radius: 20px 20px 0px 0px;
    width: 100vw;
    height: fit-content;
  }

  .onboarding-popup__text-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .onboarding-popup__image-container {
    width: 328px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .onboarding-popup__light-image {
      mix-blend-mode: screen;
      z-index: 1;
      margin-top: -130px;
      margin-bottom: 10px;
      width: 60%;
    }
  }

  .onboarding-popup__second-image {
    margin-left: 0px;
    margin-top: 5px;
    width: 55%;
    object-fit: cover;
  }

  .onboarding-popup__audio {
    margin-left: 0px;
    z-index: 2;
    position: absolute;
    bottom: 42px;
    right: 21px;
  }

  .onboarding-popup__text {
    width: auto;
    margin-left: 40px;
    margin-right: 40px;
  }

  .onboarding-popup__dots-container {
    margin-top: 0px;
  }

  .onboarding-popup__button {
    margin-bottom: 20px;
    display: flex;
    width: 262px;
    align-items: flex-end;
    gap: 16px;
  }

  .reward-popup__information-container-with-image.centered {
    .onboarding-popup__bb-image-container {
      position: absolute;
      top: -100px;
    }

    .onboarding-popup__text-container-little {
      margin-top: 170px;
      width: 100%;
      height: 90px;
    }

    .onboarding-popup__close-icon-new {
      display: block;
      z-index: 9999;
    }
  }
}

@media screen and (min-width: 376px) and (max-width: 520px) {
  .onboarding-popup__text {
    width: 240px;
  }

  .onboarding-popup__audio {
    bottom: 40px;
    right: 32px;
  }
}

@media screen and (min-width: 360px) and (max-width: 375px) {
  .onboarding-popup__audio {
    bottom: 40px;
    right: 12px;
  }
}

.onboarding-popup__text--first {
  color: var(--Gray-scale-white, #fff);
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

.onboarding-popup__text--second {
  color: var(--Gray-scale-white, #fff);
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

@media screen and (min-width: 375px) and (max-width: 474px) {
  .onboarding-popup__container.centered {
    bottom: -347px;
  }
}

@media screen and (min-width: 475px) and (max-width: 520px) {
  .onboarding-popup__container.centered {
    bottom: -347px;
  }
}
