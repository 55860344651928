.item-detail-pet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  cursor: pointer;

  background-image: url('../../../../assets/img/pompa.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  &:hover {
    .item-detail-pet__wrapper {
    }
  }

  .item-detail-pet__wrapper {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
  }

  .item-detail-pet__check {
    position: absolute;
    right: 13px;
    bottom: 11px;
    z-index: 3;
    height: 30px;
    width: 30px;
  }

  .item-detail-pet__header {
    position: absolute;
    top: 8px;
    z-index: 2;
    width: 200px;
    height: 36px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .item-detail-pet__image {
    height: 140px;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 20px;
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    .item-detail-pet__locked {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .item-detail-pet__image-l {
    height: 160px;
    width: 160px;
  }

  .item-detail-pet__image-m {
    height: 140px;
    width: 140px;
  }

  .item-detail-pet__image-s {
    height: 120px;
    width: 120px;
    img {
      width: 90%;
      height: 90%;
    }
  }

  .item-detail-pet__image-xs {
    height: 90px;
    width: 90px;
  }

  .item-detail-pet__image--locked {
    background: transparent;
    border: none;
  }

  img.item-detail-pet-img_disabled {
    filter: brightness(0) contrast(0.7);

    & ~ .item-detail-pet__content__content-coins {
      display: none;
    }
  }
}

.item-detail-pet-selected {
  background-image: url('../../../../assets/img/pompa_select.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.item-detail-pet__button {
  display: flex;
  height: 36px;
  padding: 8px 16px;
  align-items: center;
  color: #1c292b;
  text-align: center;
  font-family: Quicksand;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.item-detail-pet__button--default {
  border-radius: 12px;
  border: 2px solid #576b70;
  background: #fff;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.1);
}

.item-detail-pet__button--available {
  border-radius: 40px;
  border: none;
  background: #ffaf22;
  box-shadow: none;
}

.item-detail-pet__button--disabled {
  border-radius: 40px;
  border: none;
  background: #f2f2f2;
  box-shadow: none;
  pointer-events: none;
}

.swiper-slide-prev,
.swiper-slide-next {
  .item-detail-pet__image {
    height: 100px;
    width: 100px;
  }
}

.item-detail-pet__content-item-detail-pet {
  position: relative;

  .item-detail-pet__content__content-coins {
    display: inline-flex;
  }

  // Cuando el contenedor padre tiene una imagen deshabilitada
  .item-detail-pet__image.item-detail-pet-img_disabled {
    filter: brightness(0) contrast(0.7);
  }

  // Ocultar coins cuando cualquier elemento dentro del contenedor tiene la clase disabled
  &:has(.item-detail-pet-img_disabled) {
    .item-detail-pet__content__content-coins {
      display: none;
    }
  }
}

.item-detail-pet__content-level {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  .item-detail-pet__content-level-text {
    color: #ffffff;
    background-color: #3703a4;
    padding: 6px 12px;
    border-radius: 18px 0 0 18px;
    margin-left: -20px;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
  }
  .map-level-counter {
    position: absolute;
    right: -27px;
    .map-level-counter-text {
      top: 3px;
      font-size: 18px;
    }
  }
}

.item-detail-pet__content-header {
  display: inline-flex;
  width: 100%;
  position: absolute;
  justify-content: space-evenly;
  top: -10px;

  @media screen and (max-width: 520px) {
    display: flex;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    top: -5px;
    flex-direction: row;
    flex-wrap: nowrap;

    // Elemento izquierdo (level)
    .item-detail-pet__content-level {
      margin: 0;
      position: relative;
      left: 0;
      flex-shrink: 0;
      margin-left: 34px;
    }

    // Elemento central (si existe)
    .item-detail-pet__content-center {
      position: relative;
      left: auto;
      transform: none;
      flex-shrink: 0;
    }

    // Elemento derecho (coins)
    .item-detail-pet__content__content-coins {
      margin: 0;
      position: relative;
      right: 0;
      flex-shrink: 0;
      margin-left: 27px;
    }
  }
}

.item-detail-pet__content__content-coins {
  height: 45px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  width: 30px;

  .item-detail-pet__content__content-coins-text {
    color: #ffffff;
    background-color: #3703a4;
    padding: 6px 15px;
    border-radius: 18px 0 0 18px;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
  }

  .item-detail-pet__content__content-coins-icon {
    position: absolute;
    right: -27px;
    top: 9px;

    svg {
      height: 30px;
      width: 30px;
    }
  }
}

.item-detail-pet-img_disabled {
  filter: brightness(0) contrast(0.7);

  & ~ .item-detail-pet__content-item-detail-pet {
    .item-detail-pet__content__content-coins {
      display: none;
    }
  }
}

.item-detail-pet__content-header--no-coins {
  display: flex;
  align-items: center;
  position: absolute;
  left: 10px;

  .map-level-counter {
    .map-level-counter-text {
      top: -5px;
    }
  }
}

@media screen and (max-width: 520px) {
  .item-detail-pet .item-detail-pet__image-s img {
    width: auto;
    height: 72px;
  }

  .item-detail-pet .item-detail-pet__wrapper {
    width: 107px;
    height: 107px;
    justify-content: center;
  }

  .item-detail-pet__content__content-coins
    .item-detail-pet__content__content-coins-icon
    svg {
    width: 22px;
    height: 22px;
  }

  .item-detail-pet__content-header {
    display: flex;
    width: 50px;
    height: 20px;
    padding: 0px 5px 0px 6px;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    left: 0px;
  }

  .item-detail-pet__content-header--no-coins {
    .item-detail-pet__content-level .map-level-counter {
      right: -44px;
      top: 9px;
    }
  }

  .item-detail-pet__content-level .item-detail-pet__content-level-text {
    display: none;
  }

  .item-detail-pet__content-level .map-level-counter {
    right: -22px;
  }

  .item-detail-pet__content-level .map-level-counter .map-level-counter-text {
    top: -20px;
  }

  .item-detail-pet__content__content-coins
    .item-detail-pet__content__content-coins-text {
    display: flex;
    width: 40px;
    height: 20px;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    justify-content: center;
  }
  .item-detail-pet__content__content-coins
    .item-detail-pet__content__content-coins-icon {
    right: -18px;
    top: 10px;
  }

  .item-detail-pet .item-detail-pet__check {
    right: 0px;
  }
}

@media screen and (min-width: 360px) {
  .item-detail-pet__content-header--no-coins
    .item-detail-pet__content-level
    .map-level-counter {
    right: -43px;
    top: 12px;
  }
  .item-detail-pet__content-header {
    left: -12px;
  }
}

@media screen and (min-width: 361px) and (max-width: 419px) {
  .item-detail-pet__content-header--no-coins
    .item-detail-pet__content-level
    .map-level-counter {
    right: -56px;
    top: 12px;
  }
  .item-detail-pet__content-header {
    left: 8px;
  }
}

@media screen and (min-width: 420px) and (max-width: 520px) {
  .item-detail-pet__content-header--no-coins
    .item-detail-pet__content-level
    .map-level-counter {
    right: -32px;
    top: 10px;
  }

  .item-detail-pet__content-header {
    left: -2px;
  }
}

@media screen and (min-width: 421px) and (max-width: 460px) {
  .item-detail-pet__content-header {
    left: -4px;
  }
}

@media screen and (min-width: 521px) and (max-width: 768px) {
  .item-detail-pet__content-header--no-coins
    .item-detail-pet__content-level
    .map-level-counter {
    right: -28px;
    top: 0px;
  }

  .item-detail-pet__content__content-coins
    .item-detail-pet__content__content-coins-icon {
    position: absolute;
    right: -26px;
    top: 8px;

    svg {
      height: 30px;
      width: 30px;
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 2920px) {
  .item-detail-pet__content-header--no-coins
    .item-detail-pet__content-level
    .map-level-counter {
    right: -25px;
    top: 0px;
  }
  .item-detail-pet__content-header {
    left: -10px;
  }
}
