.modal .modal__content {
  width: 550px;
  box-sizing: border-box;
  box-shadow: 0px 8px 0px $shadow-grey;
}

.modal .modal__body {
  text-align: center;
  padding: 16px;

  h1 {
    font-family: $primary-font;
    color: $blue;
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    margin-top: 0;
    margin-bottom: 32px;
  }

  p {
    font-family: $secondary-font;
    color: $gray-3;
    font-size: 16px;
    line-height: 21px;
  }

  .practice-label__highlight {
    font-family: $primary-font;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: $secondary-color;
  }
}

.modal .modal__controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;

  div {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
}
