.signup-form {
  display: flex;
  justify-content: center;
  align-items: center;

  .form-input {
    margin-top: 12px;
  }

  .signup-form__row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;

    .form-input {
      width: 100%;
      flex-grow: 1;
      margin-right: 8px;
      margin-top: 0;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
        margin-left: 8px;
      }
    }
  }

  .signup-form__terms {
    display: block;
    text-align: center;
    margin-top: 16px;
  }

  .signup-form__terms-text {
    font-family: 'Poppins', sans-serif;
    color: #3E3A68;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
  }

  .signup-form__terms-link {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    color: #6363fc;
    cursor: pointer;
  }

  .blueberry-family-panel__actions {
    margin-top: 32px;
  }
}

.signup-form__terms-popup {
  .form-button {
    width: 376px;
  }

  .terms__scroll-text-wrapper {
    height: 252px;
    background: #FFFFFF;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 8px 6px 8px 16px;
    overflow: hidden;
  }

  .terms__scroll-text {
    @include custom-family-scrollbar;
    scrollbar-gutter: stable;
    height: 100%;
    overflow: auto;
    padding-right: 10px;

    font-family: 'Times', serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    h3 {
      font-family: 'Quicksand', sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #576B70;
      margin: 1em auto;
      width: 100%;
    }

    h5 {
      font-family: 'Quicksand', sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #576B70;
      margin: 1em 0;
    }

    p,
    li {
      font-family: 'Times', serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #576B70;
    }

    li {
      margin-left: -1.5em;
    }

    ol {
      list-style-type: lower-roman;
    }

    a {
      font-family: 'Quicksand', sans-serif;
      font-weight: 700;
      font-size: 12px;
      color: #6363FC;
      text-decoration: underline;
      margin-inline: 3px;
      cursor: pointer;
      display: inline-block
    }
  }
}

.google-signup-button__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative
}

.google-signup-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 40px;
  border-radius: 8px;
  width: 333px;
}

.signup-form__separator {
  width: 100%;
  margin: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  height: 22px;

  .signup-form__separator-line {
    display: inline-block;
    flex-grow: 1;
    width: 100%;
    height: 2px;
    background: rgba(195, 194, 207, 0.6);
    border-radius: 10px;
  }

  .signup-form__separator-text {
    display: inline-block;
    margin: 0 12px;
    flex-grow: 0;
    color: #88A7B8;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
}

.signup-form__login-actions {
  display: flex;
  justify-content: center;
}


.signup-form__login-text {
  color: #3E3A68;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-right: 6px;
}

.signup-form__login-link {
  color: #6363FC;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
}

.signup-form__login-next-button {
  margin-top: 24px;
}
