.streak-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.streak-progress__bullet {
  width: 34px;
  height: 34px;
  z-index: 2;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.streak-progress__connector {
  width: 100px;
  height: 13px;
  margin: 0 -14px;
  z-index: 1;
}

.streak-progress__connector--on {
  background: #06bf85;
  box-shadow: 0px 2px 0px 0px #00e19a inset;
}

.streak-progress__connector--off {
  background: #3703a4;
}
