@import '../../../../theme/_media-queries.scss';

.grid-container {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 10px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 35px;
  margin-bottom: 21px;

  @media (max-width: $breakpoint-mobile) {
    grid-template-columns: auto 1fr;
  }
  
  @media (max-height: $breakpoint-mobile) {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 102;

  .item-content-timer {
    margin-left: 24px;
    white-space: nowrap;

    @media (max-width: $breakpoint-tablet) {
      margin-left: 16px;
    }
  }

  &.counter-question {
    margin-right: 24px;

    @media (max-width: $breakpoint-tablet) {
      margin-right: 16px;
    }

    @media (max-width: $breakpoint-mobile) {
      display: none;
    }

    .item-content {
      white-space: nowrap;
    }
  }

  &.progress-bar-container {
    justify-content: center;
    width: 100%;
    padding: 0;
  }
}

.item-content-timer {
  height: 40px;
  width: 100%;
  padding: 12px 24px;
  border-radius: 52px;
  background: rgba(0, 0, 0, 0.72);
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  @include inter-font($size: 16px, $weight: 700);
  font-variant-numeric: tabular-nums;
  
  &.item-content-timer_alert {
    background-color: red;
    animation: pulseClock 1s infinite;
  }

  @keyframes pulseClock {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
}

.item-content {
  height: 40px;
  padding: 12px 24px;
  border-radius: 52px;
  background: #5a34e1;
  color: #fff; /* Puedes cambiar esto a tu color de texto preferido */
  @include inter-font($size: 16px, $weight: 700);
  line-height: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.grid-item.progress-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-bar {
  background: #ffffff;
  height: 40px;
  border-radius: 52px;
  padding: 3px;
  box-sizing: border-box;
  width: 81%;
  position: relative;
  
  @media (max-width: $breakpoint-desktop) {
    width: 90%;
  }
  
  @media (max-width: $breakpoint-tablet) {
    width: 96%;
  }
  
  @media (max-width: $breakpoint-mobile) {
    width: 100%;
    margin-right: 16px;
  }

  .progress-bar-background {
    position: absolute;
    height: 15px;
    width: calc(100% - 32px);
    background: #acacf7;
    border-radius: 52px;
    transform: translateY(10px);
    left: 16px;
  }

  .progress-bar-fill {
    position: absolute;
    height: 15px;
    background: linear-gradient(180deg, #7c3de5 0%, #3c3cbf 100%);
    border-radius: 52px;
    transform: translateY(10px);
    left: 16px;
  }

  .imgPlanet {
    position: absolute;
    width: 64px;
    height: 64px;
    right: 16px;
    transform: translate(50%, -13px);
    
    @media (max-width: $breakpoint-tablet) {
      width: 51px;
      height: 51px;
      transform: translate(50%, -7px);
    }
  }

  .imgAvatar {
    position: absolute;
    width: 64px;
    height: 64px;
    top: 50%;
    left: 0;
    transform: translate(-32px, -30px);
    z-index: 10;
    
    @media (max-width: $breakpoint-tablet) {
      width: 51px;
      height: 51px;
      transform: translate(-25.5px, -26px);
    }
  }
}
