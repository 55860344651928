.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .error-page__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;

    .error-page__title {
      font-family: Quicksand;
      font-size: 42px;
      font-weight: bold;
      color: #333;
      margin-top: 32px;
      text-align: center;
      width: 40%;
    }

    .error-page__subtitle {
      font-family: Quicksand;
      font-size: 20px;
      font-weight: bold;
      color: #4e4e4e;
      margin-top: 32px;
      text-align: center;
      width: 40%;
    }

    .simple-button {
      margin-top: 40px;
    }
  }
}
