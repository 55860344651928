.confirm-popup__backdrop {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  // backdrop-filter: blur(20px);
  z-index: 1010;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-popup {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;
  border-radius: 16px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 1020;

  .confirm-popup__content {
    display: flex;
    width: 100%;
  }

  .confirm-popup__left {
    width: 138px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 24px;
  }

  .confirm-popup__right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .confirm-popup__actions {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    gap: 8px;
    padding-top: 16px;
  }
}

.confirm-popup--size-sm {
  max-width: 460px;

  @media only screen and (max-width: 600px) {
    padding: 24px !important;
    height: auto !important;
    width: 100vw;
  }

  @media only screen and (max-width: 460px) {
    width: 100%;
    border-radius: 0;
  }
}

.confirm-popup--size-default,
.confirm-popup--size-md {
  max-width: 610px;
}

.confirm-popup--size-lg {
  max-width: 760px;
}

.confirm-popup__button {
  display: flex;
  height: 36px;
  padding: 8px 16px;
  align-items: center;
  color: #1c292b;
  text-align: center;
  font-family: Quicksand;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.confirm-popup__button--default {
  display: flex;
  width: 114px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 36px;
  border: 2px solid var(--Blueberry-Primary-Medium-blue, #5a34e1);
  color: var(--Blueberry-Primary-Medium-blue, #5a34e1);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.confirm-popup__button--available {
  display: flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 36px;
  background: var(--Blueberry-Primary-Medium-blue, #5a34e1);
  color: var(--Grays-white, #fff);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.confirm-popup__button--disabled {
  border-radius: 40px;
  border: none;
  background: #f2f2f2;
  box-shadow: none;
  pointer-events: none;
}

@media only screen and (max-width: 520px) {
  .confirm-popup--size-sm {
    display: flex;
    width: 345px;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
    background: #fff;
    position: fixed;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .confirm-popup__actions {
    display: flex;
    width: 333px;
    padding-top: 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
}
