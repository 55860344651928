.practice-button {
  display: flex;
  flex: 1;
  max-height: 48px;
  align-items: center;
  justify-content: center;
  border-radius: 36px;
  padding: 0 32px;
  width: fit-content;
  min-width: fit-content;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  gap: 8px;

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .practice-button_loading {
    height: 1em;
    width: 1em;
    padding: 0;
    margin: 0;
    animation: rotating 1.5s linear infinite;
  }

  // Primario
  color: #ffffff;
  background-color: #5a34e1;
  path {
    fill: #ffffff;
  }
  &:active {
    background-color: #3703a4;
  }
  &.active {
    background-color: #3703a4;
  }

  // Secundario
  &.type-2 {
    color: #5a34e1;
    background-color: #ffffff;

    path {
      fill: #5a34e1;
    }

    &:active {
      background-color: #acacf7;
    }
    &.active {
      background-color: #acacf7;
    }
  }

  // Terciario
  &.type-3 {
    color: #5a34e1;
    background-color: #eff1fa;
    border: none;

    path {
      fill: #5a34e1;
    }

    &:active {
      background-color: #acacf7;
    }
    &.active {
      background-color: #acacf7;
    }
  }

  // Outline
  &.type-4 {
    color: #ffffff;
    background-color: transparent;
    border: solid #ffffff 2px;

    path {
      fill: #ffffff;
    }

    &:active {
      color: #3703a4;
      border: solid #3703a4 2px;

      path {
        fill: #3703a4;
      }
    }

    &.active {
      color: #3703a4;
      border: solid #3703a4 2px;

      path {
        fill: #3703a4;
      }
    }
  }
  // Terciario
  &.type-5 {
    color: #5a34e1;
    background-color: #eff1fa;
    border: none;
    width: 308px;
    margin-top: 10px;

    path {
      fill: #5a34e1;
    }

    &:active {
      background-color: #acacf7;
    }
    &.active {
      background-color: #acacf7;
    }
  }
  // Deshabilitado
  &.disabled {
    background-color: #f0f4f8;
    color: #afb7c6;

    path {
      fill: #afb7c6;
    }
  }

  &.icon-only {
    width: fit-content !important;
    max-width: fit-content !important;
    min-width: fit-content !important;
    padding: 0 21px !important;

    @media screen and (max-width: 520px) {
      width: 32px !important;
      height: 32px !important;
      min-width: 32px !important;
      max-width: 32px !important;
      padding: 0 !important;
    }
  }

  &.swap-icon-text {
    flex-direction: row-reverse;
  }

  @media screen and (min-width: 768px) {
    flex: initial;
  }
}
