.terms-form {
  display: flex;
  justify-content: center;
  align-items: center;

  .blueberry-family-panel {
    width: 610px;

    @media only screen and (max-width: 460px) {
      width: 95%;
      padding: 32px 16px;
    }
  }

  .blueberry-family-panel__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .blueberry-family-panel__title {
    max-width: 376px;
    width: 100%;
  }

  .terms-form__scroll-text-wrapper {
    max-height: 200px;
    background: #FFFFFF;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 16px 6px 0 16px;
    overflow: hidden;

    &.terms-form__scroll-text-wrapper--large {
      height: 480px;
    }
  }

  .terms-form__scroll-text {
    @include custom-family-scrollbar;
    scrollbar-gutter: stable;
    height: 100%;
    overflow: auto;
    padding-right: 10px;

    font-family: 'Times', serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    h3 {
      font-family: 'Quicksand', sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #576B70;
      margin: 1em auto;
      width: 100%;
    }

    h5 {
      font-family: 'Quicksand', sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #576B70;
      margin: 1em 0;
    }

    p,
    li {
      font-family: 'Times', serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #576B70;
    }

    li {
      margin-left: -1.5em;
    }

    ol {
      list-style-type: lower-roman;
    }

    a {
      font-family: 'Quicksand', sans-serif;
      font-weight: 700;
      font-size: 12px;
      color: #6363FC;
      text-decoration: underline;
      margin-inline: 3px;
      cursor: pointer;
      display: inline-block
    }

  }

  .terms-form__instructions {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    color: #384346;
    margin-top: 16px;
  }

  .blueberry-family-panel__actions {
    margin-top: 32px;
    max-width: 376px;
    width: 100%;
  }

  .terms-form__privacy {
    max-width: 376px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 16px;


    div:first-child {
      width: 20px;
    }

    div:last-child {
      margin-left: 14px;
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #333333;
    }
  }


}
