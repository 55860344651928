@import '../../../assets/styles/blueberry.scss';

.activity-blueberry-component {
  .feedback-indicator {
    position: relative;
    padding: 14px 28px 14px 66px;
    border-radius: 42px;
    background: map-get($activity-colors, white-bb);
    box-shadow: 0px 1px 5px 0px map-get($activity-colors, shadow-01);
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    text-align: center;

    .feedback-indicator__message {
      text-align: center;
      font-family: Poppins, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    &.feedback-indicator--correct {
      color: map-get($activity-colors, green-bb);

      @media screen and (max-width: 768px) {
        width: auto;
        max-width: 60%;
        margin-left: auto;
        margin-right: auto;
      }

      @media screen and (max-width: 520px) {
        width: auto;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.feedback-indicator--incorrect {
      color: map-get($activity-colors, red-bb);

      @media screen and (max-width: 768px) {
        width: auto;
        max-width: 60%;
        margin-left: auto;
        margin-right: auto;
      }

      @media screen and (max-width: 520px) {
        width: auto;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.feedback-indicator--hint {
      color: map-get($activity-colors, primary-medium);
      padding: 14px 28px;
    }

    > svg,
    > img {
      position: absolute;
      height: auto;
      width: 60px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &__content {
      display: flex;
      align-items: center;
      gap: 8px;

      button.speak-button {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 20px;
        min-height: 20px;
        width: 20px;
        height: 20px;

        svg {
          width: 100%;
          height: 100%;
          position: static;
          transform: none;
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .speak-button svg {
    width: 32px;
    height: 32px;
  }
  .activity-blueberry-component .feedback-indicator > svg,
  .activity-blueberry-component .feedback-indicator > img {
    width: 68px;
    height: 68px;
    left: -18px;
  }
}
