.map-course-back-button {
  position: fixed;
  top: 96px;
  left: 43px;
  z-index: 100;
  filter: drop-shadow(1px 1px 3px #888);
  cursor: pointer;
  user-select: none;


  :hover {
    filter: brightness(0.75);
  }

  img {
    height: 64px;
    width: 74px;
  }
}

.map-course-back-button-top {
  top: 0px;
}
