@use 'sass:color';

.download-app-page {
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  position: fixed;
  top: 0;
  left: 0;

  .download-app-page-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 32px 42px;
    background-color: #fff;
    overflow: auto;

    .download-app-page-layout__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
    }
  }

  .download-app-page__title {
    font-family: Poppins, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #4D586C;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    padding-top: 20px;
  }

  .download-app-page__text {
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #939DB0;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 26px;
  }

  .download-app-page__image {
    img {
      width: 100%;
      height: auto;
      max-width: 286px;
      margin: 0 auto;
    }
  }

  .download-app-page__download-btn {
    margin: 0;
    cursor: pointer;

    img {
      width: auto;
      height: auto;
      max-width: 286px;
      margin: 0 auto;
    }
  }

  .download-app-page__separator {
    margin: 24px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .download-app-page__separator-line {
      display: inline-block;
      border-bottom: 2px solid #939db0;
      width: 100%;
      max-width: 155px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .download-app-page__separator-text {
      display: inline-block;
      color: #939db0;
      font-family: Quicksand;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin: 0 32px;
    }
  }

  .download-app-page__logout {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #5a34e1;
    cursor: pointer;
    margin-top: 32px;
    border-radius: 36px;
    background: #5A34E1;
    color: #FFF;
    padding: 8px 16px;
    width: 100%;
    max-width: 400px;
  }

  @media (max-height: 860px) {
    .download-app-page__image_wrapper {
      img {
        max-width: 140px;
      }
    }

    .download-app-page__title {
      font-size: 18px;
    }

    .download-app-page__text {
      font-size: 16px;      
    }    

    .download-app-page__download-btn {
      img {
        max-width: 150px;
      }
    }

    .download-app-page__separator {
      margin: 18px 0;
    }
  }
}
