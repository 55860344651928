.form-checkbox {
  cursor: pointer;

  // Custom checkbox
  .form-checkbox__container {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    // Hide default checkbox
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .form-checkbox__checkmark {
      position: relative;
      width: 20px;
      height: 20px;
      display: block;
    }

    input:checked~.form-checkbox__checkmark .form-checkbox__tick {
      display: inline-block
    }

    .form-checkbox__tick {
      position: absolute;
      top: 4px;
      left: 8px;
      display: none;
      transform: rotate(45deg);
      height: 11px;
      width: 5px;
      border-bottom: 2px solid #1B1A26;
      border-right: 2px solid #1B1A26;
    }
  }

  .form-checkbox__label {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #3e3a68;
    margin-left: 8px;
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
  }
}
