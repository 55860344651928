// Blueberry Family Form button styles
.form-button {
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  padding: 0 16px;
  cursor: pointer;

  &:active {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  }

  .form-button__icon {
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    color: #5a34e1;
  }

  .form-button__text {
    display: inline-flex;
    align-items: center;
    color: #5a34e1;
  }
}

// variants
.form-button--default {
  background-color: #5a34e1;
  color: #fff;

  .form-button__icon svg {
    height: 18px;
    width: 18px;
    color: #5a34e1;
  }

  .form-button__icon svg path {
    fill: #fff;
  }

  &:hover {
    background-color: #3c3cbf;
  }

  &.form-button--disabled {
    pointer-events: none;
    background: #acacf7;
  }
}

.form-button--transparent {
  color: #6363fc;

  .form-button__icon svg path {
    fill: #5a34e1;
  }

  &:hover {
    color: #acacf6;

    .form-button__icon svg path {
      fill: #5a34e1;
    }
  }

  &:active {
    color: #3c3cbf;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25) !important;
  }

  &.form-button--disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &.form-button--text-align-left {
    justify-content: flex-start;

    &:active {
      box-shadow: none;
    }
  }

  &.form-button--text-align-right {
    justify-content: flex-end;

    &:active {
      box-shadow: none;
    }
  }

  &.form-button--text-align-center {
    justify-content: center;

    &:active {
      box-shadow: none;
    }
  }
}

.form-button--outline {
  color: #6363fc;
  border: 1.5px solid #6363fc;

  .form-button__icon svg {
    height: 18px;
    width: 18px;
  }

  .form-button__icon svg path {
    fill: #6363fc;
  }

  &:hover {
    border: 1.5px solid #3c3cbf;
    color: #3c3cbf;
  }

  &:active {
    border: 1.5px solid #3c3cbf;
    color: #3c3cbf;
  }

  &.form-button--disabled {
    pointer-events: none;
    border: 1.5px solid #acacf6;
    color: #acacf6;

    .form-button__icon svg path {
      fill: #acacf6;
    }
  }
}

// sizes
.form-button--display-fullwidth {
  display: flex;
  width: 100%;
}

.form-button--display-inline {
  display: inline-flex;
  width: auto;
  padding: 0 32px;
}
