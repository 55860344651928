.modal.modal-lemonade-onboarding {
  .modal__content {
    width: 636px;
    max-width: 636px;
    border-radius: 24px;
    box-shadow: none;
    padding: 24px;

    .modal__body {
      padding: 0;
    }

    .modal__body h1 {
      color: #4d586c;
      text-align: center;
      font-family: Poppins, sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 68px;
      margin-bottom: 24px;
    }

    .modal-lemonade-onboarding__img {
      display: flex;
      justify-content: center;

      img {
        border-radius: 24px;
        pointer-events: none;
        box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);
      }
    }

    .modal__controls {
      display: flex;
      justify-content: center;

      .practice-button {
        flex-grow: 0;
        min-width: 50%;
        margin-top: 16px;
      }
    }
  }
}

@media (max-width: 520px) {
  .modal.modal-lemonade-onboarding {
    .modal__content {
      width: 90%;
      max-width: none;
      height: auto;

      .modal-lemonade-onboarding__img {
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
