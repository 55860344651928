.map-bottom-bar {
  position: absolute;
  bottom: 20px;
  right: 0;
  z-index: 2;

  padding: 8px 0 8px 12px;
  display: flex;
  align-items: center;
  border-radius: 12px 0 0 12px;
  background: #5a34e1;
  box-shadow: -8px -5px 12px 0px rgba(0, 0, 0, 0.17) inset;
  transition: 0.2s ease-out all;

  &.map-bottom-bar--visible {
    right: 0;
    transition: 0.5s ease-out all;
  }

  &.map-bottom-bar--hidden {
    right: -304px;
    transition: 0.5s ease-out all;

    .map-bottom__button {
      transform: rotate(180deg);
    }
  }

  &.map-bottom-bar--hidden-gam {
    right: -160px;
    transition: 0.5s ease-out all;

    .map-bottom__button {
      transform: rotate(180deg);
    }
  }

  .map-bottom__button {
    position: absolute;
    left: -12px;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background: #5a34e1;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset,
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }

  .map-bottom-bar__wrapper {
    padding: 10px 26px;

    border-radius: 8px 0 0 8px;
    background: #3703a4;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .map-bottom-bar__compas-button {
    height: 56px;
    width: 56px;
    cursor: pointer;
    position: relative;
  }

  .map-bottom-bar__compas-image {
    width: 57px;
    height: 57px;
  }

  .map-bottom-bar__birdeye-button,
  .map-bottom-bar__compas-button,
  .map-bottom-bar__missions-button,
  .map-bottom-bar__pets-button {
    height: 56px;
    width: 56px;
    cursor: pointer;
    position: relative;
  }

  .map-bottom-bar__missions-tooltip,
  .map-bottom-bar__pets-tooltip {
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 8px 12px;
    width: max-content;
    border-radius: 8px;
    background: #3703a4;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    line-height: 16px;
    bottom: 88px;
    text-align: center;

    &:before {
      content: '';
      width: 12px;
      height: 12px;
      background: #3703a4;
      transform: rotate(45deg);
      position: absolute;
      bottom: -6px;
    }
  }

  .map-bottom-bar__missions-button {
    &:hover {
      .map-bottom-bar__missions-tooltip {
        display: flex;
        left: -42px;
        width: 140px;
      }
    }
  }

  .map-bottom-bar__compas-button {
    .map-bottom-bar__missions-tooltip {
      &:before {
        content: '';
        width: 12px;
        height: 12px;
        background: #3703a4;
        transform: rotate(45deg);
        position: absolute;
        left: 124px;
        bottom: -6px;
      }
    }

    &:hover {
      .map-bottom-bar__missions-tooltip {
        left: -100px;
        padding: 6px 12px;
        align-items: flex-start;
        gap: 10px;
        display: inline-block;
        white-space: nowrap;
      }
    }
  }

  .map-bottom-bar__pets-button {
    &:hover {
      .map-bottom-bar__pets-tooltip {
        display: flex;
        left: -50px;
        width: 160px;
      }
    }
  }

  .map-bottom-bar__missions-button-halo,
  .map-bottom-bar__pets-button-halo {
    height: 56px;
    width: 56px;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 0 0 3px #30ffb1;
    border-radius: 50%;
    animation: notificationHalo 5000ms infinite linear;
    opacity: 0;
  }

  .map-bottom-bar__missions-button-exclamation,
  .map-bottom-bar__pets-button-exclamation {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -1%;
    right: -12%;
    width: 20px;
    height: 20px;
    color: #ffffff;
    background-color: #ff0000;
    border-radius: 50%;
    box-shadow: 0px 10px 2px 0px rgba(0, 0, 0, 0.25);
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 14px;
    animation: notification 900ms infinite ease-in-out;

    &.map-bottom-bar__missions-button-exclamation--grouped {
      top: -10%;
      right: 94%;
    }
  }

  @keyframes notificationHalo {
    5% {
      opacity: 1;
    }

    10% {
      opacity: 0.5;
    }

    20% {
      opacity: 0;
    }
  }

  @keyframes notification {
    15% {
      top: -15%;
    }

    30% {
      top: -1%;
    }

    45% {
      top: -15%;
    }

    60% {
      top: -1%;
    }

    75% {
      top: -1%;
    }
  }

  @media (min-width: 361px) and (max-width: 520px) {
    .map-bottom-bar__birdeye-button,
    .map-bottom-bar__compas-button,
    .map-bottom-bar__missions-button,
    .map-bottom-bar__pets-button,
    .map-bottom-bar__rate-blueberry-mobile {
      height: 43px;
      width: 43px;

      img {
        width: 43px;
        height: 43px;
      }
    }
    .map-bottom-bar__missions-button-halo,
    .map-bottom-bar__pets-button-halo {
      height: 43px;
      width: 43px;
    }
  }

  @media (max-width: 360px) {
    &.map-bottom-bar--visible {
      right: 0;
      width: calc(100% - 12px);
      transition: 0.5s ease-out all;
    }
    &.map-bottom-bar--hidden {
      right: -325px;
    }

    .map-bottom-bar__wrapper {
      width: 100%;
    }

    .map-bottom-bar__birdeye-button,
    .map-bottom-bar__compas-button,
    .map-bottom-bar__missions-button,
    .map-bottom-bar__pets-button {
      height: 46px;
      width: 46px;

      img {
        width: 46px;
        height: 46px;
      }
    }

    .map-bottom-bar__missions-button-halo,
    .map-bottom-bar__pets-button-halo {
      height: 46px;
      width: 46px;
    }

    .map-bottom-bar__rate-blueberry-mobile {
      img {
        width: 46px;
        height: 46px;
      }
    }
  }

  @media (min-width: 521px) and (max-width: 620px) {
    &.map-bottom-bar--hidden {
      right: -260px;
    }

    .map-bottom-bar__birdeye-button,
    .map-bottom-bar__compas-button,
    .map-bottom-bar__missions-button,
    .map-bottom-bar__pets-button {
      height: 45px;
      width: 45px;

      img {
        width: 45px;
        height: 45px;
      }
    }

    .map-bottom-bar__missions-button-halo,
    .map-bottom-bar__pets-button-halo {
      height: 45px;
      width: 45px;
    }

    .map-bottom-bar__rate-blueberry-mobile {
      img {
        width: 45px;
        height: 45px;
      }
    }
  }

  @media (min-width: 621px) and (max-width: 720px) {
    &.map-bottom-bar--hidden {
      right: -280px;
    }

    .map-bottom-bar__birdeye-button,
    .map-bottom-bar__compas-button,
    .map-bottom-bar__missions-button,
    .map-bottom-bar__pets-button {
      height: 50px;
      width: 50px;

      img {
        width: 50px;
        height: 50px;
      }
    }

    .map-bottom-bar__missions-button-halo,
    .map-bottom-bar__pets-button-halo {
      height: 50px;
      width: 50px;
    }

    .map-bottom-bar__rate-blueberry-mobile {
      img {
        width: 50px;
        height: 50px;
      }
    }
  }

  @media (min-width: 721px) and (max-width: 768px) {
    &.map-bottom-bar--hidden {
      right: -302px;
    }
  }

  @media (min-width: 424px) and (max-width: 519px) {
    .map-bottom-bar {
      &.map-bottom-bar--visible {
        width: calc(100% - 98px);
      }
    }
  }
}

.map-bottom-bar__rate-blueberry-mobile {
  display: none;

  @media (max-width: 520px) {
    display: block;
    cursor: pointer;

    .tooltip__message {
      display: none;
    }
  }
}
