.form-select {
  position: relative;
  margin: 8px auto;
  width: 100%;
}

.form-select label {
  font-family: 'Poppins', sans-serif;
  color: #3E3A68;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 6px
}

.form-select .form-select__control {
  color: #1B1A26;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  background-color: #ffffff;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #9997AE;
  height: 46px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &.form-select__control--is-focused {
    border-color: #5555C7;
    box-shadow: none;
  }

  &.form-select__control--is-disabled {
    border: none;
    background-color: #e9e8f0;

    .form-select__single-value--is-disabled {
      color: #1b1a26;
    }
  }

  .form-select__placeholder {
    font-family: 'Poppins', sans-serif;
    color: #9997AE;
  }

  .form-select__value-container {
    padding: 8px;
  }

  .form-select__input-container {
    padding: 0
  }

  .form-select__indicator {
    padding: 12px;
  }

}

.form-select__menu-list {
  background: #FFFFFF;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}

.form-select__option {

  &.form-select__option--is-focused,
  &:active {
    background-color: transparent !important;
    color: #333333;
    cursor: pointer;
  }

  &.form-select__option--is-selected {
    background-color: transparent !important;
    color: #333333;

    &:after {
      content: url('../../../../assets//icons/select-check.svg');
      position: absolute;
      right: 12px;
    }
  }
}

.form-select.error input {
  border-color: #FF3D30;
}

.form-select__error {
  margin-top: 6px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #FF3D30;
}


.form-select.withTooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #576B70;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  width: 140px;
  bottom: 100%;
  left: 50%;
  margin-left: -70px;
  margin-bottom: 8px;
}


/* Show the tooltip text when you mouse over the tooltip container */
.form-select.withTooltip:hover .tooltiptext {
  visibility: visible;
}

.form-select.withTooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #576B70 transparent transparent transparent;
}
