@use 'sass:color';

* {
  box-sizing: border-box;
}

.password-form {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    width: 100%;
    display: block;
    padding: 0;
    margin: 0 auto;
  }

  .form-group {
    margin-bottom: 32px;
  }

  .submit-control {
    margin-top: 0;
  }
  .submit-control {
    .form-button__text {
      color: #fff;
    }
  }

  .form-title {
    color: var(--Neutral-Grays-Gray-02, #4d586c);
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    height: 39px;
    align-self: stretch;
  }

  .form-text {
    font-family: 'Poppins', sans-serif;
    color: #3e3a68;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 32px;
    text-align: center;
  }

  .form-image {
    margin-bottom: 32px;
  }

  .btn {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    border-radius: 37px;
    transition: background-color 0.1s;
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: 20px;
    color: #ffffff;
    background-color: #5a34e1;

    &:hover {
      cursor: pointer;
      background-color: #5a34e1;
      padding: 12px 16px;
      border-radius: 37px;
      color: #ffffff;
    }

    &:active,
    &:target {
      position: relative;
      background-color: #5a34e1;
      padding: 12px 16px;
      border-radius: 37px;
      color: #ffffff;
    }

    &:disabled {
      pointer-events: none;
      border: none !important;
      color: #afb7c6;
      box-shadow: none !important;
      background: var(--Grays-gray-09, #f0f4f8);
    }
  }

  .input-with-label input {
    margin: 0 auto 6px;
  }

  .form-input-container:first-child {
    margin-top: 32px;
  }

  .form-input-container:last-child {
    margin-top: 16px;
  }

  .form-input-error {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #ff3d30;
  }
}
