// Niveles base
$z-base: 0;
$z-low: 100;
$z-medium: 500;
$z-high: 1000;

// Elementos específicos
$z-decoration: $z-base;
$z-panel: 98;
$z-panel-mobile: $z-low;
$z-panel-content: $z-high;

// Existentes
$unit-button-depth: 20;
$road-depth: 10;
$under-road-depth: 5;
$over-road-depth: 15;
$clouds-depth: 20;
$modal-depth: $z-high + 1;
$under-modal-depth: $z-high;
$shadow-depth: $z-low;
$under-shadow-depth: $z-low - 1;
$over-shadow-depth: $z-low + 1;
$loader-depth: $z-high + 2;
