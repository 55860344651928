.metaberry-page {
  width: 100%;
  height: 100%;

  .metaberry-loading {
    position: absolute;
    background-color: #490dc8;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }
}
