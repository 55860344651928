.contest-info__page {
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  background: url('./assets/bg_stars.png') repeat-x center center, #3704a3;

  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;

  .contest-info__page-bg {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    background: url('./assets/bg_top_left.png') no-repeat top left,
      url('./assets/bg_bottom_right.png') no-repeat right bottom -220px;
  }
  @media (max-width: 900px) {
    .contest-info__page-bg {
      background: url('./assets/bg_top_left.png') no-repeat top left;
    }
  }

  .content__container {
    padding-top: 100px;
    max-width: 750px;
    width: 95%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    .content__header {
      width: 100%;
      margin-bottom: 100px;
      display: flex;
      justify-content: center;
      position: relative;

      .content__logo {
        width: 100%;
        max-width: 400px;
      }

      .content__red-monster {
        position: absolute;
        right: -120px;
        bottom: -100px;
      }
      .content-results__logo {
        width: 100%;
        max-width: 400px;
        margin-left: -85px;
      }
    }

    .contest-result-page__title {
      font-size: 63px;
      font-weight: 700;
      line-height: 200px;
      margin-top: -80px;
    }

    .contest-info-page__title {
      font-weight: 700;
      font-size: 39px;
      line-height: 59px;
      text-align: center;
      display: flex;
      justify-content: center;
      margin-bottom: 44px;
      max-width: 700px;
    }
    .contest-graphic-page__title {
      font-weight: 700;
      font-size: 39px;
      line-height: 59px;
      text-align: center;
      display: flex;
      justify-content: center;
      margin-bottom: 44px;
      max-width: 700px;
    }

    .content__highlight-text {
      background-color: #5a34e1;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      border-radius: 39px;
      display: flex;
      justify-content: center;
      margin-bottom: 54px;
      padding: 8px 37px;
      max-width: 544px;
      width: 100%;
    }
    .content-graphic__highlight-text {
      background-color: #5a34e1;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      border-radius: 39px;
      display: flex;
      justify-content: center;
      margin-bottom: 54px;
      padding: 8px 37px;
      max-width: 544px;
      width: 100%;
    }
    .content-result__highlight-container {
      border-radius: 36px;
      border: 1px solid #fff;
      display: inline-flex;
      padding: 7px;
      justify-content: center;
      align-items: center;
      gap: 14px;
      margin-bottom: 17px;

      .content-result__highlight-activities-text {
        text-align: center;
        font-family: Poppins, sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 31px;
        display: flex;
        width: 156px;
        height: 62px;
        flex-direction: column;
        justify-content: center;
      }
    }

    .content__one-eye-green-monster {
      position: absolute;
      left: -181px;
      bottom: 58px;
      width: 173px;
      height: 172px;
      z-index: 0;
    }
    .contest__frame-container {
      border-radius: 24px;
      background: #fff;
      box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.3);
      width: 951px;
      height: 565px;
      flex-shrink: 0;
      padding: 49px 46px 50px 46px;
      margin-bottom: 193px;
      position: relative;
      z-index: 1;

      .contest__frame {
        height: 475px;
        flex-shrink: 0;
      }
    }
    .contest-result__podium-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-shrink: 0;
      margin-top: 100px;
      position: relative;
      width: 771px;
      height: 205px;
    }
    .contest-result__podium-school-container {
      border-radius: 24px;
      background: var(--Blueberry-Primary-Medium-blue, #5a34e1);
      width: 602px;
      padding: 16px 8px;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      flex-shrink: 0;
      margin-top: 0;
      position: absolute;
      top: 114px;
      display: inline-block;
      word-wrap: break-word;
      max-width: 470px;

      .contest-result__podium-school-name-container {
        display: flex;
        padding: 10px 12px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        align-self: stretch;
        border-radius: 14px;
        background: rgba(0, 0, 0, 0.38);
        margin-left: 8px;
        margin-right: 8px;

        .contest-result__podium-school-name {
          overflow: hidden;
          color: var(--Neutral-Base-White, #fff);
          text-align: center;
          text-overflow: ellipsis;
          font-family: Poppins, sans-serif;
          font-size: 25px;
          font-weight: 700;
          line-height: 26px;
          display: flex;
          flex-direction: column;
        }
      }
    }
    .contest-result__podium-content-images {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 324px;
      height: 154px;
      flex-shrink: 0;
      margin-left: 60px;
      margin-top: 14px;
      position: relative;
      .contest-result__blueberro-podium {
        display: inline-flex;
        width: 136px;
        height: 136px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        position: absolute;
        right: 125px;
        top: -95px;
      }
      .contest-result__podium {
        width: 370px;
        height: 189px;
        flex-shrink: 0;
        fill: #63dbd0;
      }
    }
    .contest-result__congratulation-container {
      margin-top: 82px;
      margin-bottom: 68px;

      .contest-result__congratulation-title {
        overflow: hidden;
        color: var(--Neutral-Base-White, #fff);
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: Poppins, sans-serif;
        font-size: 25px;
        font-weight: 700;
        line-height: 26px;
        display: flex;
        width: 544px;
        height: 19px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
      }
      .contest-result__contact-text {
        color: #fff;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 34px;
        width: 544px;
        height: 80px;
        flex-shrink: 0;
        padding-left: 0px;
        padding-right: 0px;
      }
      .content-result__one-eye-green-monster {
        position: absolute;
        left: -232px;
        bottom: 173px;
        width: 173px;
        height: 172px;
        z-index: 0;
      }
    }

    .contest_button {
      cursor: pointer;
      display: inline-flex;
      padding: 12px 32px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 36px;
      background: var(--Grays-white, #fff);
      color: var(--Blueberry-Primary-Medium-blue, #5a34e1);
      font-family: Poppins, sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-top: 30px;
      margin-bottom: 130px;
    }

    .contest_button:hover {
      cursor: pointer;
      display: inline-flex;
      padding: 12px 32px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 36px;
      color: var(--Blueberry-Primary-Dark-blue, #3703a4);
      font-family: Poppins, sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    .contest-result__end-container {
      background-color: #1c0251;
      border-radius: 10px;
      margin-bottom: 80px;

      .contest-result__end-contest-text {
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 40px;
        display: flex;
        width: 232px;
        height: 39px;
        flex-direction: column;
        justify-content: center;
      }
    }

    @media (max-width: 950px) {
      .contest__frame-container {
        margin-top: -15px;
        width: 100%;
      }
    }

    .content__info {
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      display: flex;
      justify-content: center;
      margin-bottom: 54px;
    }

    .content__images {
      position: relative;

      .content__boy-image {
        width: 100%;
        max-width: 544px;
        margin-bottom: 100px;
      }

      .content__purple-planet-image {
        position: absolute;
        top: -14px;
        right: -60px;
      }
    }

    .contest__requirements-content {
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      width: 100%;
      max-width: 928px;
      height: 194px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin-bottom: 54px;
      padding-top: 180px;
      padding-bottom: 150px;
      position: relative;

      .contest__requirement-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
      }

      .contest__requirement-info {
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 20px;

        p {
          margin: 0;
        }
      }

      .content__one-eye-green-monster {
        position: absolute;
        left: -135px;
        top: -140px;
        width: 173px;
        height: 172px;
      }
    }
  }

  .contents__footer {
    background: var(
      --Linear-progress-dark,
      linear-gradient(0deg, #3a1587 9%, #490dc8 99.99%, #3703a4 100%)
    );
    padding-top: 150px;
    padding-bottom: 150px;
    padding-left: 80px;
    padding-right: 60px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;

    .contents__footer-redes {
      border: 1px solid #fff;
      //border-right: none;
      color: #fff;
      padding-left: 70px;
      display: flex;
      padding-top: 13px;
      padding-bottom: 10px;
      flex-shrink: 1;

      .instagram {
        margin-right: 24px;
        width: 27px;
        height: 27px;
      }

      .linkedin {
        margin-right: 24px;
        width: 27px;
        height: 27px;
      }

      .youtube {
        margin-right: 24px;
        width: 27px;
        height: 27px;
      }
    }

    .contents__footer-web {
      border: 1px solid #fff;
      line-height: 36px;
      flex-grow: 1;
      padding-top: 10px;
      padding-bottom: 10px;

      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      padding-left: 16px;

      p {
        color: #fff;
        text-align: center;
        font-size: 23px;
        font-weight: 700;
        margin: 0;
        padding-right: 100px;
      }

      a {
        text-decoration: none;
      }

      .planetImage {
        position: absolute;
        top: -21px;
        left: 0;
        width: 117px;
        height: 95px;
      }
    }

    .layouts-demo__footer-logo {
      border: 1px solid #fff;
      color: #fff;
      padding-right: 60px;
      padding-left: 32px;
      padding-top: 10px;
      padding-bottom: 10px;
      flex-shrink: 1;
    }

    @media screen and (min-width: 540px) and (max-width: 800px) {
      .layouts-demo__footer-logo {
        border-top: 1px solid #fff;
      }
      .contents__footer-redes {
        border-bottom: 1px solid #fff;
      }
    }
    @media screen and (min-width: 400px) and (max-width: 740px) {
      .layouts-demo__footer-logo {
        border-top: 0px;
      }
      .contents__footer-redes {
        border-bottom: 0px;
      }
    }
    @media screen and (min-width: 900px) and (max-width: 920px) {
      .layouts-demo__footer-logo {
        border-top: 0px;
      }
    }
    @media screen and (min-width: 741px) and (max-width: 900px) {
      .layouts-demo__footer-logo {
        border-top: 1px solid #fff;
      }
    }
    @media screen and (min-width: 570px) and (max-width: 740px) {
      .layouts-demo__footer-logo {
        border-top: 1px solid #fff;
      }
    }
    @media (max-width: 400px) {
      .layouts-demo__footer-logo {
        border-top: 0px;
      }
      .contents__footer-redes {
        border-bottom: 0px;
      }
    }

    .content__blue-monster {
      position: absolute;
      top: -114px;
      right: 61px;
    }
    .content-results__blue-monster {
      position: absolute;
      top: -114px;
      right: 61px;
    }
  }

  /* Pantallas de menos de 900px de ancho */
  @media (max-width: 900px) {
    .content__container {
      padding-top: 75px;
      position: relative;
      .contest__frame-container {
        padding: 15px 15px 15px 15px;
        height: 464px;
        .contest__frame {
          height: 428px;
        }
      }
      .content-graphic__logo {
        margin-top: -40px;
        width: 245px;
      }
      .contest-graphic-page__title {
        font-size: 24px;
        line-height: 40px;
        color: var(--Grays-white, #fff);
        text-align: center;
        font-family: Inter, sans-serif;
        font-weight: 700;
        margin-top: -85px;
      }
      .content-result__highlight-text {
        margin-top: 52px;
      }
      .content-graphic__highlight-text {
        margin-top: -15px;
      }
      .content-result__highlight-activities-text {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .contest-result__podium-content-images {
        width: 275px;
        height: 154px;
        flex-shrink: 0;
        margin-left: 45px;
        margin-top: 14px;
        position: relative;
        .contest-result__blueberro-podium {
          width: 136px;
          height: 136px;
          position: absolute;
          left: 45px;
          top: -80px;
        }
      }
      .contest-result__podium-school-container {
        border-radius: 24px;
        background: var(--Blueberry-Primary-Medium-blue, #5a34e1);
        display: flex;
        width: 320px;
        padding: 16px 8px;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        flex-shrink: 0;
        .contest-result__podium-school-name-container {
          border-radius: 12px;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          display: flex;
          padding: 8px 12px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          .contest-result__podium-school-name {
            overflow: hidden;
            color: var(--Neutral-Base-White, #fff);
            text-align: center;
            text-overflow: ellipsis;
            //white-space: nowrap;
            font-family: Poppins, sans-serif;
            font-size: 20px;
            font-weight: 700;
            line-height: 26px;
            display: flex;
            height: 55px;
            flex-direction: column;
            justify-content: center;
            flex: 1 0 0;
          }
        }
      }
      .contest-result__end-container {
        margin-top: -65px;
        margin-bottom: 145px;
      }
      .contest-result__congratulation-container {
        margin-top: 30px;
        .contest-result__congratulation-title {
          width: 0px;
        }
        .contest-result__contact-text {
          color: #fff;
          text-align: center;
          font-family: Inter, sans-serif;
          font-size: 18px;
          font-weight: 700;
          line-height: 34px;
          //width: 100%;
          height: 80px;
          flex-shrink: 0;
          padding-left: 81px;
          padding-right: 81px;
        }
        .content-result__one-eye-green-monster {
          left: 5px;
          bottom: 28px;
          width: 88px;
          height: 88px;
        }
      }

      .content__one-eye-green-monster {
        position: absolute;
        left: 0px;
        bottom: 73px;
        width: 94px;
        height: 94px;
        z-index: 0;
      }
    }
    .content__red-monster {
      display: none;
    }
    .contest-info__page-bg {
      .content__container {
        .content__header {
          .content-results__logo {
            margin-top: -40px;
            width: 245px;
            margin-left: 0;
          }
        }
        .contest-result-page__title {
          margin-left: 0px;
          color: var(--Grays-white, #fff);
          text-align: center;
          font-family: Inter, sans-serif;
          font-size: 44px;
          font-weight: 700;
          line-height: 40px;
          margin-bottom: 20px;
        }
        .content-result__highlight-container {
          margin-left: 0px;
          display: flex;
          width: 156px;
          height: 62px;
          flex-direction: column;
          justify-content: center;
          flex-shrink: 0;
          margin-top: 17px;
        }
      }
    }
    .contest__requirements-content {
      margin-bottom: 154px;

      .content__one-eye-green-monster {
        left: 0;
        bottom: -130px;
        width: 95px;
        height: 95px;
        top: initial;
      }
    }
    .contents__footer {
      padding-left: 27px;
      padding-right: 26px;
    }
    .contents__footer .content__blue-monster {
      right: 77px;
      top: -48px;
      width: 144px;
    }
    .contents__footer .content-results__blue-monster {
      right: 77px;
      top: -48px;
      width: 144px;
    }
    .contents__footer .contents__footer-web {
      justify-content: flex-start;
      padding-right: 16px;
      border-left: 1px solid #fff;

      .planetImage {
        position: absolute;
        right: 0;
        top: -21px;
        left: initial;
      }
    }
    .contents__footer .contents__footer-redes {
      //border-right: 1px solid #fff;
      //border-bottom: 1px solid #fff;
      padding-left: 16px;
    }
    .contents__footer .layouts-demo__footer-logo {
      //border-left: 1px solid #fff;
      //border-top: 1px solid #fff;
      padding-right: 25px;
      padding-left: 16px;
    }
  }

  /* Animaciones */

  .pulsate-fwd {
    -webkit-animation: pulsate-fwd 3s ease-in-out infinite both;
    animation: pulsate-fwd 3s ease-in-out infinite both;
  }

  /**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
  @-webkit-keyframes pulsate-fwd {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes pulsate-fwd {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
