@use '../../../../theme/index' as theme;

.region-select-container {
    display: flex;
    align-items: center; /* Alinea verticalmente los elementos en el centro */
    width: auto; /* Ajusta el ancho según sea necesario */
    justify-content: center;
    margin: 0 auto;
    max-width: 310px;
}

.region-select-label {
    margin-right: 3px; /* Espacio entre la etiqueta y el selector */
    @include theme.poppins-font($size: 14px, $weight: 500);
    font-style: normal;
    line-height: 20px;
    color: #303949; /* Color de la etiqueta */
}

.custom-select {
    position: relative;
    cursor: pointer;
    background-color: #fff;
    transition: border-color 0.3s;
    flex: 1; /* Permite que el selector ocupe el espacio restante */

    display: flex;
    @include theme.poppins-font($size: 14px, $weight: 400);
    color: #333333;
    width: 100%;
    justify-content: center;
    align-items: center;

    &:hover {
        border-color: #888; /* Cambia el color del borde al pasar el mouse */
    }
}

.selected-option {
    display: flex;
    align-items: center;
    color: #555; /* Color del texto seleccionado */
    width: 100%;
}

.options-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Sombra para el dropdown */
    padding: 0; /* Elimina el padding */
    margin: 0; /* Elimina el margin */
    list-style: none; /* Elimina los puntos de la lista */
    width: max-content;
}

.option {
    display: flex;
    align-items: center;
    padding: 10px; /* Espaciado interno */
    cursor: pointer;
    transition: background-color 0.3s; /* Transición suave para el hover */

    &:hover {
        background-color: #f0f0f0; /* Color de fondo al pasar el mouse */
    }
}

.flag {
    width: 20px; /* Ancho de la bandera */
    height: auto; /* Mantiene la proporción de la imagen */
    margin-right: 10px; /* Espacio entre la bandera y el texto */
}

.arrowIcon {
    border-radius: 50%;
    display: grid;
    place-items: center;
    position: static;
    width: 0.75rem;
    height: 0.75rem;
    min-width: 0.75rem;
    min-height: 0.75rem;
}