.recover-password-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  .recover-password-page__cover-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
  .recover-password-page_luna {
    position: absolute;
    z-index: 3;
    left: 392px;
    top: -40px;
  }
  .recover-password-page_rocket {
    position: absolute;
    bottom: 0px;
    pointer-events: none;
  }
}
.recover-password-page__bb-logo-container {
  margin-top: 24px;
}
.recover-password-page__content {
  position: relative;
  border-radius: 14px;
  background: var(--Grays-White, #fff);
  padding: 24px 36px 36px 36px;
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 440px;
  justify-content: space-between;
  flex-shrink: 0;
  margin-top: 50px;
  margin-bottom: 30px;
}
.recover-password-page-container {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  margin: 0;
}

.recover-password-page__google-button {
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 1em 0.5em 0 0.5em;
  width: 100%;
  margin-top: 12px;
}

.recover-password-page__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  width: 100%;
  height: 40px;

  .form-button {
    padding-left: 0;
    flex-grow: 1;
  }

  .recover-password-page__oc-logo {
    display: flex;
    justify-content: flex-end;
    width: 80px;
    flex-grow: 1;
    fill: #5a34e1;
  }
}
.form-button__recover-password {
  color: #fff;
}

.recover-password-page__form-content {
  width: 100%;
}

@media screen and (max-width: 520px) {
  .recover-password-page-container {
    justify-content: normal;
    display: grid;
    align-items: center;
    .recover-password-page__content {
      width: 100%;
      height: 100%;
      margin: 0px;      
    }
  }
  .recover-password-page .recover-password-page_luna {
    right: 16px;
    top: 58px;
    left: unset;
    width: 56px;
    height: 56px;
    z-index: 2;
  }

  .recover-password-page_rocket {
    top: -70px;
    left: -76px;
    z-index: 2;
    width: 350px;
  }
  .recover-password-page_header-mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-image: url('../../assets/img/fondo-espacial-login.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 225px;
  }
  .recover-password-page__bb-logo-container {
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 0;

    .recover-password-page__bb-logo {
      margin-top: 16px;
      width: 139px;
      path {
        fill: #fff;
      }
    }
  }
  .recover-password-page__form-content {
    margin-top: 164px;    
  }
}
