.form-tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .form-tabs__tab-item {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #576B70;
    margin: 0 12px;
    cursor: pointer;
  }

  .form-tabs__tab-item--active {
    color: #6363FC;
  }
}
