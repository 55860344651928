.blueberry-family-layout {
  height: 100%;
  height: -webkit-fill-available;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-image: url(../../../../assets/img/title_bg.png);
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;

  .blueberry-family-layout__content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    // Below ipad mini and landscape mode
    @media only screen and (max-width: 1000px) and (orientation: landscape) {
      overflow: auto;
      align-items: flex-start;
      padding: 8px;


    }

    .blueberry-family-panel__form {
      width: 100%;
    }
  }

  .blueberry-family-layout__footer {
    width: 100%;
    height: 26px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.8);

    font-family: Poppins;
    font-size: 12px;
    color: #576b70;
  }

  .license-form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
