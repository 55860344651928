.form-input {
  position: relative;
  margin: 8px auto;
  width: 100%;
}

.form-input label {
  font-family: 'Poppins', sans-serif;
  color: #3E3A68;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 6px
}

.form-input__wrapper {
  position: relative
}

.form-input input {
  display: block;
  color: #1B1A26;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding: 10px;
  margin: 0;
  background-color: #ffffff;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #9997AE;

  &:focus {
    outline: none;
    border-color: #5555C7;
  }

  &::placeholder {
    font-family: 'Poppins', sans-serif;
    color: #9997AE;
  }
}

.form-input__right-icon {
  position: absolute;
  top: 12px;
  right: 8px;
}

.form-input.error input {
  border-color: #FF3D30;
}

.form-input__error {
  margin-top: 6px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #FF3D30;
}

.form-input input:disabled {
  border: none;
  background-color: #e9e8f0;
  color: #1b1a26;
}
