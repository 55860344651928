.pricing-panel {
  width: 660px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 32px 42px;
  border-radius: 16px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 255, 0.8);

  @media only screen and (max-width: 660px) {
    width: 95%;
    padding: 32px 16px;
  }

  .pricing-panel__content {
    width: 100%;
  }

  .pricing-panel__logo {
    margin-top: 14px;
    margin-bottom: 32px;
  }

  .pricing-panel__title {
    font-family: Quicksand, sans-serif;
    font-weight: bold;
    font-size: 24px;
    color: #1b1a26;
    margin-bottom: 32px;
    text-align: center;
    line-height: 28px;
  }

  .pricing-panel__text {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    margin-bottom: 32px;
  }

  .pricing-panel__text-h4 {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #576B70;
    margin-bottom: 32px;
  }

  .pricing-panel__info {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #6363FC;
    margin-top: 32px;
    margin-bottom: 40px;
  }

  .pricing-panel__error {
    font-family: 'Poppins', sans-serif;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #FF3D30;
    margin-top: 12px;
  }

  .pricing-panel__separator {
    width: 100%;
    height: 2px;
    background: rgba(195, 194, 207, 0.6);
    border-radius: 10px;
    margin: 32px 0;
  }

  .pricing-panel__or-separator {
    width: 100%;
    margin: 32px 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    span:first-child,
    span:last-child {
      display: inline-block;
      height: 2px;
      background: rgba(195, 194, 207, 0.6);
      border-radius: 10px;
      width: 100%;
      margin: 0;
    }

    span {
      font-family: 'Quicksand', sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #576B70;
      margin: 0 12px;
    }

  }


  .pricing-panel__actions {

    &.pricing-panel__actions--row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .form-button {
        margin-right: 32px
      }

      .form-button:last-child {
        margin-right: 0
      }
    }

    &.pricing-panel__actions--column {
      display: flex;
      flex-direction: column;

      .form-button {
        margin: 6px 0;

        &:first-child {
          margin-top: 0
        }

        &:last-child {
          margin-bottom: 0
        }
      }
    }
  }
}
