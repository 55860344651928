#__blueberry-modal-container {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: $modal-depth;
}

.modal__hidden {
  opacity: 0;
}

.modal__shadow-layer,
.modal__content-wrapper {
  position: absolute;
  width: 100%;
  height: 100vh;
}

.modal__shadow-layer {
  background: black;
}

.modal__content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__content {
  position: relative;
  max-width: 400px;
  padding: 20px;
  border-radius: 40px;
  background: $white;
}

.modal__close-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  right: -10px;
  top: -10px;
  border-radius: 100%;
  background-color: red;
  color: $white;
  z-index: calc(#{$modal-depth} + 1);
}
