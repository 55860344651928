@import '../../../theme/index.scss';

.simple-button {
  font-family: $primary-font;
  font-style: normal;
  font-weight: bold;

  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 16px;

  &:hover {
    cursor: pointer;
  }

  &:active,
  &:target {
    box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.25) !important;
    position: relative;
    top: 4px;
  }

  .simple-button__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  // size

  &.button--size-default {
    height: 43px;
    font-size: 18px;
    line-height: 22px;
  }

  &.button--size-small {
    height: 39px;
    font-size: 18px;
    line-height: 22px;
  }

  &.button--size-large {
    height: 67px;
    font-size: 28px;
    line-height: 35px;
  }

  // display

  &.button--display-default {
    width: 100%;
    height: auto;
    display: flex;
  }

  &.button--display-inline {
    width: auto;
    display: inline-flex;
  }

  // variants and colors

  &.button-variant--default {
    color: $white;

    &.button--color-default {
      border: 1px solid $blue;
      background-color: $blue;
      box-shadow: 0 4px 0 $shadow-darkBlue;
    }
    &.button--color-grey {
      border: 1px solid $gray-5;
      background-color: $gray-5;
      box-shadow: 0 4px 0 $shadow-grey;
    }
    &.button--color-red {
      border: 1px solid $red;
      background-color: $red;
      box-shadow: 0 4px 0 $shadow-darkRed;
    }
    &.button--color-pink {
      border: 1px solid $pink;
      background-color: $pink;
      box-shadow: 0 4px 0 $shadow-darkPink;
    }
    &.button--color-orange {
      border: 1px solid $orange;
      background-color: $orange;
      box-shadow: 0 4px 0 $shadow-darkOrange;
    }
    &.button--color-yellow {
      border: 1px solid $mediumYellow;
      background-color: $mediumYellow;
      box-shadow: 0 4px 0 $shadow-darkYellow;
    }
    &.button--color-green {
      border: 1px solid $green;
      background-color: $green;
      box-shadow: 0 4px 0 $shadow-darkGreen;
    }
    &.button--color-blue {
      border: 1px solid $blue;
      background-color: $blue;
      box-shadow: 0 4px 0 $shadow-darkBlue;
    }
    &.button--color-purple {
      border: 1px solid $purple;
      background-color: $purple;
      box-shadow: 0 4px 0 $shadow-darkPurple;
    }
    &.button--color-white {
      color: $blue;
      border: 1px solid $blue;
      background-color: $white;
      box-shadow: 0 4px 0 $shadow-darkBlue;
    }    
  }

  &.button-variant--outline {
    border: 1px solid $gray-1;
    background-color: $white;

    &.button--color-default {
      border: 1px solid $blue;
      box-shadow: 0 4px 0 $shadow-darkBlue;
      color: $blue;
      &:active,
      &:target {
        box-shadow: inset 0 4px 0 $shadow-inset-darkBlue !important;
      }
    }

    &.button--color-grey {
      border: 1px solid $gray-4;
      box-shadow: 0 4px 0 $shadow-grey;
      color: $gray-4;
      &:active,
      &:target {
        box-shadow: inset 0 4px 0 $shadow-inset-grey !important;
      }
    }

    &.button--color-red {
      border: 1px solid $red;
      box-shadow: 0 4px 0 $shadow-darkRed;
      color: $red;
      &:active,
      &:target {
        box-shadow: inset 0 4px 0 $shadow-inset-darkRed !important;
      }
    }
    &.button--color-pink {
      border: 1px solid $pink;
      box-shadow: 0 4px 0 $shadow-darkPink;
      color: $pink;
      &:active,
      &:target {
        box-shadow: inset 0 4px 0 $shadow-inset-darkPink !important;
      }
    }
    &.button--color-orange {
      border: 1px solid $orange;
      color: $orange;
      box-shadow: 0 4px 0 $shadow-darkOrange;
      &:active,
      &:target {
        box-shadow: inset 0 4px 0 $shadow-inset-darkOrange !important;
      }
    }
    &.button--color-yellow {
      border: 1px solid $darkYellow;
      box-shadow: 0 4px 0 $shadow-darkYellow;
      color: $darkYellow;
      &:active,
      &:target {
        box-shadow: inset 0 4px 0 $shadow-inset-darkYellow !important;
      }
    }
    &.button--color-green {
      border: 1px solid $green;
      box-shadow: 0 4px 0 $shadow-darkGreen;
      color: $green;
      &:active,
      &:target {
        box-shadow: inset 0 4px 0 $shadow-inset-darkGreen !important;
      }
    }
    &.button--color-blue {
      border: 1px solid $blue;
      box-shadow: 0 4px 0 $shadow-darkBlue;
      color: $blue;
      &:active,
      &:target {
        box-shadow: inset 0 4px 0 $shadow-inset-darkBlue !important;
      }
    }
    &.button--color-purple {
      border: 1px solid $purple;
      box-shadow: 0 4px 0 $shadow-darkPurple;
      color: $darkPurple;
      &:active,
      &:target {
        box-shadow: inset 0 4px 0 $shadow-inset-darkPurple !important;
      }
    }
    &.button--color-white {
      border: 1px solid $blue;
      box-shadow: 0 4px 0 $shadow-darkBlue;
      color: $blue;
      &:active,
      &:target {
        box-shadow: inset 0 4px 0 $shadow-inset-darkBlue !important;
      }
    }
  }

  &.button-variant--minimal {  
    border-radius: 1em;
    padding: 0.2em 1em;  
    background-color: $white;
    color: $white;
    box-shadow: none !important;

    &:active,
    &:target {
      filter: brightness(85%);
    }

    &.button--color-default {
      color: $gray-4;
    }    

    &.button--color-grey {
      color: $gray-2;
      background-color: $gray-5;      

      &:active,
      &:target {
        filter: brightness(70%);
      }      
    }

    &.button--color-red {
      background-color: $red;
    }

    &.button--color-pink {
      background-color: $pink;
    }
    &.button--color-orange {
      background-color: $orange;
    }
    &.button--color-yellow {
      background-color: $darkYellow;
    }
    &.button--color-green {
      background-color: $green;
    }
    &.button--color-blue {
      background-color: $blue;
    }
    &.button--color-purple {
      background-color: $darkPurple;
    }
  }  

  // disabled

  &.disabled {
    pointer-events: none;
    border: none !important;
    color: $white !important;
    background-color: $gray-5 !important;
    box-shadow: none !important;
    // box-shadow: 0 4px 0 $shadow-darkGray !important;

    &.button-variant--outline {
      border: 1px solid $gray-4 !important;
      color: $gray-4 !important;
      background-color: $white !important;
      box-shadow: none !important;
    }

    .simple-button__experience {
      color: $shadow-darkGray;
    }
  }

  // experience tag
  .simple-button__experience {
    background: $white;
    border-radius: 32px;
    padding: 3px;
    margin-left: 8px;

    font-family: $secondary-font;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    color: $darkYellow;
    white-space: nowrap;
  }
}
