@import '../../../../assets/styles/common';

.building-progress-unblocked-card__container {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  width: 249px;
  height: 442px;
  flex-shrink: 0;
  border-radius: 0px 13px 13px 13px;
  background: $linear-purple;
  box-shadow: 0px 3px 3px 0px $shadow-black-1;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin: 40px 12px;
  cursor: pointer;
  margin-top: 80px;

  box-sizing: border-box;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid $green-light-hover;
  }

  .information-popup_message-wrapper-out {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 8px;
  }

  .information-popup_message-wrapper-in {
    margin-bottom: 0;
    padding: 0px 20px;
  }
}

.building-progress-unblocked-card__white-circle-number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $white-1;
  position: absolute;
  top: -16px;
  left: -9px;
  z-index: 0;
  color: $remarkable-purple;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.building-progress-unblocked-card__image-container {
  position: absolute;
  flex: 1;
  width: 100%;
  top: 50px;

  .building-progress-unblocked-card__background-image {
    position: absolute;
    top: -120px;
    width: 260px;
  }

  .building-progress-unblocked-card__building-image {
    position: absolute;
    width: 127px;
    height: 127px;
    top: -20px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .building-progress-unblocked-card__light-image {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    mix-blend-mode: screen;
    z-index: 0;
  }
}

.building-progress-unblocked-card__content-container {
  background-color: $dark-purple;
  border-radius: 15px;
  display: flex;
  padding: 12px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 20px);
  height: auto;
  flex-shrink: 0;
  margin-top: auto;
}

.building-progress-unblocked-card__progress-info {
  width: 100%;
}

.building-progress-unblocked-card__progress-text {
  color: $white-1;
  text-align: justify;
  text-shadow: 0px 1px 0px $shadow-black-1;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  width: 100%;
}

.building-progress-unblocked-card__progress-bar {
  display: flex;
  align-items: center;
  position: relative;
  background-color: $light-grey;
  border-radius: 10px;
  height: 18px;
  width: 100%;
}

.progress-icon__size {
  width: 24px;
  height: 24px;
}

.progress-icon__batteries {
  position: absolute;
  left: -33px;
  top: -10px;
}

.building-progress-unblocked-card__progress-level {
  height: 100%;
  border-radius: 10px 0 0 10px;
  background: $violet-light-1;
}

.building-progress-unblocked-card__progress-percentage {
  color: $white-1;
  font-size: 14px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  text-align: justify;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.building-progress-unblocked-card__progress-icon {
  position: absolute;
  right: -4px;
  top: -2px;
}

.progress-icon img {
  width: 18px;
  height: 18px;
}

.building-progress-unlocked-card__ranking-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5.936px;
  background: var(--Blueberry-Primary-Medium-blue, #5a34e1);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  width: 205px;
  height: 25px;
  padding: 5px 7px;
  flex-shrink: 0;
}

.building-progress-unlocked-card__left,
.building-progress-unlocked-card__right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.building-progress-unlocked-card__right {
  position: relative;
}

.building-progress-unlocked-card__left-name {
  overflow: hidden;
  color: var(--Neutral-Base-White, #fff);
  text-overflow: ellipsis;
  font-family: Poppins, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-left: 4px;
}

.building-progress-unlocked-card__right-background {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  padding: 1px 5px;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  height: 14px;
  border-radius: 3px;
  margin-right: 4px;
  // background: var(--Blueberry-Primary-Medium-blue, #4327a9);
  // box-shadow: 0px 2.443px 2.443px 0px rgba(0, 0, 0, 0.25);

  .building-progress-unlocked-card__user-ranking-avatar {
    .avatar-icon {
      width: 24px;
      height: 24px;
      top: -2px;
    }
  }
}

.building-progress-unlocked-card__right-experience {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 13px;
}

.building-progress-unlocked-card__left-position {
  color: #fff;
  text-align: center;
  text-shadow: 0px 1.484px 0px rgba(0, 0, 0, 0.25);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-right: 8px;
  margin-left: 8px;
}

@media (max-width: 359px) {
  .building-progress-unblocked-card__container {
    display: flex;
    height: auto;
    align-items: center;
    align-content: flex-end;
    flex-shrink: 0;
    flex-wrap: wrap;
    margin-bottom: 0;
    margin-top: 40px;
    padding: 0px 4px;
    gap: 4px;
    justify-content: flex-start;
  }

  .building-progress-unblocked-card__white-circle-number {
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .building-progress-unblocked-card__content-container {
    justify-content: center;
    align-items: center;
    margin-bottom: 14px;
    margin-right: 7px;
    width: 94%;
  }
}
