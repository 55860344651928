.reward-daily-goal {
  position: relative;

  .reward-popup__subtitle {
    color: var(--Gray-scale-white, #fff);
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    margin: 20px 0 30px 0;
    position: relative;
    z-index: 1;
  }

  .reward-popup__image {
    margin: 30px 0 50px 0;
  }
}

.reward-daily-goal__ribbon {
  margin-top: -28px !important;
}

.reward-daily-goal__text {
  margin-top: -62px;
  color: #fff;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  font-family: Quicksand, sans-serif;
  font-size: 36px;
  font-weight: 700;
}

.reward-daily-goal__streak-cards {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reward-daily-goal__streak-progress {
  display: flex;
  justify-content: center;
}

.reward-daily-goal__actions {
  margin-top: 24px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
}

.reward-daily-goal__button {
  display: flex;
  padding: 8px 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: #ffaf22;
  color: #1c292b;
  text-align: center;
  font-family: Quicksand, sans-serif;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

@media (max-width: 520px) {
  .reward-daily-goal .reward-popup__subtitle {
    color: var(--Gray-scale-white, #fff);
    text-align: center;

    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  .reward-daily-goal__streak-cards {
    .streak-card {
      display: none;

      &:nth-child(n + 1):nth-child(-n + 3) {
        display: block;
      }
    }
  }
}
