@import '../../../../theme/_media-queries.scss';

.quiz-loading {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;  
  z-index: 215;
  display: flex;
  flex-direction: column;
  align-items: center;

  .quiz-infinite-space {
    background-image: url('../../assets/backgorund_podium.svg');
    background-repeat: repeat-x;
    background-position: left center;
    background-size: auto 100%;
    animation: scrollSpace 3s linear infinite;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  /* La animación que hará que el fondo se desplace de derecha a izquierda */
  @keyframes scrollSpace {
    from {
      background-position: 0 center;
    }
    to {
      background-position: calc(100% - 100vw) center;
    }
  }

  .quiz-loading__title {
    color: #fff;
    font-family: Inter;
    font-size: 45px;
    font-weight: 700;
    padding: 128px 48px 8px 48px;
    display: flex;
    align-items: center;
    text-align: center;

    @media (max-width: $breakpoint-tablet) {
      padding: 128px 32px 8px 32px;
      font-size: 35px;
    }

    @media (max-width: $breakpoint-mobile) {
      padding: 128px 16px 8px 16px;
      font-size: 20px;
    }

    @media (max-height: 660px) {
      padding-top: 80px;
    }

    @media (max-height: 660px) {
      padding-top: 48px;
    }
  }

  .quiz-loading__title-game-started {
    font-size: 32px;
  }

  .quiz-loading__wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;

    .quiz-loading__ship_all {
      height: 70%;
      position: relative;
      display: flex;
      gap: 10px;
      align-items: center;

      animation: shipAll 3s linear infinite;

      .quiz-loading__ship {
        height: 50%;
        max-height: 180px;
      }

      .quiz-loading__smoke {
        height: 8%;
        position: relative;
        left: 0;

        margin-top: 15%;
        transform: scale(0.7) translateX(0);
        animation: smoke 0.8s linear infinite alternate;
      }
    }
  }

  @keyframes shipAll {
    0% {
      transform: rotate(0deg) translateX(40%) rotate(0deg);
    }
    100% {
      transform: rotate(360deg) translateX(40%) rotate(-360deg);
    }
  }

  @keyframes smoke {
    0% {
      margin-top: 15%;
      transform: scale(0.7) translateX(0);
    }
    25% {
      margin-top: 15%;
      transform: scale(0.7) translateX(0);
    }
    50% {
      margin-top: 10%;
      transform: scale(1.3) translateX(-19%);
    }
    100% {
      margin-top: 10%;
      transform: scale(1.3) translateX(-19%);
    }
  }


  .quiz-loading_text {
    color: #fff;
    font-family: Poppins, sans-serif;
    font-size: 35px; 
    font-weight: 700;
    padding-bottom: 48px;

    @media (max-width: $breakpoint-tablet) {
      font-size: 30px;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 20px;
    }
  }
}

/*
@media screen and (max-width: $breakpoint-desktop) and (max-height: $breakpoint-tablet) {
  .quiz-loading {
    .quiz-loading_text {
      margin-bottom: 130px;
      margin-top: -116px;
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) and (max-height: $breakpoint-desktop) {
  .quiz-loading {
    .quiz-loading_text {
      margin-bottom: 206px;
    }
  }
}

@media screen and (max-width: $breakpoint-desktop) and (max-height: 600px) {
  .quiz-loading__ship {
    margin-bottom: 50px;
  }
  .quiz-loading {
    .quiz-loading_text {
      margin-bottom: 111px;
    }
  }
}
*/
