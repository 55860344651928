html,
body,
div,
span,
applet,
object,
iframe,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li {
  border: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
picture,
img {
  display: block;
}

html, body {
  height: 100%;
  overflow: hidden;
}

body {
  line-height: 1;
}

blockquote,
q {
  quotes: none;
}

blockquote {

  &:before,
  &:after {
    content: '';
    content: none;
  }
}

q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

// Non-selectable content
* {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.lemo-editable-text,
input,
textarea {
  /* Exception for input areas (iOS fix)*/
  -webkit-touch-callout: default !important;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

// Disable pinch zoom
body {
  touch-action: pan-x pan-y
}
