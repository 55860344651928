@import '../../theme/_media-queries.scss';
@import '../../assets/styles/_common.scss';

.lobby-teacher-practice-page {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(
    --Linear-progress-dark,
    linear-gradient(180deg, #3a1587 9%, #490dc8 99.99%, #3703a4 100%)
  );
  z-index: 1002;
  animation: fadeIn 0.5s;
  background-image: url("./assets/backgroundLobby.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .container-countdown {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    min-height: 100vh;
    text-shadow: 0px 7px 27px rgba(0, 0, 0, 0.5);

    .text-countdown {
      @include inter-font($size: 200px, $weight: 700);
      color: #ffd600;
      line-height: 25px;
      word-wrap: break-word;
      text-align: center;
      z-index: 103;
      animation: fadeIn 0.5s;
    }
    .text-countdown-end {
      @include inter-font($size: 155px, $weight: 700);
      color: #ffd600;
      line-height: 200px;
      word-wrap: break-word;
      text-align: center;
      z-index: 103;
      animation: fadeIn 0.5s;

      @media (max-width: $breakpoint-mobile) {
        @include inter-font($size: 21vw, $weight: 700);
      }
    }
  }

  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-evenly;
    padding: 8px 0;
    align-self: center;
    align-content: center;
    height: 100vh;

    @media (max-width: $breakpoint-mobile) {
      justify-content:space-between;
    }

    @media (max-height: 630px) {
      padding: 0 0 4px 0;
    }

    &.center {
      justify-content: center;
    }

    .container-content {
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      width: 100%;
      height: 70vh;      

      @media (max-height: $breakpoint-mobile) {
        height: 60vh;
      }
    }

    .message-container-title {
      width: 489px;
      height: 41px;
      padding-left: 37px;
      padding-right: 37px;
      padding-top: 16px;
      padding-bottom: 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      display: inline-flex;
      z-index: 105;
      margin-top: 70px;
      margin-bottom: 120px;
    }

    .message-container {
      width: auto;
      height: 41px;
      padding: 16px 37px;
      background: $shadow-black-2;
      border-radius: 39px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      display: inline-flex;
      z-index: 105;
      margin-bottom: 24px;
      margin-top: 32px;
      min-width: 489px;

      
      @include inter-font($size: 18px, $weight: 700);
      color: white;
      line-height: 25px;
      word-wrap: break-word;
      
      
      @media (max-width: $breakpoint-mobile) {
        width: calc(100% - 32px);
        min-width: auto;
        height: 56px;        
        border-radius: 8px;
        font-size: 14px;
        margin-bottom: 12px;
        margin-top: 14px;
      }
      
      @media (max-height: 630px) {
        padding: 16px 37px;
        margin-top: 16px;
        margin-bottom: 0;        
        font-size: 16px;
      }

      @media (max-height: $breakpoint-mobile) {
        display: none;
      }
    }    

    .text-title {
      @include poppins-font($size: 28px, $weight: 700);
      color: white;
      line-height: 25px;
      word-wrap: break-word;
    }


    .players-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 1em;
      flex-wrap: nowrap;
      z-index: 105;
      justify-content: start;
      align-items: center;
      margin-bottom: 0px;
      overflow-y: auto;
      border-radius: 8px;
      background: $shadow-black-1;
      padding: 22px;

      max-width: 100%;
      width: 515px;
      @media (max-width: $breakpoint-tablet) {
          width: 362px;
      }

      &::-webkit-scrollbar {
        width: 5px;
        margin-right: 4px;
      }
      
      &::-webkit-scrollbar-track {
        background: transparent;
        margin-right: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.8);
        border-radius: 4px;
        margin-right: 4px;
      }

      .blocked {
        opacity: 0.2;

        .pompa {
          animation: none !important;
        }
      }
    }    

    .container-block-number-players {
      width: 402px;
      height: 173px;
      margin-bottom: 16px;
      position: relative;
      background: #683fac;
      box-shadow: 0px 2px 0px #9940c3 inset;
      border-radius: 8px;
      overflow: hidden;
      z-index: 102;
      display: flex;
      justify-content: center;
      align-self: center;
      flex-direction: column;
      align-items: center;
      background: radial-gradient(
        ellipse at center bottom,
        #a948d7 0%,
        #683fac 64%
      );

      .container-number-players {
        width: auto;
        padding: 5.41px 18px;
        position: relative;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        gap: 10.81px;
        display: inline-flex;
        height: 41px;
        margin-top: 16px;

        .container-number-players-child {
          color: white;
          line-height: 27.03px;
          @include inter-font($size: 16.46px, $weight: 700);
        }
      }

      .waiting-players {
        position: relative;
        color: $white;
        @include poppins-font($size: 22px, $weight: 700);
        margin-top: 31px;
      }

      @media (max-width: $breakpoint-mobile) {
        width: calc(100% - 16px);
        margin-top: 0px;
      }
    }
  }

  .container-fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    @media (max-width: $breakpoint-mobile) {
      display: none;
    }

    .container-fullscreen-child {
      display: flex;
      justify-content: right;
      align-items: center;
      z-index: 1000;

      .container-fullscreen-button {
        color: white;
        border: 1px #fff solid;
        padding: 3px;
        border-radius: 36px;
        font-size: 16px;
        cursor: pointer;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
        transition: all 0.3s ease;
        margin-top: 6px;
        margin-right: 6px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: $breakpoint-mobile) {
          margin-top: 2px;
          margin-right: 2px;
        }

        &:hover {
          background-color: rgb(0 0 0 / 32%);
          transform: scale(1.05);
        }

        &:active,
        &:focus {
          background-color: transparent;
          transform: scale(1);
        }

        img {
          width: 16px;
          height: 16px;
          color: white;
        }
      }
    }
  }
}
