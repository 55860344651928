.activity-blueberry-component {
  .activity-footer {
    position: relative;
    display: flex;
    align-items: center;
    place-content: space-between;
    // place-content: space-evenly;
    flex-wrap: wrap;
    gap: 16px;
    z-index: 12;
    // padding: 16px 25% 36px 25%;
    padding: 16px 0 36px 0;

    /*
  > div:first-child:not(:only-child) {
    margin-right: auto;
  }
  > div:last-child {
    margin-left: auto;
  }
    */

    &.activity-footer_phone-size {
      padding: 16px 0 16px 0;
    }

    &.activity-footer_tablet-size {
      // padding: 16px 15% 36px 15%;
      padding: 16px 0 24px 0;
    }
  }
}
