@import '../../../assets/styles/common';
@import '../../../theme/depths';

.meta-lesson-panel {
  width: 366px;
  height: auto;
  background: #683fac;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);

  border-radius: 20px;
  position: fixed;
  z-index: $z-panel;
  padding: 12px 14px 16px 12px;

  flex-direction: column;

  .meta-lesson-panel_decoration {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translate(-50%);
    width: 60%;
    height: calc(100% - 70px);
    background-size: 100% auto;
    background-repeat: repeat-y;
    z-index: $z-decoration;
    overflow: hidden;
  }

  .meta-lesson-panel__blocked {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .meta-lesson-panel__blocked-title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: var(--ui-primary-color);
    text-align: center;
    margin: 14px 0 12px 0;
  }

  .meta-lesson-panel__blocked-info {
    width: 285px;
    color: $white-1;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-top: 16px;
  }

  .meta-lesson-panel__previous-image {
    margin: 1em;
    width: 259px;
    height: 144px;
    border-radius: 24px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      transform: scale(0.6);
    }
  }

  .meta-lesson-panel__lesson-number {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: var(--ui-primary-color);
    margin: 16px 0;
    text-align: center;
  }

  .meta-lesson-panel__lesson-pieces {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px 12px 30px 12px;
    min-width: 200px;

    .meta-lesson-panel__lesson-pieces_gear {
      z-index: 2;

      svg {
        width: 44px;
        height: 44px;
      }
    }

    .meta-lesson-panel__lesson-pieces_bar {
      position: relative;
      /* Para moverlo tras el iconoy el número de piezas */
      left: -24px;
      /* Para moverlo tras el iconoy el número de piezas */
      margin-right: -34px;
      /* Para moverlo tras el iconoy el número de piezas */

      flex-grow: 1;
      height: 29px;
      border-radius: 22px;
      background: #3703a4;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;

      .meta-lesson-panel__lesson-pieces_bar_foregrond {
        height: 100%;
        border-radius: 18px;
        background: linear-gradient(180deg, #7c3de5 0%, #3c3cbf 100%);
        box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.15) inset,
          0px 5px 4px 0px rgba(255, 255, 255, 0.25) inset;
      }
    }

    .meta-lesson-panel__lesson-pieces_number {
      display: inline-flex;
      padding: 16px 8px 17px 8px;
      justify-content: center;
      align-items: center;
      border-radius: 40px;
      background: #fff;
      color: #000;
      font-family: Poppins, sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 0px;
      z-index: 2;

      div:first-of-type {
        font-size: 14px;
        font-weight: 700;
        padding-right: 3px;
      }

      div:last-of-type {
        padding-right: 1px;
      }
    }
  }

  .meta-lesson-panel__button {
    position: relative;
    z-index: $z-panel-content;
    display: flex;
    width: fit-content;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 36px;
    background: var(--Grays-white, #fff);
    color: var(--Blueberry-Primary-Medium-blue, #5a34e1);
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    cursor: pointer;
    margin: 0 auto;
  }

  @media screen and (max-width: 520px) {
    width: 100vw;
    margin: 0;
    z-index: $z-panel-mobile;
    border-radius: 20px 20px 0 0;
  }
}

.meta-lesson-panel.phaser-dom[data-fixed='true'] {
  top: auto !important;
  left: auto !important;
  position: relative !important;
  transform: none !important;
  visibility: visible !important;
}
