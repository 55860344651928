@import '../../../theme/index.scss';
@import '../../../theme/_media-queries.scss';
@import '../../../assets/styles/_common.scss';

.text-container-component {
  position: relative;
  width: 100%;
  max-width: 563px;
  margin: 0 auto;
  left: 0;
  transform: none;
  background-image: url('../../../assets/img/metaberry_ui/information-frame.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 1px;

  @media (max-width: $breakpoint-mobile) {
    max-width: 420px;
  }

  .text-container-component_content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 24px 32px;
    gap: 16px;

    .text-container-component_message {
      color: $white-1;
      text-align: center;
      text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);

      font-family: Poppins, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      white-space: normal;
      overflow: visible;
      word-wrap: break-word;
      flex: 1;
      align-self: center;

      @media (max-width: $breakpoint-mobile) {
        font-size: 18px;
      }
    }

    .text-container-component_audio-button {
      position: relative;
      z-index: 1;
      cursor: pointer;      
      min-width: 30px;
      height: 30px;
      display: flex;
      flex-shrink: 0;
    }
  }
}


