$font-path: '../assets/fonts';

$primary-font: 'Quicksand';
$secondary-font: 'Montserrat';

@mixin poppins-font($size: 14px, $weight: 400) {
  font-family: 'Poppins', sans-serif;
  font-size: $size;
  font-weight: $weight;
}

@mixin inter-font($size: 14px, $weight: 400) {
  font-family: 'Inter', sans-serif;
  font-size: $size;
  font-weight: $weight;
}

@font-face {
  font-family: 'Quicksand';
  src: url('#{$font-path}/Quicksand/Quicksand-Bold.woff2') format('woff2'),
    url('#{$font-path}/Quicksand/Quicksand-Bold.woff') format('woff'),
    url('#{$font-path}/Quicksand/Quicksand-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';

  src: url('#{$font-path}/Montserrat/Montserrat-BoldItalic.woff2')
      format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-BoldItalic.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';

  src: url('#{$font-path}/Montserrat/Montserrat-Bold.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-Bold.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';

  src: url('#{$font-path}/Montserrat/Montserrat-Italic.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-Italic.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';

  src: url('#{$font-path}/Montserrat/Montserrat-SemiBoldItalic.woff2')
      format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-SemiBoldItalic.woff')
      format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-SemiBoldItalic.ttf')
      format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';

  src: url('#{$font-path}/Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-SemiBold.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';

  src: url('#{$font-path}/Montserrat/Montserrat-Regular.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-Regular.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DS-Digital';
  src: url('#{$font-path}/DS-Digital/DS-DIGI.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
