.ruler-page {
  position: relative;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;

  .ruler-page_size {
    position: absolute;
    top: calc(50% - 0.5em);
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 30px;

    div {
      text-align: left;
      font-size: 1em;      
    }
    
    div:first-child {
      text-align: center;
      font-size: 2em;
      font-weight: bold;
    }    
  }

  .ruler-page_inner-size {
    //transform: translate(-50%, -50%);
    font-size: 1.5em;
    font-weight: bold;
    color: red;   
  }

  .title{
    font-weight: 700;
  }

  .ruler-page_width-wrapper {
    position: absolute;
    top: 0;
    left: 0;

    .ruler-page_width-block {
      display: inline-flex;
      justify-content: flex-end;
      align-items: flex-end;
      width: 100px;
      height: 30px;
      text-align: right;
      border-right: 1px solid black;
      box-sizing: border-box;
      padding-right: 0.2em;
    }
  }

  .ruler-page_height-wrapper {
    position: absolute;
    top: 0;
    left: 0;

    .ruler-page_height-block {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      width: 30px;
      height: 100px;
      border-bottom: 1px solid black;
      box-sizing: border-box;
      padding-bottom: 0.2em;
    }
  }
}
