.meta-popup-selector__button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 30px;

  .meta-popup-selector__button {
    z-index: 1000;
    font-family: 'Poppins', sans-serif;
    font-style: medium;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #5a34e1;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;

    min-width: 164px;
    min-height: 39px;
    width: fit-content;
    margin: 0 auto;

    background: #ffffff;
    border-radius: 18px;

    cursor: pointer;
  }
}

@media (max-width: 520px) and (max-height: 780px) {
  .meta-popup-selector__button {
    bottom: 300px;
  }
}
