@import '../../theme/colors.scss';
@import '../../theme/_media-queries.scss';

.teacher-practice-page {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1002;
  animation: fadeIn 0.5s;
  background: var(
    --Linear-progress-dark,
    linear-gradient(180deg, #3a1587 9%, #490dc8 99.99%, #3703a4 100%)
  );

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .header {
    color: white;
    width: 100%;
    z-index: 102;
    height: 100px;
    flex-shrink: 0;

    @media (max-height: $breakpoint-mobile) {
      height: 80px;
    }
  }

  .main {
    flex: 1;
    margin-bottom: 47px;
    overflow: hidden;
    max-width: 922px;
    align-self: center;
    width: calc(100% - 94px);

    @media (max-width: $breakpoint-laptop) {
      margin-bottom: 27px;
      width: calc(100% - 54px);
    }

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 16px;
      width: calc(100% - 32px);
    }

    @media (max-height: $breakpoint-mobile) {
      margin-bottom: 12px;
    }

    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 0;
      width: 100%;
      padding: 0;
      margin: 0;
      width: 100%;
    }

    .activity-blueberry-component {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: #ffffff;
      z-index: 102;
      padding: 32px;
      border-radius: 24px;

      @media (max-width: $breakpoint-mobile) {
        border-radius: 8px 8px 0 0;
        padding: 0 16px 16px 16px;
      }

      .feedback-panel-quiz {
        height: inherit;
      }

      .activity-view_content {
        padding: 0 48px;

        .activity-view_content__without-audio-button {
          flex: 1;

          @media (max-width: $breakpoint-mobile) {
            .number-question {
              margin-left: 48px;
            }

            .lemo-question-header {
              padding-left: 48px;
            }
          }
        }

        @media (max-width: $breakpoint-mobile) {
          padding: 0;
        }
      }

      .activity-footer {
        padding-bottom: 0;

        .activity-button {
          margin-left: auto;
          margin-right: auto;
          width: 70% !important;
        }
      }

      .activity-view__scroll-mark {
        padding-bottom: 88px;
      }
    }
  }

  .myButton {
    color: white;
    padding: 10px 20px;
    background-color: blue;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease;
  }

  .container-fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    @media (max-width: $breakpoint-mobile) {
      display: none;
    }

    .container-fullscreen-child {
      display: flex;
      justify-content: right;
      align-items: center;
      z-index: 1000;

      .container-fullscreen-button {
        color: white;
        border: 1px #fff solid;
        padding: 3px;
        border-radius: 36px;
        font-size: 16px;
        cursor: pointer;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
        transition: all 0.3s ease;
        margin-top: 6px;
        margin-right: 6px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: $breakpoint-mobile) {
          margin-top: 2px;
          margin-right: 2px;
        }

        &:hover {
          background-color: rgb(0 0 0 / 32%);
          transform: scale(1.05);
        }

        &:active,
        &:focus {
          background-color: transparent;
          transform: scale(1);
        }

        img {
          width: 16px;
          height: 16px;
          color: white;
        }
      }
    }
  }
}
