@import '../../../assets/styles/common';

.toast-drawer__container {
  bottom: 20px;
  margin-left: 0;
  z-index: 1000;

  border-radius: 20px;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  width: 342px;
  height: 122px;
  padding-right: 46px;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;

  .toast-drawer__icon {
    border-radius: 20px 0px 0px 20px;
    background: var(--green, #00e19a);
    display: flex;
    width: 15px;
    height: 341px;
    transform: rotate(90deg);
    padding: 10px 7px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    top: -60px;
    left: 164px;
  }

  img {
    width: 34px;
    height: 34px;
    transform: rotate(-90deg);
  }

  .toast-drawer__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    color: $white-1;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
}
