@import '../../../../../assets/styles/common';

.lesson-item {
  display: flex;
  gap: 16px;
  align-items: center;
  margin: 8px 0;
  padding: 10px;
  background: $shadow-black-1;
  border-radius: 5px;
  width: 100%;

  @media screen and (max-width: 400px) {
    max-width: 300px;
  }

  .lesson-item_name {
    color: $white-1;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    padding-bottom: 10px;
  }

  .lesson-item_battery-status {
    height: 60px;
  }

  .lesson-item_lesson-progress {
    flex: 1;
  }
}
