@import '../../../assets/styles/common';
@import '../../../theme/depths';

.meta-unit-panel {
  width: 320px;
  height: auto;
  backdrop-filter: blur(4px);
  position: absolute;
  z-index: $z-panel;
  top: 64px;
  padding: 5px 7px 12px 7px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: #683fac;
  .meta-unit-panel_decoration {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translate(-50%);
    width: 60%;
    height: calc(100% - 70px);
    background-size: 100% auto;
    background-repeat: repeat-y;
    z-index: $z-decoration;
    overflow: hidden;
  }

  .meta-unit-panel__blocked {
    display: flex;
    flex-direction: column;
    align-items: center;

    .meta-unit-panel__previous-image {
      padding: 0 1em 1em 1em;

      img {
        width: 100%;
        border-radius: 8px;
        border: 2px solid var(--ui-primary-color);
      }
    }
  }

  .meta-unit-panel__blocked-icon {
    svg path {
      fill: var(--ui-primary-color);
    }
  }

  .meta-unit-panel__blocked-title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: var(--ui-primary-color);
    text-align: center;
    margin: 14px 0 12px 0;
  }

  .meta-unit-panel__blocked-info {
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--ui-primary-color);
    text-align: center;
    margin-bottom: 24px;
  }

  .meta-unit-panel__previous-image {
    padding: 0 1em 1em 1em;

    img {
      width: 100%;
      border-radius: 8px;
      border: 1px solid var(--ui-primary-color);
    }
  }

  .meta-unit-panel__unit-title {
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 34px;

    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #3703a4;
    padding: 8px;
    border-radius: 14px 14px 4px 4px;
    text-align: center;
  }

  .meta-unit-panel__ranking {
    margin: 16px 0 0 0;
    padding: 6px;
    z-index: $z-panel-content;
  }

  .meta-ranking__no-data {
    height: 95px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .meta-unit-panel__button {
    z-index: $z-panel-content;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-radius: 36px;
    padding: 12px 32px;
    color: #5a34e1;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    width: fit-content;
    margin: 0 auto;
  }

  .meta-unit-panel__button-mt {
    margin-top: 25px;
  }

  @media screen and (max-width: 520px) {
    width: 100vw;
    margin: 0;
    z-index: $z-panel-mobile;
    border-radius: 20px 20px 0 0;
  }
}

.meta-unit-panel.phaser-dom[data-fixed='true'] {
  height: auto;
  top: auto !important;
  left: auto !important;
  position: relative !important;
  transform: none !important;
  visibility: visible !important;
}
