@import '../../../assets/styles/blueberry.scss';

.activity-blueberry-component {
  .feedback-box {
    @include activity-primary-font;
    padding: 24px 0;
    position: relative;

    .feedback-box__blueberro-hint {
      position: absolute;
      left: 18px;
      top: -16px;
    }

    .feedback-box__box {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 24px;
      border-radius: 12px;
      gap: 12px;
      align-items: center;
      background-color: map-get($activity-colors, white-bb);
      z-index: 12;

      .feedback-box__title {
        font-family: Poppins, sans-serif;
        font-size: 18px;
        font-weight: 700;
      }

      .feedback-box__content {
        flex: 1;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: baseline;
        gap: 10px;

        .lemonade-feedback-message {
          color: map-get($activity-colors, gray-01);
          p {
            margin-bottom: 7px;
          }
        }
      }

      &.type-correct {
        border: 3px solid map-get($activity-colors, green-bb);

        .feedback-box__title {
          color: #00e19a;
        }

        .feedback-box__blueberro-image {
          display: block;
          position: absolute;
          top: 12px;
          width: 70px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &.type-incorrect {
        border: 3px solid map-get($activity-colors, red-bb);

        .feedback-box__title {
          color: #ff3d30;
        }

        .feedback-box__blueberro-image {
          display: block;
          position: absolute;
          top: 12px;
          width: 70px;
          left: 50%;
          transform: translateX(-50%);
        }

        @media screen and (min-width: 768px) {
          .feedback-box__title {
            display: none;
          }
        }
      }

      &.type-hint {
        border: 3px solid map-get($activity-colors, primary-medium);
      }

      .practice-button {
        margin-top: 12px;
      }
    }

    .feedback-box__content-audio {
      display: flex;
      padding: 8px;
      align-items: center;
      place-content: center;
      width: 36px;
      height: 36px;
      border-radius: 18px;
      z-index: 6;

      .speak-button svg {
        height: 100%;
        width: auto;
      }
    }
  }
}
