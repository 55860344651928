@import '../../../../theme/_media-queries.scss';

.quiz-podium {
  // Altura de la caja de resultados: padding + fuente + padding + padding + fuente + padding + padding
  $results-height: calc(24px + 14px + 10px + 36px + 30px + 36px + 24px);

  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  // background: center / cover no-repeat url('../../assets/backgorund_podium.svg');
  background: linear-gradient(180deg, #3a1587 9%, #490dc8 99.99%, #3703a4 100%);
  z-index: 15;

  .quiz-podium__wrapper {
    height: 100%;
    width: fit-content;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    .quiz-podium__title {
      color: #fff;
      font-family: Inter;
      font-size: 45px;
      font-weight: 700;
      padding: 16px 16px 0 16px;
      text-align: center;

      @media screen and (max-width: $breakpoint-tablet),
        screen and (max-height: $breakpoint-tablet) {
        font-size: 38px;
      }

      @media screen and (max-width: $breakpoint-mobile) {
        font-size: 32px;
      }

      @media screen and (max-height: $breakpoint-mobile) {
        font-size: 28px;
      }
    }

    .quiz-podium__body {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;

      .quiz-podium__podium_star {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        transform: scale(0);
        opacity: 0;
        animation: starAppear 0.5s ease 3s forwards;

        img {
          height: 64px;
        }

        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-61%, -39%);
          font-family: Poppins, sans-serif;
          color: #3703a4;
          font-weight: 700;
          font-size: 30px;
        }

        @media screen and (max-height: $breakpoint-mobile) {
          img {
            height: 44px;
          }

          span {
            transform: translate(-61%, -36%);
            font-size: 22px;
          }
        }
      }

      .quiz-podium__content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .quiz-podium__mount_wrapper {
          position: relative;
          width: 100%;
          aspect-ratio: 2/1;

          @media screen and (max-height: 621px),
            screen and (max-height: $breakpoint-mobile),
            screen and (max-height: 400px) {
            aspect-ratio: 3/1;
          }

          .podium_mount {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 90%;
            animation: mountUp 0.5s ease 1s forwards;
            bottom: -100vh;
            z-index: 1;

            @media screen and (max-height: 621px) {
              max-width: 60%;
            }

            @media screen and (max-height: $breakpoint-mobile) {
              max-height: 126px;
            }

            @media screen and (max-height: 400px) {
              max-height: 96px;
            }
          }

          @keyframes mountUp {
            from {
              bottom: -100vh;
            }
            to {
              bottom: 0;
            }
          }

          .podium_avatar_wrapper {
            position: absolute;
            height: 50%;
            left: 50%;
            transform: translateX(-50%);
            top: -400%;
            z-index: 2;
            animation: avatarDown 0.5s ease 2s forwards;

            @media screen and (max-height: $breakpoint-mobile) {
              height: 45%;
            }

            @media screen and (max-height: 400px) {
              height: 40%;
            }

            @keyframes avatarDown {
              from {
                top: -400%;
              }
              to {
                top: 0;
              }
            }

            .podium_avatar {
              position: absolute;
              height: 76%;
              z-index: 1;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              animation: pulsePodium 2s infinite 2s;
            }

            .podium_bubble {
              position: absolute;
              height: 100%;
              z-index: 2;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              animation: pulsePodium 2s infinite 2s;
            }

            @keyframes pulsePodium {
              0% {
                transform: translate(-50%, -50%) scale(0.95);
              }
              70% {
                transform: translate(-50%, -50%) scale(1.05);
              }
              100% {
                transform: translate(-50%, -50%) scale(0.95);
              }
            }
          }
        }

        .quiz-podium__results {
          position: relative;
          z-index: 2;
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          background: #5a34e1;
          font-family: Poppins;
          font-weight: 700;
          color: #fff;
          gap: 24px;
          padding: 24px;
          border-radius: 24px;
          margin-top: -32px;

          @media screen and (max-height: 566px) {
            padding: 16px;
            margin-top: -50px;
          }

          .quiz-podium__results_points,
          .quiz-podium__results_questions {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
            align-items: stretch;
            text-align: center;
            gap: 10px;
          }

          .quiz-podium__results_points_text,
          .quiz-podium__results_questions_text {
            text-transform: uppercase;
            white-space: nowrap;
            font-size: 14px;

            @media screen and (max-width: $breakpoint-mobile) {
              font-size: 12px;
            }
          }

          .quiz-podium__results_points_user_points,
          .quiz-podium__results_questions_user_questions {
            white-space: nowrap;
            border-radius: 14px;
            background: rgba(0, 0, 0, 0.38);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            overflow: hidden;
            font-size: 30px;
            font-weight: 700;
            padding: 36px 73px;

            @media screen and (max-width: 590px) {
              padding: 30px 66px;
              font-size: 18px;
            }

            @media screen and (max-width: $breakpoint-mobile),
              screen and (max-height: $breakpoint-mobile) {
              padding: 24px 36px;
              font-size: 16px;
            }

            @media screen and (max-width: 370px),
              screen and (max-height: 446px) {
              padding: 16px 20px;
              font-size: 12px;
            }
          }
        }
      }
    }

    .quiz-podium__button_exit {
      cursor: pointer;
      display: inline-flex;
      padding: 12px 32px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 36px;
      border: 2px solid #5a34e1;
      background-color: #fff;
      color: #5a34e1;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-top: 8px;
      width: 74%;

      @media screen and (max-height: 460px) {
        padding: 8px 32px;
      }
    }

    .quiz-podium__button_exit:active {
      color: #3703a4;
      border-color: #3703a4;
    }
  }

  @keyframes starAppear {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    80% {
      transform: scale(1.2);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}
