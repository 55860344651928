@import '../../../assets/styles/common.scss';

.tooltip {
  position: relative;
  display: inline-block;

  &:hover {
    .tooltip__message {
      visibility: visible;
      opacity: 1;
    }
  }

  &__message {
    color: $white-1;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: 1000;
    width: max-content;
    padding: 6px 12px;
    border-radius: 8px;
    background: #3703a4;
    transition: opacity 0.2s ease-in-out;

    // Flecha del tooltip
    &::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background: #3703a4;
      transform: rotate(45deg);
    }

    // Posicionamiento según la dirección
    &--right {
      left: calc(100% + 15px);
      top: 50%;
      transform: translateY(-50%);

      &::before {
        left: -4px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
    }

    &--left {
      right: calc(100% + 15px);
      top: 50%;
      transform: translateY(-50%);

      &::before {
        right: -6px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
    }

    &--top {
      bottom: calc(100% + 15px);
      left: 50%;
      transform: translateX(-50%);

      &::before {
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }
    }

    &--bottom {
      top: calc(100% + 15px);
      left: 50%;
      transform: translateX(-50%);

      &::before {
        top: -6px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }
    }
  }
}
