@import '../../../assets/styles/common.scss';

// Base styles for all bold titles
.bold-base {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  color: $white-1;
  text-align: center;
  text-shadow: 0px 1.705px 0px rgba(0, 0, 0, 0.25);
  white-space: normal;
  overflow-wrap: break-word;
  padding: 10px;
}

// Title variations

.boldXS {
  @extend .bold-base;
  font-size: 10px;
  line-height: normal;
}

.boldS {
  @extend .bold-base;
  font-size: 12px;
  line-height: normal;
}

.boldM {
  @extend .bold-base;
  font-size: 14px;
  line-height: 22px;
}

.boldL {
  @extend .bold-base;
  font-size: 16px;
  line-height: 24px;
}

.boldXL {
  @extend .bold-base;
  font-size: 18px;
  line-height: 24px;
}

.boldXXL {
  @extend .bold-base;
  font-size: 20px;
  line-height: 24px;
}
