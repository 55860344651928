  .meta-sound-menu__backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(28, 41, 43, 0.5);
    z-index: 100;
  }

  .meta-sound-menu {
    position: absolute;
    z-index: 101;
    top: 64px;
    right: 55px;

    border-radius: 12px;
    background: #5A34E1;
    box-shadow: -8px -5px 12px 0px rgba(0, 0, 0, 0.17) inset;
    padding: 15px 13px;

    .meta-sound-menu__content {
      border-radius: 8px;
      background: #3703A4;
      font-family: Poppins, sans-serif;
      font-size: 16px;
      line-height: 24px;
      width: 273px;
      height: auto;

      width: 235px;
      padding: 14px 12px;

      .meta-sound-menu__item {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0
        }
      }

      .switch {
        position: relative;
        display: flex;
        width: 27px;
        height: 17px;
        cursor: pointer;
      }

      .switch__text {
        position: absolute;
        width: 180px;
        left: 36px;
        color: var(--ui-switch-off-color)
      }

      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        -webkit-transition: .4s;
        transition: .4s;
        border: 2px solid var(--ui-switch-off-color);
        border-radius: 34px;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        left: -2px;
        bottom: -2px;
        -webkit-transition: .3s;
        transition: .3s;
        border: 2px solid var(--ui-switch-off-color);
        background-color: var(--ui-switch-button-color);
        border-radius: 50%;
      }

      input:checked+.slider {
        background-color: var(--ui-switch-on-color);
        border: 2px solid var(--ui-switch-on-color);

        &+.switch__text {
          color: var(--ui-switch-on-color)
        }
      }

      input:focus+.slider {
        box-shadow: 0 0 1px var(--ui-switch-on-color)
      }

      input:checked+.slider:before {
        -webkit-transform: translateX(10px);
        -ms-transform: translateX(10px);
        transform: translateX(10px);
        border: 2px solid var(--ui-switch-on-color);
        background-color: var(--ui-switch-button-color);
      }

    }
  }
