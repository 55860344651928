.my-profile-players {

  .profiles-form__single-profile {
    display: flex;
    flex-direction: column;
    width: 100%;

    .profiles-form__single-profile-row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
    }

    .form-input {
      width: 100%;
      flex-grow: 1;
      margin-right: 9px;
      min-width: 190px;
    }

    .form-select {
      width: 100%;
      flex-grow: 1;
      margin-left: 9px;
    }

    .profiles-form__single-profile-edit {
      .form-button {
        width: auto;
        flex-grow: 0;
        padding: 0;
        height: 46px;
        margin: 8px auto;

        .form-button__icon {
          margin-left: 0;
          margin-right: 9px;
        }
      }
    }

    .profiles-form__single-profile-delete {
      .form-button {
        width: auto;
        flex-grow: 0;
        padding: 0;
        height: 46px;
        margin: 8px auto;

        .form-button__icon {
          margin-right: 0;
          margin-left: 9px;
        }
      }
    }

    .profiles-form__single-profile-save {
      .form-button {
        display: inline-flex;
        width: auto;
        padding: 0;
        margin-bottom: 12px;
      }
    }

    &:first-child {

      .form-button,
      .form-input,
      .form-select {
        margin-top: 0
      }
    }

    &:last-child {
      .profiles-form__single-profile-save {
        .form-button {
          margin-bottom: 0;
        }
      }
    }
  }

  .profiles-form__error {
    margin-top: 6px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #FF3D30;
  }

  .profiles-form__actions {
    .form-button {
      display: inline-flex;
      width: auto;
      padding: 0;
    }
  }

  .blueberry-family-panel__actions {
    margin-top: 32px;
  }
}
