.internet-connection-alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  text-align: left;
  z-index: 1000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 25px;
  border-top: 5px solid #FF3D30;
  padding-left: 32px;
  min-height: 60px; 
  line-height: 22px;
  
  &.slow {
    background-color: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #647082;
  }
  
  &.offline {
    background-color: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #647082;
  }

  .internet-connection-alert-icon-container{
    display: flex;
  }

  .internet-connection-alert-close-container {
    margin-left: auto;
    padding-right: 24px;
    cursor: pointer;
    .internet-connection-alert-close-container-svg {
      fill: #4D586C;
    }
  }
  
}