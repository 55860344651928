@import '../../../../../assets/styles/common';

.lesson-progress-bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  max-width: 600px;
  height: 20px;

  .lesson-progress-bar_icon-start,
  .lesson-progress-bar_icon-end {
    width: 30px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
  }

  .lesson-progress-bar_icon-start {
    left: 0;
  }

  .lesson-progress-bar_icon-end {
    right: 0;    
  }

  .lesson-progress-bar_back {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    border-radius: 14px;
    background: linear-gradient(
      180deg,
      #3a1587 9%,
      #490dc8 99.99%,
      rgba(55, 3, 164, 0) 100%
    );

    .lesson-progress-bar_fill {
      height: 100%;
      border-radius: 14px;
      background: linear-gradient(180deg, #7c3de5 22.56%, #3c3cbf 100%);
    }
  }

  .lesson-progress-bar_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -49%);

    color: #fff;
    text-shadow: 0px 1.388px 0px rgba(0, 0, 0, 0.2);
    font-family: Poppins;
    font-size: 10px;
    font-weight: 700;
  }
}
