.menu-drawer {
  z-index: 900;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  .menu-drawer_background {
    width: 343px;
    height: 100vh;
    flex-shrink: 0;

    border-radius: 14px 0px 0px 14px;
    background: var(--COVER-Blueberry, #5a34e1);
    box-shadow: 0px 1px 43px 0px rgba(0, 0, 0, 0.37),
      -6px 6px 10px 0px rgba(0, 0, 0, 0.25),
      -10px -10px 63px 0px rgba(0, 0, 0, 0.25) inset;
    .menu-drawer_header-container {
      height: 110px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .menu-drawer_header-name {
        display: flex;
        width: 242px;
        height: 100px;
        flex-direction: column;
        justify-content: center;
        margin-left: 40px;
        margin-right: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        color: var(--Neutral-Base-White, #fff);
        font-family: Poppins, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .menu-drawer_close-button {
        cursor: pointer;
        border-radius: 22.5px;
        border: 1.25px solid var(--Grays-white, #fff);
        background: rgba(255, 255, 255, 0.3);

        display: flex;
        width: 35px;
        height: 35px;
        padding: 7.5px 13.75px;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }
    }
    .menu-drawer_options-background {
      display: flex;
      width: 309px;
      height: calc(100% - 200px);
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      flex-shrink: 0;
      margin-left: auto;

      border-radius: 8px;
      background: var(--Blueberry-Blue-Blue-02, #3703a4);
      position: relative;
      .menu-drawer_options-container {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .menu-drawer_option {
          display: flex;
          align-items: center;
          gap: 12px;
          cursor: pointer;

          .menu-drawer__option-text {
            color: #fff;
            text-align: center;

            font-family: Poppins, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
          }
        }

        .menu-drawer__option-text-container {
          .menu-drawer__option-exclamation {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 26px;
            right: 36%;
            width: 20px;
            height: 20px;
            color: #ffffff;
            background-color: #ff0000;
            border-radius: 50%;
            box-shadow: 0px 10px 2px 0px rgba(0, 0, 0, 0.25);
            font-family: Montserrat, sans-serif;
            font-weight: 600;
            font-size: 14px;
            z-index: 9;
          }
        }
      }

      .menu-drawer_separator {
        width: 261px;
        height: 1px;
        opacity: 0.25;
        background: #fff;
      }

      .menu-drawer_logout-container {
        position: absolute;
        bottom: 10%;
        left: 24px;
        display: flex;
        align-items: center;
        gap: 12px;
        .menu-drawer_option-text {
          color: #fff;
          text-align: center;

          font-family: Poppins, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }
  }
}

.map-user-menu-modal__action-dropdown__options {
  display: flex;
  width: 240px;
  padding: 14px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;

  border-radius: 8px;
  background: rgba(0, 0, 0, 0.31);

  svg {
    margin-left: 6px;
  }
}

.menu-drawer__options-text {
  color: var(--Grays-white, #fff);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;

  position: absolute;
  width: 180px;
  left: 36px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 2px solid var(--ui-switch-off-color);
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 13px;
  width: 13px;
  left: -2px;
  bottom: -2px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border: 2px solid var(--ui-switch-off-color);
  background-color: var(--ui-switch-button-color);
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--ui-switch-on-color);
  border: 2px solid var(--ui-switch-on-color);

  & + .switch__text {
    color: var(--ui-switch-on-color);
  }
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--ui-switch-on-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
  border: 2px solid var(--ui-switch-on-color);
  background-color: var(--ui-switch-button-color);
}

.switch {
  position: relative;
  display: flex;
  width: 27px;
  height: 17px;
  cursor: pointer;
}

.switch__text {
  position: absolute;
  width: 180px;
  left: 36px;
  color: var(--ui-switch-off-color);
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.menu-drawer_header-name {
  .tooltip {
    .tooltip__message {
      display: flex;
      padding: 6px 12px;
      align-items: flex-start;
      gap: 10px;
      border-radius: 8px;
      background: var(--Grays-white, #fff);
      color: var(--COVER-Blueberry, #5a34e1);
      font-family: Poppins, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      &::before {
        background: var(--Grays-white, #fff);
      }

      &--bottom {
        top: 34px;
        transform: translateX(calc(0px - 120px));
        min-width: max-content;

        &::before {
          top: -3px;
          left: 16px;
        }
      }
    }
  }
}

@keyframes drawer-slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes drawer-slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.menu-drawer__language-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  svg {
    margin-left: 8px;
  }
}
