.map-daily-goal-progress-bar {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;

  .map-daily-goal-progress-bar__icon {
    margin-left: -18px;
    margin-right: 4px;
    height: 40px;
    width: 32px;
    flex-grow: 1;
    flex-shrink: 0;
    position: relative;
    z-index: 1;

    img {
      width: 200%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .map-daily-goal-progress-bar__bar-container {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    max-width: 431px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .map-daily-goal-progress-bar__bg {
      width: 100%;
      height: 12px;
      border-radius: 50px;
      background: rgba(255, 255, 255, 0.25);
      position: relative;
    }

    .map-daily-goal-progress-bar__progress {
      background: #fff;
      height: 12px;
      border-radius: 50px;
      position: absolute;
      left: 0;
      transition: width ease-in-out 1s;
    }

    .map-daily-goal-progress-bar__progress--completed {
      background: #00e19a;
      box-shadow: 0px 4px 3px 0px rgba(255, 255, 255, 0.7) inset;
    }

    @-webkit-keyframes green-shine {
      from {
        -webkit-filter: drop-shadow(0px 0px 12px transparent);
      }

      to {
        -webkit-filter: drop-shadow(0px 0px 12px #a8fcca);
      }
    }

    @keyframes green-shine {
      from {
        filter: drop-shadow(0px 0px 12px transparent);
      }

      to {
        filter: drop-shadow(0px 0px 12px #a8fcca);
      }
    }

    .green-shine {
      animation: green-shine 0.5s;
      -webkit-animation: green-shine 0.5s;
      animation-fill-mode: forwards;
      -webkit-animation-fill-mode: forwards;
    }
  }
}

@media screen and (max-width: 520px) {
  .map-daily-goal-progress-bar .map-daily-goal-progress-bar__icon {
    width: 20px;
    height: 24px;
  }
  .map-daily-goal-progress-bar
    .map-daily-goal-progress-bar__bar-container
    .map-daily-goal-progress-bar__bg {
    height: 7px;
  }
  .map-daily-goal-progress-bar
    .map-daily-goal-progress-bar__bar-container
    .map-daily-goal-progress-bar__progress {
    height: 7px;
  }
}
