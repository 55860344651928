.app-practice-page {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #ffffff;
  z-index: 101;
}

.app-practice-page.uppercase-required,.app-practice-page.uppercase-required * {
  text-transform: uppercase !important;
}
