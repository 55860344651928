.streak-card {
  height: 136px;
  width: 96px;
  padding: 0 0 8px 0px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.5);
  margin: 7px;
  position: relative;
}

.streak-card__ribbon-wrapper {
  width: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: -8px;
}

.streak-card__ribbon img {
  width: 76px;
  height: 30px;
}

.streak-card__number {
  color: #fff;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);

  position: absolute;
  top: 2px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.streak-card__top-text {
  color: var(--Grays-gray-07, #c8cfda);
  text-align: right;

  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;

  border-radius: 40px;
  background: #291545;

  padding: 4px 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: -12px;
  left: 50%;
  transform: translate(-50%);
}

// variants
.streak-card--today {
  border-radius: 12px;
  background: linear-gradient(180deg, #00e19a 0%, #50cdc7 100%);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.2);
  height: 144px;
}

.streak-card__image {
  position: relative;
  z-index: 1;
}

.streak-card__background {
  img {
    position: absolute;
    top: 11px;
    left: -27px;
    width: 150px;
    height: 90px;
    z-index: 0;
  }
}
