.login-page {
  height: 100vh;
  height: -webkit-fill-available;
  background-color: royalblue;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.login-page__container {
  border-radius: 14px;
  background: var(--Grays-White, #fff);
  padding: 24px 36px 36px 36px;
  z-index: 2;
  align-items: center;
  width: 440px;
  justify-content: space-between;
  position: relative;
}

.login-page__cover-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.login-page_rocket {
  position: absolute;
  bottom: 0px;
  left: 0px;
  pointer-events: none;
}

.login-page_luna {
  position: absolute;
  right: -90px;
  top: -40px;
}
.login-page__bb-logo-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.login-page__bb-logo {
  margin-top: 50px;

  svg {
    width: 100%;
  }
}

.login-title {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
  color: var(--Neutral-Grays-Gray-02, #4d586c);
  line-height: 26px;
}

.login-page__google-button-container,
.login-page__layers-button-container {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 16px;
}
.login-page-content {
  .login-page__login-form {    
    .login-page__google-button-container,
    .login-page__layers-button-container {
      .login-page__google-button {
        border: none;
      }
    }
    .login-form{
      margin-top: 24px;
    }
  }
}

.login-page__layers-button-container {
  width: 100%;

  layers-auth-button {
    width: 98%;
    border-radius: 25px;
    height: 31px;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.login-page__signup-button {
  margin-top: 8px;
}

.login-page__oc-logo {
  display: flex;
  height: 34px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 3px;
}

.login-page__separator {
  width: 100%;
  margin: 24px 0 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  height: 22px;

  .login-page__separator-line {
    display: inline-block;
    flex-grow: 1;
    width: 100%;
    height: 2px;
    background: rgba(195, 194, 207, 0.6);
    border-radius: 10px;
  }

  .login-page__separator-text {
    display: inline-block;
    margin: 0 12px;
    flex-grow: 0;
    color: #939db0;

    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 21px;
  }
}

.login-page_scroll-container {
  height: 100vh;
  height: -webkit-fill-available;
  overflow: auto;
  z-index: 1;
  justify-content: center;
  align-items: center;
  display: grid;
  width: 100%;
  /* padding-top: 40px;
  padding-bottom: 40px; */
}

@media screen and (max-width: 520px) {
  .login-page_scroll-container {
    justify-content: normal;
  }

  .login-page__container {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 2%;
    .login-page-content {
      margin-top: 164px;
    }
  }
  .login-page_luna {
    right: 16px;
    top: 58px;
    width: 56px;
    height: 56px;
    z-index: 2;
  }

  .login-page_rocket {
    top: -70px;
    left: -76px;
    z-index: 2;
    width: 350px;
  }
  .login-page_header-mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-image: url('../../assets/img/fondo-espacial-login.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 225px;
  }
  .login-page__bb-logo-container {
    position: relative;

    .login-page__bb-logo {
      margin-top: 16px;      
      width: 139px;      
      path {
        fill: #fff;
      }
    }
  }
}
