.generic-error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;

  .generic-error-page_container {
    .error-page__button-container {
      .simple-button__text {
        color: #5a34e1;
        display: flex;
        width: 219px;
        padding: 12px 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 36px;
        font-family: Poppins, sans-serif;
      }
    }
  }
}
.generic-error-page_background {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(./../../assets/img/Background_generic_error.png);
}
.generic-error-page_container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -30px;
}
.generic-error-page_message-container {
  max-width: 850px;
}
.error-page__message {
  color: #fff;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.error-page__button-container {
  margin-top: 30px;
  margin-bottom: 100px;
}


@media screen and (min-height: 480px) and (max-height: 550px) {
  .generic-error-page_message-container {
    margin-bottom: -16px;
  }
}

@media screen and (max-width: 520px) {
  .generic-error-page_background {
    background-image: url(./../../assets/img/bg_new.png);
  }
  .generic-error-page_container {
    height: 100%;
    background-image: url(./../../assets/img/img-oh-no-min.png);
    background-size: 125%;
    background-repeat: no-repeat;
    background-position: 150% 0%;
  }
  .error-page__message {    
    font-size: 16px;
    padding: 0 36px;
  }
  .error-page__button-container {
    position: absolute;
    bottom: 0;
  }
}
