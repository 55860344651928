.activity-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  background-color: #ffffff;
  font-family: Poppins;
  text-align: center;
  font-style: normal;

  .activity-loading_frame {
    height: auto;
    width: 90%;
    max-width: 384px;
    padding-bottom: 48px;
  }

  .activity-loading_frame-boy-moon {
    position: relative;
    height: min(90vh, 90vw, 384px);
    width: min(90vh, 90vw, 384px);
    padding-bottom: 48px;
  }

  .activity-loading_moon {
    position: absolute;
    top: 50%;
    left: 52%;
    transform: translate(-20%, -30%);
    width: 25%;
    height: auto;
  }

  .activity-loading_boy {
    position: absolute;
    top: 10%;
    left: 20%;
    transform: translate(-50%, -50%);
    animation: boyFloating 2.8s linear infinite;
  }

  @keyframes boyFloating {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(50px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .activity-loading_title {
    color: #5a34e1;
    font-size: 22px;
    font-weight: 700;
    line-height: 29px;
    padding-bottom: 12px;
  }

  .activity-loading_message {
    max-width: 80%;
    color: #647082;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }

  @media (min-width: 500px) {
    .activity-loading_title {
      font-size: 32px;
      line-height: 39px;
    }

    .activity-loading_message {
      font-size: 20px;
      line-height: 32px;
    }
  }
}
