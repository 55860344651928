.reward-popup__backdrop {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
}

.reward-popup {
  width: 649px;
  height: 446px;
  padding: 18px 24px;

  border-radius: 8px;
  background: #683fac;
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.25),
    0px 2px 0px 0px #9940c3 inset;

  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);

  z-index: 101;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @-webkit-keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  &.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }

  .reward-popup__decoration {
    position: absolute;
    top: 1%;
    left: 50%;
    transform: translate(-50%);
    width: 60%;
    height: 100%;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA0OTEgNDUzIj48ZWxsaXBzZSBjeD0iMjQ1LjQ2NyIgY3k9IjIwNy42MTQiIGZpbGw9InVybCgjYSkiIGZpbGwtb3BhY2l0eT0iLjUiIHJ4PSIyNDQuOTY0IiByeT0iMjQ0LjQ4MSIvPjxkZWZzPjxyYWRpYWxHcmFkaWVudCBpZD0iYSIgY3g9IjAiIGN5PSIwIiByPSIxIiBncmFkaWVudFRyYW5zZm9ybT0ibWF0cml4KDAgMjQ0LjQ4MSAtMjQ0Ljk2NCAwIDI0NS40NjcgMjA3LjYxNCkiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj48c3RvcCBzdG9wLWNvbG9yPSIjRkM1QUZGIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjQzY2QUZGIiBzdG9wLW9wYWNpdHk9IjAiLz48L3JhZGlhbEdyYWRpZW50PjwvZGVmcz48L3N2Zz4=');
    background-size: 100% 50%;
    background-repeat: repeat-y;
    z-index: 0;
  }

  .reward-popup__close-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    top: -12px;
    right: 27px;
    cursor: pointer;
    z-index: 103;
    padding: 1.2em;
    margin: -1.2em;

    svg path {
      fill: var(--ui-modal-close-color);
    }
  }

  .information-popup {
    width: 405px;
    height: 251px;
    flex-shrink: 0;
    .reward-popup__close-icon {
      position: absolute;
      top: -10px;
      left: 10px;
    }
  }

  .reward-popup__title {
    font-family: Quicksand, sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: var(--ui-modal-header-text-color);
    text-align: center;
    line-height: 30px;

    strong {
      color: #ffaf22;
    }
  }

  .reward-popup__text {
    font-family: Quicksand, sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: var(--ui-modal-header-text-color);
    text-align: center;
    line-height: 22px;

    strong {
      color: #ffaf22;
    }
  }

  .reward-popup__image img {
    margin: 12px auto;
  }

  .reward-popup__center-image {
    margin: 0 auto;
  }

  .reward-popup__content {
    // deprecate
    p {
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      text-align: left;
      color: var(--ui-modal-content-text-color);
    }

    .reward-popup__text-with-icon {
      display: inline-block;
      margin-top: 0;

      span {
        margin: 0 6px;

        vertical-align: middle;

        svg path {
          fill: var(--ui-modal-content-text-color);
        }
      }
    }
  }

  .reward-popup__button {
    min-width: 120px;
    width: max-content;
    height: 26px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    padding: 4px 32px;
    border-radius: 6px;
    background-color: #ffaf22;

    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #1c292b;
    cursor: pointer;
  }
}

// Battery status change animations

.battery-status-change__container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 128px;
}

.battery-status-change__image {
  position: absolute;
  user-select: none;
}

.flicker-in {
  animation: flicker-in-1 1.6s linear both;
}

.flicker-out {
  animation: flicker-out-1 1.6s linear both;
}

.vibrate {
  animation: vibrate-1 0.3s linear infinite both;
}

.pulsate-fwd {
  animation: pulsate-fwd 0.3s ease-in-out 2 both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-12-1 11:34:9
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * @animation flicker-in-1
 * ----------------------------------------
 */
@keyframes flicker-in-1 {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  10.1% {
    opacity: 1;
  }

  10.2% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  20.1% {
    opacity: 1;
  }

  20.6% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  30.1% {
    opacity: 1;
  }

  30.5% {
    opacity: 1;
  }

  30.6% {
    opacity: 0;
  }

  45% {
    opacity: 0;
  }

  45.1% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  55% {
    opacity: 1;
  }

  55.1% {
    opacity: 0;
  }

  57% {
    opacity: 0;
  }

  57.1% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  60.1% {
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  65.1% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  75.1% {
    opacity: 0;
  }

  77% {
    opacity: 0;
  }

  77.1% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  85.1% {
    opacity: 0;
  }

  86% {
    opacity: 0;
  }

  86.1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation flicker-out-1
 * ----------------------------------------
 */
@keyframes flicker-out-1 {
  0% {
    opacity: 1;
  }

  13.9% {
    opacity: 1;
  }

  14% {
    opacity: 0;
    box-shadow: none;
  }

  14.9% {
    opacity: 0;
    box-shadow: none;
  }

  15% {
    opacity: 1;
  }

  22.9% {
    opacity: 1;
  }

  23% {
    opacity: 0;
    box-shadow: none;
  }

  24.9% {
    opacity: 0;
    box-shadow: none;
  }

  25% {
    opacity: 1;
  }

  34.9% {
    opacity: 1;
  }

  35% {
    opacity: 0;
    box-shadow: none;
  }

  39.9% {
    opacity: 0;
    box-shadow: none;
  }

  40% {
    opacity: 1;
  }

  42.9% {
    opacity: 1;
  }

  43% {
    opacity: 0;
    box-shadow: none;
  }

  44.9% {
    opacity: 0;
    box-shadow: none;
  }

  45% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  54.9% {
    opacity: 1;
  }

  55% {
    opacity: 0;
    box-shadow: none;
  }

  69.4% {
    opacity: 0;
    box-shadow: none;
  }

  69.5% {
    opacity: 1;
  }

  69.9% {
    opacity: 1;
  }

  70% {
    opacity: 0;
    box-shadow: none;
  }

  79.4% {
    opacity: 0;
    box-shadow: none;
  }

  79.9% {
    opacity: 1;
  }

  80% {
    opacity: 0;
    box-shadow: none;
  }

  89.8% {
    opacity: 0;
    box-shadow: none;
  }

  89.9% {
    opacity: 1;
    box-shadow: none;
  }

  90% {
    opacity: 0;
    box-shadow: none;
  }

  100% {
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@keyframes pulsate-fwd {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
@keyframes vibrate-1 {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translate(-2px, 2px);
  }

  40% {
    transform: translate(-2px, -2px);
  }

  60% {
    transform: translate(2px, 2px);
  }

  80% {
    transform: translate(2px, -2px);
  }

  100% {
    transform: translate(0);
  }
}

.reward-popup__information-container {
  width: 405px;
  height: 251px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 8px 32px 8px;
  z-index: 111;

  background: $darkPurple;
  background-image: radial-gradient(
      50% 50% at 50% 0%,
      rgba(252, 90, 255, 0.5) 0%,
      rgba(198, 106, 255, 0) 100%
    ),
    radial-gradient(
      50% 50% at 50% 100%,
      rgba(252, 90, 255, 0.5) 0%,
      rgba(198, 106, 255, 0) 100%
    ),
    radial-gradient(
      50% 50% at 50% 50%,
      rgba(252, 90, 255, 0.35) 0%,
      rgba(198, 106, 255, 0) 100%
    );
  background-position: top, bottom, center;
  background-repeat: no-repeat;
  background-size: 100% 50%, 100% 50%, 50% 100%;

  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.25),
    0px 2px 0px 0px #9940c3 inset;
  border-radius: 8px;
}
.reward-popup .reward-daily-goal {
  .information-popup_message-wrapper-out {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    left: 0px;
    margin-bottom: 20px;
    margin-top: -27px;
  }
}

.reward-popup__information-container-with-image {
  width: 405px;
  padding: 24px 106px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--ui-bar-background);
  min-height: 175px;

  z-index: 101;
  position: absolute;
  bottom: 42px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  justify-content: center;
  flex-direction: column;

  border-radius: 8px;
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.25),
    0px 2px 0px 0px #9940c3 inset;

  height: fit-content;
  background: #683fac;
  background-image: radial-gradient(
      50% 50% at 50% 0,
      #fc5aff80 0,
      #c66aff00 100%
    ),
    radial-gradient(50% 50% at 50% 100%, #fc5aff80 0, #c66aff00 100%),
    radial-gradient(50% 50% at 50% 50%, #fc5aff59 0, #c66aff00 100%);

  @media (max-width: 1024px) and (orientation: landscape) {
    width: 560px;
  }

  @media (orientation: portrait) {
    bottom: 220px;
  }
  &.centered {
    width: 405px;
    align-items: center;
    padding: 18px 18px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 251px;
    background: #683fac;
    background: $darkPurple;
    background-image: radial-gradient(
        50% 50% at 50% 0%,
        rgba(252, 90, 255, 0.5) 0%,
        rgba(198, 106, 255, 0) 100%
      ),
      radial-gradient(
        50% 50% at 50% 100%,
        rgba(252, 90, 255, 0.5) 0%,
        rgba(198, 106, 255, 0) 100%
      ),
      radial-gradient(
        50% 50% at 50% 50%,
        rgba(252, 90, 255, 0.35) 0%,
        rgba(198, 106, 255, 0) 100%
      );
    background-position: top, bottom, center;
    background-repeat: no-repeat;
    background-size: 100% 50%, 100% 50%, 50% 100%;

    @media (orientation: portrait) {
      bottom: unset;
    }
  }
}

@media screen and (max-width: 520px) {
  .reward-popup {
    width: 100vw;
    height: 730px;
    border-radius: 20px 20px 0 0;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
    position: fixed;

    > .reward-popup__close-icon {
      display: none;
    }

    .drawer-backdrop {
      display: none;
    }

    .drawer-box__close-button {
      top: 10px;
      display: flex;
      width: 40px;
      height: 40px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
  .reward-popup__information-container-with-image.centered {
    width: 95%;
    height: 269px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 521px) and (max-width: 744px) {
  .reward-popup .reward-popup__close-icon {
    right: 336px;
  }
}
