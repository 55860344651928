.form-popup__backdrop {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(20px);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-popup {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 32px 42px;
  border-radius: 16px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.25);
  background-color: #FFF;

  @media only screen and (max-height:600px) {
    padding: 24px 8px;
    max-width: 95vw !important;
    height: 95%;
    width: 100vw;

    .form-popup__content {
      margin: 16px 0 !important;
      flex-grow: 1;

      .terms__scroll-text-wrapper {
        height: calc(90vh - 155px) !important;
        background: #FFFFFF;
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 8px 6px 8px 16px;
        overflow: hidden;
      }
    }

  }

  @media only screen and (max-width:600px) {
    padding: 24px 8px;
    max-width: 95vw !important;
    height: 95%;
    width: 100vw;

    .form-popup__content {
      margin: 16px 0 !important;
      flex-grow: 1;

      .terms__scroll-text-wrapper {
        height: calc(90vh - 155px) !important;
        background: #FFFFFF;
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 8px 6px 8px 16px;
        overflow: hidden;
      }
    }
  }

  .form-popup__title {
    width: 100%;
    font-family: Quicksand, sans-serif;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #333;
  }

  .form-popup__subtitle {
    width: 100%;
    font-family: Quicksand, sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: #576B70;
    margin-top: 8px;
  }

  .form-popup__content {
    margin: 32px 0;
  }

  .form-popup__actions {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.form-popup--size-sm {
  max-width: 460px;

  @media only screen and (max-width:600px) {
    padding: 24px 42px !important;
    max-width: 100vw !important;
    height: auto !important;
    width: 100vw;
  }

  @media only screen and (max-width: 460px) {
    width: 100%;
    border-radius: 0;
  }
}

.form-popup--size-default,
.form-popup--size-md {
  max-width: 610px;
}

.form-popup--size-lg {
  max-width: 760px;
}
