.practice-layout {
  display: flex;
  flex-direction: column;
  // min-height: 100vh;
  // min-height: -webkit-fill-available;
  height: 100vh;
  height: 100dvh;
  width: 100%;
  // max-width: 1196px;
  margin: 0 auto;
}

.lemonade-preview.practice-layout {
  // min-height: 100vh;
  // /* mobile viewport bug fix */
  // min-height: 100vh !important;
  height: 100vh;
  height: 100dvh;
}

.lesson-page {
  // min-height: 100vh;
  // min-height: -webkit-fill-available;
  height: 100vh;
  height: 100dvh;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.lesson-view {
  // min-height: 100vh;
  // min-height: -webkit-fill-available;
  height: 100vh;
  height: 100dvh;
}

.metaberry-loading {
  height: -webkit-fill-available !important;
}

.practice-layout__body {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  box-sizing: border-box;
  // max-height: calc(100vh - 148px);
}

.practice-layout__content {
  @include custom-scrollbar;
  display: flex;
  flex-direction: column;
  width: 100%;
  // width: calc(100% - 18px);
  overflow: auto;
  box-sizing: border-box;
  // padding: 0 118px;
  transition: all ease 0.8s;
  position: relative;
  scrollbar-gutter: stable; // reserve space for scrollbar
  padding-left: 16px; // scrollbar compensation

  // height: calc(100% - 175px);
}

.exercise .exercise__content .controls-wrapper {
  transition: all ease 0.8s;
  // max-width: 964px;
  // margin-left: -16px;
  width: 996px;
  max-width: 90%;
}

@media (max-width: $breakpoint-desktop-large) {
  .practice-layout__content {
    padding: 0;
    transition: all ease 0.8s;
  }

  .exercise .exercise__content .controls-wrapper {
    padding: 0;
    // width: 90%;
    // max-width: 964px;
    width: 996px;
    max-width: 90%;
    // margin-left: -16px;
    transition: all ease 0.8s;
  }
}

// @media (max-height: $breakpoint-vertical-laptop) {
//   .practice-layout__body {
//     // max-height: calc(100vh - 100px);
//   }

//   .exercise {
//     // margin-bottom: 110px;

//     .exercise__content .controls-wrapper {
//       bottom: 0;
//     }
//   }
// }
