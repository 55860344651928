@import '../../../assets/styles/blueberry.scss';

.activity-blueberry-component {
  .dummy-activity {
    @include activity-secondary-font;
    color: map-get($activity-colors, gray-04);

    .dummy-activity__content {
      display: flex;
      // width: calc(100% - 32px);
      flex-wrap: wrap-reverse;
      justify-content: flex-end;
      align-content: flex-end;
      padding: 16px 0;
      align-self: center;
      border: 1px solid map-get($activity-colors, gray-08);
      border-radius: 8px;

      .dummy-activity__titles {
        flex-grow: 1;
        padding: 1em;

        & > * {
          margin: 0.6em 0;
        }

        ul {
          list-style-type: none;
        }
      }

      .controls {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        margin-right: 40px;
        gap: 10px;
      }

      .simple-button {
        margin: 0 10px;
        padding: 0 10px;
        font-size: 24px;
      }
    }
  }
}
