.meta-logout-modal__backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(28, 41, 43, 0.5);
  z-index: 100;
}

.meta-logout-modal {
  display: flex;
  padding: 24px 16px 25px 16px;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.24);
  position: fixed;
  z-index: 101;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 567px;
  height: auto;

  .meta-logout-modal__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .meta-logout-modal__header-icon {
    svg path {
      fill: var(--ui-modal-header-text-color);
    }
  }

  .meta-logout-modal__content {
    color: var(--Grays-gray-01, #303949);
    text-align: center;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 6px 0 24px 0;
    width: 420px;
  }

  .meta-logout-modal__actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 16px;
  }

  .meta-logout-modal__button {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    font-size: 18px;
    border-radius: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 8px 24px;
    text-align: center;
  }

  .meta-logout-modal__button--cancel {
    background: var(--Blueberry-Primary-Medium-blue, #5a34e1);
    color: var(--Grays-white, #fff);
  }

  .meta-logout-modal__button--confirm {
    border: 2px solid var(--Blueberry-Primary-Medium-blue, #5a34e1);
    color: var(--Blueberry-Primary-Medium-blue, #5a34e1);
  }

  .meta-logout-modal__button--warning {
    background: #ff6363;
    color: #ffffff;
  }

  .meta-logout-modal__dev-options {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 8px;
    padding: 16px;
    border: 1px solid #ff6363;
    margin-top: 24px;
    gap: 16px;
  }
}

@media screen and (max-width: 520px) {
  .meta-logout-modal {
    display: flex;
    width: 345px;
    padding: 24px 16px 25px 16px;
    flex-direction: column;
    align-items: center;
    gap: 18px;

    .meta-logout-modal__content {
      font-size: 18px;
      line-height: 26px;
      width: 326px;
    }

    .meta-logout-modal__button {
      font-size: 16px;
      display: flex;
      padding: 12px 32px;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
}
