@import '../../../../../assets/styles/common';

.unit-info {
  display: flex;
  align-items: center;
  gap: 20px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  color: $white-1;
  font-size: 20px;
  margin-bottom: 25px;

  .unit-info_index {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    background: $violet-light-1;
    text-align: center;
  }
}
