@use 'sass:color';

.blockage-phones-page {
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url(../../assets/img/title_bg.png);
  background-position: center;
  background-size: cover;

  .blockage-phones-page-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 32px 42px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.25);
    background-color: rgba(255, 255, 255, 0.8);
    overflow: auto;

    .blockage-phones-page-layout__container {
      width: 100%;
      color: $gray-1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
    }
  }

  .blockage-phones-page__title {
    font-family: $primary-font, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #1c292b;
  }

  .blockage-phones-page__image {
    margin: 40px 0;

    img {
      width: 100%;
      height: auto;
      max-width: 286px;
      margin: 0 auto;
    }
  }

  .blockage-phones-page__text {
    font-family: $secondary-font, sans-serif;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 40px;

  }

  .blockage-phones-page__no-mail {
    color: var(--primary-dark, #384346);
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .blockage-phones-page__resend {
    color: #6363fc;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    margin-left: 4px;
    cursor: pointer;
  }

  .blockage-phones-page__counter {
    color: #ACACF6;
    font-family: Poppins, sans-serif;
    font-variant-numeric: tabular-nums;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .blockage-phones-page__counter-monospace {
    font-family: monospace;
    font-variant-numeric: tabular-nums;
    font-size: 15px;
  }

  .blockage-phones-page__logout {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #6363fc;
    cursor: pointer;
    margin-top: 32px;
  }

  .blockage-phones-page__toast {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #00E19A;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    opacity: 0;
    transform: translateY(-40px);
    transition: all 200ms;

    z-index: 1;

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }

    .blockage-phones-page__toast-message {
      display: flex;
      justify-content: center;
      align-items: center;
    }


    .blockage-phones-page__toast-text {
      font-family: Poppins, sans-serif;
      line-height: normal;
      color: #ffffff;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      margin-left: 12px;
    }
  }
}
