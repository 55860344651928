.map-loading_page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.map-loading_background {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(./assets/bg_without_logo.svg);

  @keyframes loadingPulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.05;
    }
    100% {
      opacity: 1;
    }
  }
}
.map-loading_container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.map-loading_header {
  flex: 1;
}
.map-loading_logo {
  flex: 1;
  margin-top: 32px;
}
.map-loading_footer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.map-loading_avatars {
  position: relative;
  height: 100vh;
  width: 100vw;
}

.map-loading_footer-progress-bar_background {
  border-radius: 28px;
  background: var(--Grays-white, #fff);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25),
    0px 0px 14px 0px rgba(0, 0, 0, 0.25) inset;
  width: 350px;
  height: 35px;
  flex-shrink: 0;
  padding: 3px 3px 3px 3px;
  z-index: 2;
}
.map-loading_footer-fill-progress-bar {
  border-radius: 23px;
  background: var(
    --Linear-progress,
    linear-gradient(180deg, #7c3de5 22.56%, #3c3cbf 100%)
  );
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25) inset;
  height: 100%;
  flex-shrink: 0;
  width: 240px;
  max-width: 343px;
}
.map-loading_footer-text {
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  padding-bottom: 16px;
  padding-top: 35px;
}

@media screen and (max-width: 520px) {
  .map-loading_container {
    margin: 0 auto;    
  }
  .map-loading_logo {
    margin-bottom: 36px;
    flex: 0;
    margin-top: 0;
    img {
      max-width: 230px;
    }
  }
  .map-loading_footer, .map-loading_header {
    flex: 0;
  }
     
  .map-loading_footer-progress-bar_background {
    width: 230px;
    height: 28px;
  }
  .map-loading_footer-fill-progress-bar {
    width: 180px;
  }
  .map-loading_footer-text {
    padding-top: 16px;
  }

  .map-loading_background {        
    background-position: left center;
    background-image: url(./assets/bg-mobile.svg);
  }
}
