@import '../../../assets/styles/common';
.drawer-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(28, 41, 43, 0.5);
  z-index: 899;
  display: block;

  @media (max-width: 520px) {
    display: block;
  }
}

.drawer-box {
  position: fixed;
  transition: all 0.5s ease-in-out;
  width: fit-content;
  height: fit-content;
  max-width: 100%;
  max-height: 100%;
  z-index: 900;

  // Variantes según dirección
  &--right {
    top: 0;
    right: 0;
    animation: drawer-slideInRight 0.5s ease-in-out;
    &.closing {
      animation: drawer-slideOutRight 0.5s ease-in-out forwards;
    }
  }

  &--left {
    top: 0;
    left: 0;
    animation: drawer-slideInLeft 0.5s ease-in-out;
    &.closing {
      animation: drawer-slideOutLeft 0.5s ease-in-out forwards;
    }
  }

  &--top {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    animation: drawer-slideInTop 0.5s ease-in-out;
    &.closing {
      animation: drawer-slideOutTop 0.5s ease-in-out forwards;
    }
  }

  &--bottom {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    animation: drawer-slideInBottom 0.5s ease-in-out;
    &.closing {
      animation: drawer-slideOutBottom 0.5s ease-in-out forwards;
    }
  }

  &__close-button {
    display: none;
    border-radius: 60px;
    background: $white-1;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    top: 14px;
    left: 50%;
    cursor: pointer;
    width: 32px;
    height: 32px;
    transform: translateX(-50%);
    z-index: 150;

    svg {
      width: 50%;
      height: 50%;
      fill: rgba(137, 29, 29, 0);
    }

    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@keyframes drawer-slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes drawer-slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes drawer-slideInTop {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes drawer-slideInBottom {
  from {
    transform: translateX(-50%) translateY(100vh);
  }
  to {
    transform: translateX(-50%) translateY(0);
  }
}

@keyframes drawer-slideOutRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes drawer-slideOutLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes drawer-slideOutTop {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes drawer-slideOutBottom {
  from {
    transform: translateX(-50%) translateY(0);
  }
  to {
    transform: translateX(-50%) translateY(100vh);
  }
}
