.accordion-menu {
  width: 100%;

  &.open {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.31);
    display: flex;
    width: 261px;
    padding: 14px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .accordion-header {
      color: #fff;
      text-align: center;
      font-family: Poppins, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .accordion-list {
      width: 100%;
    }

    .accordion-item {
      width: 100%;
      padding: 8px 12px;

      .map-user-menu-modal__action-dropdown__option {
        display: flex;
        align-items: center;
        width: 100%;

        .menu-drawer__options-text {
          position: static;
          width: auto;
          left: auto;
        }

        svg {
          position: static;
          margin-left: 8px;
        }
      }
    }

    svg {
      path {
        fill: #fff;
      }
    }
  }

  .accordion-header {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;
    color: #fff;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  .accordion-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
