@media screen and (max-width: 520px) {
  .notification-drawer {
    .drawer-box {
      display: flex;
      width: 100%;
      height: 239px;
      padding: 24px 24px 48px 24px;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      flex-shrink: 0;

      border-radius: 20px 20px 0px 0px;
      background: #fff;
      box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.6);
    }

    .drawer-box__close-button {
      display: none;
    }
  }

  .toast-blueberry_title {
    color: var(--Grays-gray-02, #4d586c);
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .toast-blueberry_message {
    color: var(--Grays-gray-04, #7a8598);
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    width: 323px;
    height: 65px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .toast-blueberry_icon {
    width: 50px;
    height: 50px;
    margin-bottom: 12px;
  }
}
