.meta-exit-panel {
  width: auto;
  height: 70px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  z-index: 1;
  padding: 16px 25px;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background: var(--ui-bar-background);

  .meta-exit-panel__button {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #1C292B;

    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;

    min-width: 87px;
    min-height: 39px;
    width: fit-content;
    margin: 0 auto;

    background: #FFAF22;
    border-radius: 18px;

    cursor: pointer;
  }
}
