@import '../../../assets/styles/common';
.map-user-menu-modal__backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(28, 41, 43, 0.5);
  z-index: 100;
}

.map-user-menu-modal {
  z-index: 101;
  position: absolute;
  min-width: 245px;
  top: 66px;
  right: 54px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 14px;
  border-radius: 12px;
  background: #5a34e1;
  box-shadow: -8px -5px 12px 0px rgba(0, 0, 0, 0.17) inset;

  &:before {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 3px;
    background: #5a34e1;
    transform: rotate(45deg);
    position: absolute;
    top: -7px;
    right: 40px;
  }

  .map-user-menu-modal__wrapper {
    border-radius: 8px;
    background: #3703a4;
    padding: 14px 12px;
    width: 100%;
  }

  .map-user-menu-modal__trial-container {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 12px 12px;
    border-radius: 12px;
    background-color: rgba(90, 52, 225, 0.75);
    margin-bottom: 16px;

    .map-user-menu-modal__trial-title {
      font-family: Poppins, sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
    }

    .map-user-menu-modal__trial-days-left {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      line-height: 1.43;
      text-align: center;
      color: #ffffff;
      margin-bottom: 12px;
      margin-top: 2px;
    }

    .map-user-menu-modal__trial-button {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 8px 24px;
      border-radius: 6px;
      background-color: #6363fc;

      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
    }
  }

  .map-user-menu-modal__action {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    padding: 0;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      width: 19px;
      height: auto;
    }

    svg path {
      fill: var(--ui-primary-color);
    }

    &.map-user-menu-modal__action-subscription {
      cursor: pointer;

      svg path {
        fill: var(--ui-modal-title-color);
      }

      div {
        color: var(--ui-modal-title-color);
      }
    }

    &.map-user-menu-modal__action-language {
      cursor: auto;

      .map-user-menu-modal__action-dropdown {
        position: relative;

        .map-user-menu-modal__action-dropdown__selected-option {
          cursor: pointer;
          display: flex;
          gap: 8px;

          svg {
            height: 14px;
            width: auto;
          }

          .map-user-menu-modal__action-dropdown__chevron_inverse {
            transform: scale(1, -1);
          }
        }

        .map-user-menu-modal__action-dropdown__options {
          position: absolute;
          width: 100%;
          background-color: rgba(55, 3, 164, 0.95);
          border-radius: 8px;
          margin: 16px 0 0 -22px;
          width: calc(100% + 24px);
          padding: 8px 0;
          border: 2px solid rgba(255, 255, 255, 0.65);
          .map-user-menu-modal__action-dropdown__option {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            padding: 12px 14px 12px 14px;
            transition: background-color 0.3s ease-in-out;

            svg {
              height: 12px;
            }

            &:hover {
              background-color: rgba(0, 0, 0, 0.5);
            }
          }
        }
      }
    }

    .map-user-menu-modal__action-text {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      color: var(--ui-primary-color);
      padding-left: 12px;
      line-height: 18px;

      a {
        text-decoration: none;
        color: inherit;
      }
    }

    .map-user-menu-modal__action-contact {
      user-select: text !important;
    }
  }

  .map-user-menu-modal__separator {
    height: 0.5px;
    align-self: stretch;
    opacity: 0.25;
    background: #ffffff;
    margin-bottom: 12px;
  }
}

.custom-language-confirm-content {
  width: 100%;
  font-family: Poppins, sans-serif;
  line-height: 24px;
  font-weight: 700;
  text-align: left;

  .custom-language-confirm-title {
    color: $grey-01;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 18px;
  }

  .custom-language-confirm-message {
    color: $gray-04;
    text-align: center;

    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}

@media only screen and (max-width: 520px) {
  .custom-language-confirm-title {
    color: $grey-01;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }

  .custom-language-confirm-message {
    width: 300px;
  }
}
