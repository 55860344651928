@use 'sass:color';

.pricing-layout {
  height: 100%;
  height: -webkit-fill-available;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-image: url(../../../../assets/img/title_bg.png);
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;

  .pricing-layout__content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    // Below ipad mini and landscape mode
    @media only screen and (max-width: 1000px) and (orientation: landscape) {
      overflow: auto;
      align-items: flex-start;
      padding: 8px;
    }

    .pricing-panel__form {
      width: 100%;
      color: $gray-1;

      .pricing-panel__title {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
      }

      .pricing-panel__text {
        font-family: Montserrat;
        font-size: 16px;
        text-align: center;

        // Below ipad mini and landscape mode
        @media only screen and (max-height: 732px) {
          display: none;
        }
      }

      .pricing-panel__pricing-table-container {
        background-color: $white;        
        border-radius: 16px;
        padding: 16px 0 16px 0;
      }

      .pricing-panel_cancel_account-container {
        padding-top: 28px;
        font-family: Montserrat;
        font-size: 14px;
        text-align: center;

        .pricing-panel_cancel_account-text {
          color: #1c292b;
        }

        .pricing-panel_cancel_account-mail {
          cursor: pointer;
          display: block;
          padding-top: 7px;
          user-select: text !important;
          color: $primary-blueberry;
          text-decoration: none;
        }
      }
    }
  }

  .pricing-layout__footer {
    width: 100%;
    height: 26px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.8);

    font-family: Poppins;
    font-size: 12px;
    color: #576b70;
  }
}
