.avatar_slider__container {
  width: 90%;
  padding: 20px 0;
}
.swiper {
  width: 100%;
}

.swiper-wrapper {
  align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: #ffffff;
  width: 35px;
  height: 35px;
  border-radius: 31px;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  background: #ffffff1a;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: #ffffff3a;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 15px; /* Change the size of the arrows */
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 0;
}

.avatar_slider__button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 42px;

  .avatar_slider__button--available {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #5a34e1;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;

    min-width: 164px;
    min-height: 39px;
    width: fit-content;
    margin: 0 auto;

    background: #ffffff;
    border-radius: 18px;

    cursor: pointer;
  }

  .avatar_slider__button--default {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #afb7c6;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;

    min-width: 164px;
    min-height: 39px;
    width: fit-content;
    margin: 0 auto;

    background: #f0f4f8;
    border-radius: 18px;
    box-shadow: none;
    cursor: pointer;
  }
}

@media screen and (max-width: 520px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
  .avatar_slider__container {
    width: 100%;
  }
}
