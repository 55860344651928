.meta-tab-pets {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 27px 15px 15px 15px;
  position: relative;
  width: 100%;

  .meta-tab-pets__header {
    margin-left: 9px;
  }

  .meta-tab-pets__header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .meta-tab-pets__header-left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-grow: 1;
    }

    .meta-tab-pets__header-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      .shop-level {
        margin-right: 9px;
      }

      .meta-tab-pets__header-tooltip {
        position: absolute;
        top: 44px;
        left: -40px;
        width: 250px;
        padding: 8px 12px;
        background: #232f40;
        border-radius: 4px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Montserrat, sans-serif;
        font-weight: 600;
        font-size: 12px;
        color: #fff;
        line-height: 18px;
        z-index: 99;

        &.fade-in {
          animation: fadeInAnimation ease 0.6s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }

        &.fade-out {
          animation: fadeOutAnimation ease 0.6s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }
      }

      @keyframes fadeInAnimation {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }

      @keyframes fadeOutAnimation {
        0% {
          opacity: 1;
        }

        100% {
          opacity: 0;
        }
      }
    }
  }

  .meta-tab-pets__header-bottom {
    margin-bottom: 20px;
  }

  .meta-tab-pets__title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: normal;
    color: #1c292b;
  }

  .meta-tab-pets__subtitle {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.75);
  }

  .meta-tab-pets__content {
    @include custom-game-tabs-scrollbar;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding: 0 8px 16px 8px;
  }

  .meta-tab-pets__grid_old {
    @include custom-game-tabs-scrollbar;

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-row-gap: 24px;
    width: 100%;
    align-content: start;
    margin-top: 18px;

    @media screen and (max-width: 1100px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: 950px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  .meta-tab-pets__grid-item {
    display: flex;
    justify-content: center;
  }

  .meta-tab-pets__empty {
    font-family: Quicksand, sans-serif;
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
    padding: 32px 16px 16px 16px;
    text-align: center;
    color: #ffffff;
    margin-top: 20%;

    @media screen and (max-width: 520px) {
      font-size: 18px;
    }
  }
}

.confirm-popup__right {
  .meta-tab-pets-error-popup-title {
    font-family: Quicksand, sans-serif;
    font-size: 24px;
    font-weight: 800;
    padding: 16px;
    text-align: center;
  }

  .meta-tab-pets-error-popup-message {
    font-family: Quicksand, sans-serif;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    padding: 32px 16px 16px 16px;
    text-align: center;   
  }
}

@media (max-height: 750px) {
  .meta-options-menu-drawer__tab-content {
    height: calc(100% - 134px);
  }
}

.meta-tab-pets__grid {
  @include custom-game-tabs-scrollbar;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 62px;
  width: 100%;
  align-content: start;
  margin-top: 12px;
  grid-column-gap: 18px;

  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 950px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 636px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 520px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 420px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 419px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .meta-tab-pets__grid-total__item {
    border-radius: 5px;
    padding-bottom: 20px;

    .map-tabs-tooltip__tooltip {
      left: -4px;
      bottom: -34px;

      @media ((min-width: 950px) and (max-width: 1100px)) {
        left: 16px;
      }

      @media (max-width: 949px) {
        left: 30px;
      }

      @media (max-width: 850px) {
        left: 12px;
      }

      @media (max-width: 800px) {
        left: 58px;
      }

      @media (max-width: 680px) {
        left: 27px;
      }
      @media (max-width: 600px) {
        left: 28%;
      }
    }
  }
}
