// Resets
@import './reset';
@import './reset-form';

// Fonts
@import './fonts';

// Colors
@import './colors';

// Media queries
@import './media-queries';

// z index
@import './depths';

// spaces
@import './spaces';

// Mixins
@import './mixins';

// Components
@import '../modules/components/components';

// Modals
@import '../modules/modals/modals';

// Layouts
@import '../modules/layout/layout';

// Pages
@import '../pages/pages';

body.uppercase-required,
body.uppercase-required * {
  text-transform: uppercase !important;
}