.activity-blueberry-component {
  .speak-button {
    cursor: pointer;
    position: relative;
    z-index: 5;
    background: none;
    border: none;
    padding: 0;
    transition: all 0.2s ease;
    display: inline-flex;
    transform-origin: center;

    --audio-primary: #5A34E1;
    --audio-bg: #EFF1FA;

    &:hover {
      opacity: 0.8;
    }

    &.playing {
      animation: audio-button-pulse 1.5s ease-in-out infinite;

      &.speak-button svg {
        rect {
          fill: #5A34E1;
        }
        path {
          fill: #EFF1FA;
        }
      }
    }

    &.loading {
      animation: audio-button-loading 2s ease-in-out infinite;
    }
  }
}

@keyframes audio-button-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes audio-button-loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
