.profiles-form {
  display: flex;
  justify-content: center;
  align-items: center;

  .blueberry-family-panel__title {
    margin-bottom: 8px;
  }

  .blueberry-family-panel__subtitle {
    margin-bottom: 24px;
  }

  .profiles-form__single-profile {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;

    .form-input {
      width: 100%;
      flex-grow: 1;
      margin-right: 9px;
    }

    .form-select {
      width: 100%;
      flex-grow: 1;
      margin-left: 9px;
    }

    .form-button {
      width: auto;
      flex-grow: 0;
      padding: 0;
      height: 46px;
      margin: 8px auto;

      .form-button__icon {
        margin-right: 0;
        margin-left: 9px;
      }
    }
  }

  .profiles-form__actions {
    .form-button {
      display: inline-flex;
      width: auto;
      padding: 0;
    }
  }

  .profiles-form__error {
    margin-top: 6px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #FF3D30;
  }

  .blueberry-family-panel__actions {
    margin-top: 32px;
  }
}


.profiles-form__delete-popup {

  .form-popup__actions {
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .form-button:last-child {
      margin-top: 8px
    }
  }
}
