@import '../../../../../assets/styles/common';

.lessons-list {
  height: auto;
  margin-top: 10px;

  @media (max-width: 870px) {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @include custom-scrollbar;
  }
}
