.practice-complete-view {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #5a34e1, #c5bde0, #5a34e1);

  &.practice-complete-view_iPhone {
    padding-bottom: 30px;
  }

  // Elementos de fondo
  .practice-complete-view__background {
    position: absolute;
    width: 100%;
    height: 100%;

    .practice-complete-view__ellipse-top {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 17%;
      // background: url('../../../assets/practice-complete/ellipse-top.svg#svgView(preserveAspectRatio(none))')
      //   center center no-repeat;
      background-size: 100% 100%;
      z-index: 0;
    }

    .practice-complete-view__triple-x-top {
      position: absolute;
      top: 17%;
      right: -15px;
      width: 146px;
      height: 32px;
      // background: url('../../../assets/practice-complete/triple-x.svg')
      //   no-repeat;
      background-size: contain;
      z-index: 2;
    }

    .practice-complete-view__vectors {
      position: absolute;
      top: 22%;
      left: -25px;
      width: 292px;
      height: 180px;
      // background: url('../../../assets/practice-complete/vectors.svg') center
      //   center no-repeat;
      background-size: contain;
      z-index: 2;
    }

    .practice-complete-view__triangle {
      position: absolute;
      top: 50%;
      right: -10px;
      transform: translateY(-50%);
      width: 235px;
      height: 133px;
      // background: url('../../../assets/practice-complete/math-triangle.svg')
      //   center center no-repeat;
      background-size: contain;
      z-index: 2;
    }

    .practice-complete-view__function {
      position: absolute;
      bottom: 27%;
      left: -22px;
      width: 208px;
      height: 209px;
      // background: url('../../../assets/practice-complete/graph-function.svg')
      //   center center no-repeat;
      background-size: contain;
      z-index: 2;
    }

    .practice-complete-view__cradle {
      position: absolute;
      bottom: 16%;
      right: 0;
      width: 178px;
      height: 142px;
      // background: url('../../../assets/practice-complete/newton-cradle.svg')
      //   center center no-repeat;
      background-size: contain;
      z-index: 2;
    }

    .practice-complete-view__triple-x-bottom {
      position: absolute;
      bottom: 7%;
      left: -15px;
      width: 146px;
      height: 32px;
      // background: url('../../../assets/practice-complete/triple-x.svg')
      //   no-repeat;
      background-size: contain;
      z-index: 2;
    }

    .practice-complete-view__ellipses-bottom {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 230px;
      height: 212px;
      background-size: 100% 100%;
      z-index: 0;
    }
  }

  // Elementos de contenido
  .practice-complete-view__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    z-index: 3;
    width: 100%;
    height: 100%;

    .practice-complete-view__message {
      flex: none;
      color: #ffffff;
      text-align: center;
      font-family: Poppins, sans-serif;
      font-weight: 600;
      line-height: normal;
      padding: 0 24px;
      z-index: 3;

      .practice-complete-view__message-title {
        font-size: 37px;
        padding-top: 16px;
      }

      .practice-complete-view__message-experience {
        font-size: 30px;
        margin-top: 24px;

        span {
          vertical-align: middle;
        }

        .practice-complete-view__message-ray {
          display: inline-flex;
          padding-left: 12px;
          height: 41px;
          vertical-align: middle;

          svg {
            height: 100%;
            width: auto;
          }
        }
      }
    }

    .practice-complete-view__battery {
      flex: 1;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      width: 200px;
      transform: rotate(-40deg);
      gap: 31px;

      .practice-complete-view__battery-highlight {
        max-height: 100%;
        max-width: 100%;
      }
    }

    .practice-complete-view__information {
      flex: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .practice-complete-view__information_mastered-message {
        flex: none;
        color: #ffffff;
        text-align: center;
        font-family: Poppins, sans-serif;
        font-weight: 600;
        line-height: normal;
        padding: 0 24px;
        z-index: 3;
        font-size: 24px;
      }

      .practice-complete-view__experience-extra {
        color: #fff;
        text-align: center;
        font-family: Poppins, sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: normal;
        background: #3703a4;
        border-radius: 60px;
        padding: 8px 24px;
        width: fit-content;

        span {
          vertical-align: middle;
        }

        svg {
          display: inline-flex;
          margin: 0 7px;
          height: 27px;
          vertical-align: middle;
        }
      }

      .practice-complete-view__earned-piece {
        position: relative;
        color: #fff;
        text-align: center;
        font-family: Poppins, sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: normal;
        background: #3703a4;
        border-radius: 60px;
        padding: 8px 24px 8px 70px;
        margin-top: 24px;
        width: fit-content;

        span {
          vertical-align: middle;
        }

        svg {
          position: absolute;
          top: 50%;
          left: -10px;
          transform: translateY(-50%);
          height: 65px;
          width: fit-content;
        }
      }

      /* Sólo aparece si la lección está dominada */
      .practice-complete-view__information_mastered-message {
        font-family: Poppins, sans-serif;
        font-size: 20px;
        font-weight: 700;
        padding-bottom: 16px;
      }
    }

    .practice-complete-view__action-buttons {
      display: flex;
      flex: none;
      flex-wrap: wrap;
      gap: 24px;
      width: 100%;
      padding: 24px 16px 24px 16px;
      z-index: 3;
      justify-content: center;

      .practice-button {
        min-width: fit-content;
        width: 302px;
      }
    }
  }

  // Para terminales con poca resolución, sino no caben todos los elementos
  @media screen and (max-width: 370px), screen and (max-height: 700px) {
    .practice-complete-view__content {
      .practice-complete-view__message {
        padding: 0 20px;

        .practice-complete-view__message-title {
          font-size: 33px;
        }

        .practice-complete-view__message-experience {
          font-size: 26px;
          margin-top: 20px;

          .practice-complete-view__message-ray {
            padding-left: 8px;
            height: 34px;
            display: inline-block;

            svg {
              height: 100%;
              width: auto;
            }
          }
        }
      }

      .practice-complete-view__information {
        .practice-complete-view__information_mastered-message {
          font-size: 20px;
        }

        .practice-complete-view__experience-extra {
          font-size: 18px;
          padding: 8px 20px;

          svg {
            margin: 0 7px;
            height: 24px;
          }
        }

        .practice-complete-view__earned-piece {
          font-size: 18px;
          padding: 8px 20px 8px 70px;
          margin-top: 20px;

          svg {
            height: 60px;
          }
        }

        .practice-complete-view__information_mastered-message {
          font-size: 18px;
        }
      }
    }
  }

  @media screen and (max-width: 310px) {
    .practice-complete-view__background {
      .practice-complete-view__ellipses-bottom {
        width: 130px;
        height: 112px;
      }
    }

    .practice-complete-view__content {
      .practice-complete-view__information {
        padding: 0 8px;
        .practice-complete-view__earned-piece {
          display: flex;
          flex-direction: row-reverse;
          font-size: 18px;
          padding: 8px 8px 8px 30px;
          gap: 8px;
          margin-top: 20px;
          align-items: center;

          svg {
            position: absolute;
            display: inline-block;
            height: 40px;
            top: auto;
            left: -12px;
            transform: none;
          }
        }

        .practice-complete-view__information_mastered-message {
          font-size: 18px;
        }
      }
    }
  }

  @media screen and (max-width: 231px),
    screen and (max-height: 600px),
    screen and (max-width: 281px) and (max-height: 631px) {
    .practice-complete-view__content {
      .practice-complete-view__battery {
        display: none;
      }
    }
  }
}

.practice-complete-view {
  &.web-version {
    // Elementos de fondo
    .practice-complete-view__background {
      .practice-complete-view__ellipse-top {
        display: none;
      }

      .practice-complete-view__triple-x-top {
        top: 17%;
        right: -15px;
        width: 302px;
        height: 66px;
        background-size: contain;
      }

      .practice-complete-view__vectors {
        top: 16%;
        left: 10%;
        width: 292px;
        height: 154px;
        background-size: contain;
      }

      .practice-complete-view__triangle {
        top: 50%;
        right: 10%;
        transform: translateY(-50%);
        width: 296px;
        height: 168px;
        background-size: contain;
      }

      .practice-complete-view__function {
        bottom: 27%;
        left: 14%;
        width: 208px;
        height: 209px;
        background-size: contain;
      }

      .practice-complete-view__cradle {
        bottom: 16%;
        right: 0;
        width: 178px;
        height: 142px;
        background-size: contain;
      }

      .practice-complete-view__triple-x-bottom {
        bottom: 7%;
        left: -134px;
        width: 302px;
        height: 66px;
        transform: scaleX(-1);
        background-size: contain;
      }

      .practice-complete-view__ellipses-bottom {
        display: none;
      }
    }

    // Elementos de contenido
    .practice-complete-view__content {
      .practice-complete-view__message {
        .practice-complete-view__message-title {
          font-size: 48px;
          padding-top: 94px;
          color: #303949;
        }
      }

      .practice-complete-view__message-experience {
        font-size: 32px;
        margin-top: 24px;
        color: #4d586c;

        .practice-complete-view__message-ray {
          height: 38px;
          display: inline-block;

          svg {
            height: 100%;
            width: auto;
          }
        }
      }

      .practice-complete-view__battery {
        max-height: 288px;
      }

      .practice-complete-view__information_mastered-message {
        font-size: 26px;
        color: #4d586c;
      }

      .practice-complete-view__action-buttons {
        justify-content: center;
        gap: 32px;
        padding-bottom: 48px;

        .practice-button {
          min-width: fit-content;
          width: 300px;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      .practice-complete-view__background {
        .practice-complete-view__triple-x-top {
          right: -56px;
          transform: scale(0.8);
        }

        .practice-complete-view__vectors {
          left: 10%;
          transform: scale(0.8);
        }

        .practice-complete-view__triangle {
          transform: translateY(-50%) scale(0.8);
        }

        .practice-complete-view__function {
          transform: scale(0.8);
        }

        .practice-complete-view__cradle {
          transform: scale(0.8);
        }

        .practice-complete-view__triple-x-bottom {
          left: -56px;
          transform: scale(-0.8);
        }
      }

      .practice-complete-view__content {
        .practice-complete-view__message {
          .practice-complete-view__message-title {
            font-size: 38px;
            padding-top: 72px !important;
          }
        }

        .practice-complete-view__message-experience {
          font-size: 28px;

          .practice-complete-view__message-ray {
            height: 32px;
            display: inline-block;

            svg {
              height: 100%;
              width: auto;
            }
          }
        }

        .practice-complete-view__information_mastered-message {
          font-size: 22px;
        }
      }
    }

    @media screen and (max-width: 400px) {
      .practice-complete-view__content {
        .practice-complete-view__message {
          .practice-complete-view__message-title {
            font-size: 36px;
            padding-top: 48px !important;
          }
        }

        .practice-complete-view__message-experience {
          font-size: 26px;

          .practice-complete-view__message-ray {
            height: 28px;
            display: inline-block;

            svg {
              height: 100%;
              width: auto;
            }
          }
        }

        .practice-complete-view__information_mastered-message {
          font-size: 20px;
        }
      }
    }
  }
}

// #########################
.practice-complete-view {
  overflow-y: auto;
  overflow-x: hidden;
  background-size: cover;
  background-repeat: repeat-y;
  background-position: center;
  background-image: url(../../../../../assets/img/bg_new.png);
}

.contenedor-general {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.practice-complete__principal-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.practice-complete-view__information-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 370px;
  height: 369px;
  margin-top: 180px;
}

.practice-complete-view_message {
  color: var(--Gray-scale-white, #fff);
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  font-family: Poppins, sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 29px;
  padding: 20px;
}

.practice-complete-view_rewards-container {
  width: 313px;
  height: 84px;
  padding: 15px 32px;
  gap: 24px;
  border-radius: 14px;
  background: rgba(0, 0, 0, 0.3);
  max-width: 95%;
}

.practice-complete-view_text {
  color: #fff;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 900;
  line-height: 25px;
  margin-top: 0;
  margin-bottom: 0;
}

.practice-complete-view__pieces {
  img {
    width: 46px;
    height: 46px;
  }
}

.practice-complete-view__rewards-content {
  display: flex;
  justify-content: space-around;
}

.practice-complete-view__reward-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reward-icon {
  width: 40px;
  height: 40px;
}

.practice-complete-view__rewards-title {
  color: #fff;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.practice-complete-view__action-buttons {
  display: flex;
  flex: none;
  flex-wrap: wrap;
  gap: 24px;
  padding: 24px 16px 24px 16px;
  z-index: 3;
  justify-content: center;
  margin-bottom: -600px;

  .practice-button {
    min-width: fit-content;
    width: 302px;
  }
}
.practice-complete_reward {
  margin-top: 20px;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-height: 600px) {
  .practice-complete-view__information-container {
    margin-top: 117px;
  }
  .practice-complete-view__action-buttons {
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) and (max-height: 1024px) {
  .practice-complete-view__information-container {
    margin-top: 192px;
  }
  .practice-complete-view__action-buttons {
    margin-top: 68px;
  }
}

@media screen and (min-width: 350px) {
  .practice-complete_reward {
    margin-top: 0;
    margin-bottom: 40px;
  }
  .practice-complete-view {
    .information-popup {
      .information-popup__end-of-challenge {
        height: 341px;
      }
    }
  }
  .practice-complete-view__rewards-title {
    color: #fff;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
  .practice-complete-view_rewards-container {
    margin-top: 10px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1920px) and (min-width: 768px) {
  .practice-complete-view__action-buttons {
    margin-top: -66px;
  }
}

@media screen and (max-height: 1024px) {
  .practice-complete-view__action-buttons {
    margin-top: 50px;
  }
}

@media screen and (max-height: 768px) {
  .practice-complete-view__action-buttons {
    margin-top: -46px;
  }
}
