.map-items-counter {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-left: 16px;

  .map-items-counter__background {
    border-radius: 40px;
    background: var(--Blueberry-Primary-Dark-blue, #3703a4);

    height: 25px;
    min-width: 52px;
    padding: 4px 10px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    &.map-items-counter__background-light {
      background: #5a34e1;
    }

    .map-items-counter__icon,
    .map-items-counter__icon-animated {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      position: absolute;
      left: -12px;
      width: 34px;
      height: 34px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    @keyframes map-items-counter-amimation {
      0% {
        transform: translateY(-150px) scale(2);
        animation-timing-function: cubic-bezier(0.8, 0, 0.2, 1);
        opacity: 1;
      }
      40% {
        transform: translateY(60px) scale(2);
        animation-timing-function: linear;
        opacity: 1;
      }
      60% {
        transform: translateY(60px) scale(2);
        animation-timing-function: cubic-bezier(0.8, 0, 0.2, 1);
        opacity: 1;
      }
      98% {
        transform: translateY(0) scale(1);
        opacity: 1;
      }
      100% {
        transform: translateY(0) scale(1);
        opacity: 0;
      }
    }

    .map-items-counter__icon-animated {
      svg {
        animation: map-items-counter-amimation 1600ms linear;
      }
    }

    .map-items-counter__count {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
      margin-left: 12px;
      color: #ffffff;
      text-align: right;

      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-weight: 700;

      &.map-items-counter__count-min-width-1 {
        min-width: 25px;
      }
      &.map-items-counter__count-min-width-2 {
        min-width: 39px;
      }
      &.map-items-counter__count-min-width-3 {
        min-width: 52px;
      }

      .map-items-counter__count_current {
        padding-left: 4px;
        font-size: 12px;
      }
      .map-items-counter__count_total {
        font-size: 10px;
        place-self: end;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .map-items-counter {
    .map-items-counter__background {
      min-width: 35px;
      display: flex;
      height: 17px;
      padding: 11px 9px 11px 8px;
      justify-content: flex-end;
      align-items: center;
      gap: 3px;
      .map-items-counter__icon,
      .map-items-counter__icon-animated {
        width: 26px;
        height: 26px;
        left: -8px;
      }
      .map-items-counter__count {
        font-size: 11px;
        min-width: 16px;
      }
    }
  }
}
