@mixin activity-primary-font {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

@mixin activity-secondary-font {
  font-family: Quicksand, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

$lemonade-secondary-font: 'Poppins';

$activity-colors-base: (
  corporative: #3c3cbf,
  primary-dark: #3703a4,
  primary-medium: #5a34e1,
  primary-light-1: #acacf7,
  primary-light-2: #eff1fa,
  alpha: rgba(172, 172, 247, 0.149),
  green-bb: #00e19a,
  green-light: #d3ffef,
  green-dark: #2ba05c,
  lemonade: #28c59d,
  red-bb: #ff3d30,
  red-light-01: #ff9790,
  red-light-02: #ffccc9,
  red-dark: #ca1a0e,
  black-bb: #000000,
  gray-01: #303949,
  gray-02: #4d586c,
  gray-03: #647082,
  gray-04: #7a8598,
  gray-05: #939db0,
  gray-06: #afb7c6,
  gray-07: #c8cfda,
  gray-08: #dfe5ec,
  gray-09: #f0f4f8,
  gray-10: #fbfcfd,
  white-bb: #ffffff,
  shadow-01: rgba(0, 0, 0, 0.075),
  shadow-02: rgba(0, 0, 0, 0.3)
);

$activity-colors: map-merge(
  $activity-colors-base,
  (
    disabled-back: map-get($activity-colors-base, gray-09),
    disabled-front: map-get($activity-colors-base, gray-06)
  )
);
