$font-path: '../fonts';

$lemonade-primary-font: 'Poppins';
$lemonade-secondary-font: 'Poppins';
$lemonade-clock-digital-font: 'DS-Digital';

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&display=swap');

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-path}/Montserrat/Montserrat-BoldItalic.woff2')
      format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-BoldItalic.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-path}/Montserrat/Montserrat-Bold.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-Bold.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-path}/Montserrat/Montserrat-Italic.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-Italic.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-path}/Montserrat/Montserrat-SemiBoldItalic.woff2')
      format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-SemiBoldItalic.woff')
      format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-SemiBoldItalic.ttf')
      format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-path}/Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-SemiBold.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-path}/Montserrat/Montserrat-Regular.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-Regular.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DS-Digital';
  src: url('#{$font-path}/DS-Digital/DS-DIGI.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
