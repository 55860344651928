.map-score-bar {
  z-index: 101;
  position: absolute;
  top: 27px;
  left: 44px;

  display: flex;
  height: 56px;
  padding: 6px 30px;
  align-items: center;
  gap: 40px;
  border-radius: 36px;
  background: #5c36e2;
  box-shadow: 0px -10px 15px 0px rgba(0, 0, 0, 0.25) inset;

  width: 100%;
  max-width: 786px;

  .map-score-bar__daily-goal {
    width: 100%;
    max-width: 431px;
  }
}

@media (max-width: 1194px) {
  .map-score-bar {
    left: 34px;
    width: 545px;
    gap: 30px;
    padding: 6px 20px;

    .map-score-bar__daily-goal {
      width: 100%;
      max-width: 431px;

      .map-bar-tooltip__tooltip--center:before {
        right: initial;
        left: 40px;
      }
    }
  }
}

@media (max-width: 1150px) {
  .map-score-bar {
    left: 34px;
    width: 540px;
    gap: 30px;
    padding: 6px 20px;

    .map-score-bar__daily-goal {
      width: 100%;
      max-width: 431px;

      .map-bar-tooltip__tooltip--center:before {
        right: initial;
        left: 40px;
      }
    }
  }
}

@media (max-width: 940px) {
  .map-score-bar {
    width: 400px;
    gap: 10px;

    .map-score-bar__daily-goal {
      max-width: 190px;
    }
  }
}

@media (max-width: 765px) {
  .map-score-bar {
    left: 24px;
  }
}

@media (max-width: 520px) {
  .map-score-bar {
    width: 70%;
    display: flex;
    height: 40px;
    padding: 5px 17px;
    align-items: center;
    gap: 12px;
  }
}

@media screen and (max-width: 768px) and (min-width: 521px) {
  .map-score-bar {
    display: flex;
    height: 47px;
    padding: 5px 17px;
    align-items: center;
    gap: 12px;
  }
  .map-level-counter .map-level-counter-img {
    width: 29px;
    height: 31px;
    left: -3px;
    position: absolute;
  }
  .map-level-counter .map-level-counter-text {
    color: var(--Gray-scale-white, #fff);
    text-align: center;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;

    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    top: -21px;
    left: -6px;
  }
}
