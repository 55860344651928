// Component imports
@import './components/PricingLayout/pricingLayout';
@import './components/PricingPanel/pricingPanel';

.pricing {
    .pricing_logout {
      cursor: pointer;
      position: relative;
      z-index: 1;
      width: fit-content;

      font-family: $primary-font;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 6px;
      margin: 20px 0 0 20px;
      padding: 8px 16px;
      border-radius: 8px;
      box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.25);
      background-color: #3c3cbf;

      span {
        flex-grow: 0;
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        color: $white;
      }
    }

    .form-button {
      margin: 14px 0 0 14px;
      z-index: 1;
      position: relative;
      max-width: fit-content;
    }
}
