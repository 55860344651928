@use 'sass:color';

* {
  box-sizing: border-box;
}

.login-form {
  width: 100%;
  margin: 0 auto;
  max-width: 310px;

  form {
    width: 100%;
    display: block;
    padding: 0;
    margin: 0 auto;
  }

  .form-group {
    
    margin-top: -15px;

    &:nth-child(2) {
      margin-bottom: 6px;
    }

    .input-with-label label{
      margin-top: 8px;
      margin-bottom: 8px;
    }
    .input-with-label__right-icon {
      top: 46px;
    }
  }

  .submit-control {
    margin-top: 0;
    border-radius: 31px;
  }

  .btn {
    @include poppins-font($size: 14px, $weight: 600);
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    border-radius: 37px;
    transition: background-color 0.1s;
    width: 100%;
    height: auto;
    display: flex;
    font-size: 14px;
    margin-bottom: 20px;
    color: #ffffff;
    background-color: #5a34e1;

    &:hover {
      cursor: pointer;
      background-color: #5a34e1;
      padding: 12px 16px;
      border-radius: 37px;
      color: #ffffff;
    }

    &:active,
    &:target {
      position: relative;
      background-color: #5a34e1;
      padding: 12px 16px;
      border-radius: 37px;
      color: #ffffff;
    }

    &:disabled {
      pointer-events: none;
      border: none !important;
      color: #afb7c6;
      box-shadow: none !important;
      background: var(--Grays-gray-09, #f0f4f8);
    }
  }

  .forgot {
    display: flex;
    width: 408px;
    padding: 10px 28px;
    justify-content: center;
    align-items: center;
    gap: 9px;
    border-radius: 31px;
    color: #5a34e1;
    font-weight: bold;

    &:hover {
      color: #8181fd;
    }
  }

  .double-column {
    display: flex;
    justify-content: flex-start;
    margin: 8px 0 32px 0;
  }

  .errors {
    font-family: $secondary-font;
    text-align: center;
    font-size: 0.9em;
    color: $shadow-darkRed;
    margin-bottom: 2em;
    font-weight: bold;
  }

  .form-forgot-password {
    margin-top: 0px;
  }
}

.login-form_error-message {
  color: var(--Activity-Red, #ff3d30);
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  margin-top: -15px;
  margin-bottom: 16px;
}


@media screen and (max-width: 520px) {
  .login-form {
    padding: 2%;

    .forgot {
      width: 100%;
    }
  }
}
