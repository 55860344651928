@import '../../../../../assets/styles/common';

.unit-frame {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  width: 90vw;
  height: calc(90vh - 83px - 24px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background: $shadow-black-1;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  @include custom-scrollbar;


  .unit-frame_section-a,
  .unit-frame_section-b {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
  }

  .unit-frame_section-a {
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    .unit-image {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 0;
      overflow: hidden;

      .unit-image_lesson {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    .basic-button {
      margin-top: 20px;
      width: fit-content;
    }
  }

  .unit-frame_section-b {
    flex: 1;
    padding: 26px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.21);
    overflow: auto;
    @include custom-scrollbar;

    @keyframes unitFrameLessonsLoadingSpin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .unit-frame_lossons-loading {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      img {
        filter: invert(1);
        animation: unitFrameLessonsLoadingSpin 2s linear infinite;
        width: 30%;
        height: 30%;
        object-fit: contain;
      }
    }
  }

  @media (max-width: 870px) {
    .unit-frame_section-a {
      flex: 1 1 100%;
      min-height: 50%;
      max-height: 180px;
      padding-bottom: 0;
    }

    .unit-frame_section-b {
      flex: 1 1 100%;
      height: auto;
      overflow: hidden;
    }
  }

  .go-back-button {
    position: absolute;
    top: 21px;
    left: 25px;
    z-index: 1;
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;
    height: calc(100vh - 83px - 24px);
  }
}
