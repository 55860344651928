@use '../../../theme/index' as theme;
.inline-button {
  font-family: theme.$primary-font;
  color: theme.$primary-blueberry;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
