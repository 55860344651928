@import '../../../theme/index.scss';

// Variables locales para reutilización
$message-min-height: 82px;
$message-padding: 20px 10px;
$text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);

// Mixins para estilos compartidos
@mixin message-text-base {
  font-family: Poppins, sans-serif;
  color: $white;
  text-shadow: $text-shadow;
  font-weight: 700;
  z-index: 1;
}

.information-popup {
  &_message-wrapper {
    &-out {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 20px auto;
    }

    &-in {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 20px;
      background: {
        image: url('../../../assets/img/metaberry_ui/information-frame.svg');
        size: 100% 100%;
        repeat: no-repeat;
        position: center;
      }

      width: 100%;
      height: auto;
      min-height: 88px;
    }
  }

  &_message-text {
    @include message-text-base;
    padding: $message-padding;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;

    &_rewards {
      @include message-text-base;
      position: relative;
      overflow-wrap: break-word;
      width: 272px;
      text-align: center;
      padding: $message-padding;
      font-size: 18px;
      line-height: 29px;
      flex: 1;
      word-wrap: break-word;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 280px;
    }
  }

  &_message-audio-button {
    position: relative;
    z-index: 1;
    cursor: pointer;
    margin-left: 1em;
    min-width: 30px;
    height: 30px;
    display: flex;
  }
}

.small-padding {
  padding: {
    top: 6px;
    bottom: 6px;
  }
}

.reward-popup__title {
  .title-container-wrapper {
    .title-container-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 10px;
      width: fit-content;
      max-width: 100%;
      word-break: break-word;
    }
    > div {
      @include message-text-base;
      text-align: center;
      font-size: 20px;
      line-height: normal;
    }
  }
}

@media (max-width: 600px) {
  .information-popup_message-wrapper {
    &-out {
      margin-bottom: 12px;
      padding: 0px;
    }

    &-in {
      padding: 12px 24px;
      min-height: 78px;
    }
  }
}

@media (min-width: 601px) and (max-width: 1280px) {
  .information-popup_message-wrapper {
    &-out {
      margin-bottom: 12px;
      padding: 0px;
    }

    &-in {
      padding: 12px 24px;
      min-height: 84px;
    }
  }
}
