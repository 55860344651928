//ACTIVITY COLORS
$green: #00e19a;
$green-lemonade: #28c59d;
$light-green: #d3ffef;
$red: #ff3d30;
$light-red: #ffe2e1;

//SECONDARIES MAIN COLORS
$secondary-green: #bee072;
$dark-orange: #fea487;
$turquoise: #72d2cd;
$dark-blue: #9fc1fd;
$bordeaux: #c479b4;
$purple: #bdb1fb;
$light-blue: #a2e4fa;
$light-orange: #fdcb7d;

//SECONDARIES ALPHA COLORS
$secondary-alpha-green: rgba(190, 224, 114, 0.25);
$dark-alpha-orange: rgba(254, 164, 135, 0.25);
$turquoise-alpha: rgba(114, 210, 205, 0.25);
$dark-alpha-blue: rgba(159, 193, 253, 0.25);
$bordeaux-alpha: rgba(196, 121, 180, 0.25);
$purple-alpha: rgba(189, 177, 251, 0.25);
$light-alpha-blue: rgba(162, 228, 250, 0.25);
$light-alpha-orange: rgba(253, 203, 125, 0.25);

//SECONDARIES HOVER COLORS
$secondary-hover-green: #6c9e04;
$dark-hover-orange: #993f22;
$turquoise-hover: #18a59e;
$dark-hover-blue: #396cc8;
$bordeaux-hover: #bd41a3;
$purple-hover: #7864da;
$light-hover-blue: #329fc4;
$light-hover-orange: #d48203;
