@import '../../../assets/styles/common.scss';
@import '../../../theme/_media-queries.scss';

.player-waiting-teacher-practice {
  &.player-waiting-teacher-practice_item-list {
    padding: 0 1em;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 24px;
    height: 50px;
    min-height: fit-content;
    border-radius: 12px;
    background: $shadow-white-1;
    padding: 0 24px;
    width: 100%;

    .background-avatar {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 24px 8px 0;
      height: fit-content;      
      width: fit-content;

      .pompa,
      .pompa-full {
        height: 55px;
        position: relative;

        @media (max-width: $breakpoint-mobile) {
          height: 42px;
        }
      }

      .container-avatar,
      .container-avatar-full {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: 55px;

        @media (max-width: $breakpoint-mobile) {
          height: 42px;
        }

        .player-avatar,
        .player-avatar-full {
          height: 80%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .player-waiting-teacher-practice_item-name {
    color: $white-1;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 4px 0;
  }
}
