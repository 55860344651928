@import '../../../../../assets/styles/common';

.basic-button {
  display: inline-flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 36px;
  cursor: pointer;
  background-color: $white-1;
  color: $violet-light-1;

  span {
    font-size: 17px;
    padding-top: 3px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  &.basic-button_light {
    background-color: $white-1;
    color: $violet-light-1;

    &:active {
      color: $violet-dark-1;
    }
  }
}
