@import '../../../assets/styles/common';

.modal-with-timer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  font-family: Poppins, sans-serif;
  background-color: $shadow-black-1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;

  .modal-with-timer_content {
    display: flex;
    width: 95%;
    max-width: 518px;
    padding: 24px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;
    border-radius: 24px;
    background: $white;
    box-shadow: 0px 6px 24px 0px $shadow-black-1;

    .modal-with-timer_title {
      color: $grey-01;
      font-size: 20px;
      font-weight: 700;
    }

    .modal-with-timer_message {
      color: $grey-02;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    .modal-with-timer_controls {
      width: 100%;
      display: flex;
      justify-content: end;
      gap: 16px;
      padding-top: 24px;
      flex-wrap: wrap;

      .practice-button:first-child {
        border: 2px solid $primary-medium-blue;
      }
    }

    .modal-with-timer_countdown {
      width: calc(100% + 48px);
      border-radius: 0 0 24px 24px;
      margin: 0 -24px -24px -24px;
      padding: 16px 48px 16px 48px;
      background-color: $grey-09;
      color: $grey-02;
      text-align: right;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      text-align: right;

      .modal-with-timer_countdown_end {
        font-weight: 700;
        padding-left: 5px;
      }
    }
  }

  @media (min-width: 769px) {
    .modal-with-timer_controls {
      justify-content: center;
    }

    .modal-with-timer_content {
      width: 90%;
    }
  }
}
