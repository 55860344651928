.blueberry-family-panel {
  width: 460px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 32px 42px;
  border-radius: 16px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 255, 0.8);
  position: relative;

  @media only screen and (max-width: 460px) {
    width: 100%;
    border-radius: 0;
    padding: 32px 16px;
  }

  .blueberry-family-panel__content {
    width: 100%;
  }

  .blueberry-family-panel__logo {
    margin-top: 14px;
    margin-bottom: 32px;
  }

  .blueberry-family-panel__title {
    font-family: Quicksand, sans-serif;
    font-weight: bold;
    font-size: 24px;
    color: #1b1a26;
    margin-bottom: 32px;
    text-align: center;
    line-height: 28px;
  }

  .blueberry-family-panel__subtitle {
    color: #576B70;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  .blueberry-family-panel__text {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    margin-bottom: 32px;
  }

  .blueberry-family-panel__text-h4 {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #576B70;
    margin-bottom: 32px;
  }

  .blueberry-family-panel__info {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #6363FC;
    margin-top: 32px;
    margin-bottom: 40px;
  }

  .blueberry-family-panel__error {
    font-family: 'Poppins', sans-serif;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #FF3D30;
    margin-top: 12px;
    text-align: center;
  }

  .blueberry-family-panel__separator {
    width: 100%;
    height: 2px;
    background: rgba(195, 194, 207, 0.6);
    border-radius: 10px;
    margin: 32px 0;
  }

  .blueberry-family-panel__or-separator {
    width: 100%;
    margin: 32px 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    span:first-child,
    span:last-child {
      display: inline-block;
      height: 2px;
      background: rgba(195, 194, 207, 0.6);
      border-radius: 10px;
      width: 100%;
      margin: 0;
    }

    span {
      font-family: 'Quicksand', sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #576B70;
      margin: 0 12px;
    }

  }


  .blueberry-family-panel__actions {

    &.blueberry-family-panel__actions--row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .form-button {
        margin-right: 32px
      }

      .form-button:last-child {
        margin-right: 0
      }
    }

    &.blueberry-family-panel__actions--column {
      display: flex;
      flex-direction: column;

      .form-button {
        margin: 6px 0;

        &:first-child {
          margin-top: 0
        }

        &:last-child {
          margin-bottom: 0
        }
      }
    }
  }
}


.blueberry-family-home {
  .blueberry-family-panel__title {
    margin-bottom: 40px;
  }

  .blueberry-family-panel__home-img {
    position: absolute;
    bottom: 9px;
    right: -42px;
  }
}

.blueberry-family-select-profile {

  .blueberry-family-panel__welcome {
    position: absolute;
    top: -150px;
    left: -25px;

    div {
      position: absolute;
      bottom: 90px;
      left: 170px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 16px 13px;
      min-width: 260px;
      min-height: 46px;
      height: auto;
      background: #FFFFFF;
      border-radius: 11px 11px 11px 0px;
      font-family: 'Quicksand';
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;

      &:before {
        content: "";
        width: 0;
        height: 0;
        border-left: 21px solid transparent;
        border-right: 21px solid transparent;
        border-bottom: 13px solid #ffffff;
        position: absolute;
        left: -21px;
        bottom: 0;
      }
    }

    @media only screen and (max-width: 1000px) and (orientation: landscape) {
      display: none;
    }
  }

  .blueberry-family-panel__title {
    margin-bottom: 0;
  }

  // reajusta el margen si no se muestra el botón de perfil
  .blueberry-family-panel__title+div.blueberry-family-panel__actions {
    margin-top: 32px;
  }

  .blueberry-family-panel__profile-button {
    width: 100%;
    margin: 8px 0;
    display: flex;
    justify-content: flex-end;

    .form-button {
      width: auto;
      display: inline-flex;
      padding: 8px 0;
    }
  }

  .blueberry-family-panel__actions {
    &.blueberry-family-panel__actions--column {
      .form-button {
        margin: 8px 0;
      }
    }
  }

  &.blueberry-family-select-profile--no-profiles {
    .blueberry-family-panel__title {
      margin-bottom: 32px;
    }

    .blueberry-family-panel__text {
      text-align: center;
    }
  }
}

.blueberry-family-complete {
  display: flex;
  justify-content: center;
  align-items: center;

  .blueberry-family-layout__content {
    @media only screen and (max-width: 1000px) and (orientation: landscape) {
      overflow: auto;
      align-items: center;
      padding: 8px;
    }
  }


  .blueberry-family-panel__complete-img {
    position: absolute;
    top: -135px;
    left: -85px;

    @media only screen and (max-width: 635px) {
      top: -150px;
      left: 50%;
      transform: translate(-50%);
    }

    @media only screen and (max-height: 620px) {
      display: none;
    }

    @media only screen and (max-width: 1000px) and (orientation: landscape) {
      display: none;
    }

  }
}

.blueberry-family-subscription-result {
  .blueberry-family-panel__img {
    display: flex;
    justify-content: center;
    max-width: 100%;
    margin-bottom: 32px;

    .blueberry-family-panel__img--success {
      width: 60%;
    }

    .blueberry-family-panel__img--error {
      width: 40%;
    }
  }
}
