.toast-blueberry {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 28, 0.4);
  z-index: 14;

  .toast-blueberry_frame {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    top: 138px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 50%;
    height: fit-content;
    max-height: calc(100% - 32px);
    padding: 32px 44px;
    background-color: #ffffff;
    border-radius: 24px;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);
    font-family: Poppins, sans-serif;
    text-align: center;

    .toast-blueberry_icon {
      width: 32px;
      height: 32px;
      margin-bottom: 14px;
    }

    .toast-blueberry_title {
      color: #4d586c;
      font-family: Poppins, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .toast-blueberry_message {
      color: #7a8598;
      font-family: Poppins, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-top: 12px;
    }

    .toast-blueberry__close-button {
      position: absolute;
      top: -5px;
      right: -5px;
      width: 32px;
      height: 32px;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: #ff3d30;
      padding: 8px;

      img {
        width: 16px;
        height: 16px;
        object-fit: contain;
      }

      &:hover {
        background-color: #ff3333;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .toast-blueberry_frame {
      width: calc(100% - 32px);
    }
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    .toast-blueberry_frame {
      width: 70%;
    }
  }
}

@media screen and (max-width: 520px) {
  .toast-blueberry_frame {
    border-radius: 20px 20px 0 0;
    background: #fff;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.6);
    display: flex;
    width: 100vw;
    height: 239px;
    padding: 24px 24px 48px 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
}
