.avatar-data-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  flex-direction: column;
  margin-bottom: 0;

  .avatar-data-container__title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
  }

  .avatar-data-container__description {
    background-image: url('../../../../../assets/img/box-avatar-description.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 300px;
    min-height: 150px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .avatar-data-container__description-text {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      width: 60%;
      text-align: center;
    }
  }

  @media (max-width: 520px) {
    position: fixed;
    bottom: 80px;
    z-index: 999;
  }
}
.meta-popup-selector__button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;

  .meta-popup-selector__button {
    z-index: 1000;
    font-family: 'Poppins', sans-serif;
    font-style: medium;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #5a34e1;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;

    min-width: 164px;
    min-height: 39px;
    width: fit-content;
    margin: 0 auto;
    margin-top: 10px;

    background: #ffffff;
    border-radius: 18px;

    cursor: pointer;
  }
}

.meta-popup-selector__button-container-intro {
  margin-top: 0;
}

.information-popup_message-wrapper-out {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 68px;
  margin: 0 auto;
  left: 0;
  transform: none;
  margin-bottom: 12px;
  margin-top: 10px;
  max-width: 400px;
}

.information-popup_message-wrapper-in {
  height: auto;
  min-height: 68px;
}

@media (max-width: 520px) {
  .avatar-data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 232px;
    flex-shrink: 0;
    bottom: 0;
    left: 10px;
    width: 96%;
    border-radius: 8px;
    background: #683fac;
    box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.25),
      0px 2px 0px 0px #9940c3 inset;
    font-size: 16px;

    .avatar-data__decoration {
      width: 274px;
      height: 273px;
      border-radius: 274px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(252, 90, 255, 0.5) 0%,
        rgba(198, 106, 255, 0) 100%
      );
      position: absolute;
      z-index: 0;
    }

    .information-popup_message-wrapper-out {
      position: relative;
      width: 100%;
      height: auto;
      min-height: 68px;
      margin: 0 auto;
      left: 0;
      transform: none;
      margin-bottom: 12px;
      margin-top: 20px;
      max-width: 400px;
    }

    .information-popup_message-wrapper-in {
      height: auto;
      min-height: 68px;
    }
  }
}

@media (max-width: 520px) and (max-height: 1080px) {
  .meta-popup-selector__button-container {
    .meta-popup-selector__button {
    }
  }
}
