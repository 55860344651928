@use '../../../../theme/index' as theme;

.input-with-label {
  position: relative;
}

.input-with-label label {
  color: var(--Neutral-Grays-Gray-01, #303949);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-top: 24px;
  margin-bottom: 10px;
}

.input-with-label input {
  display: block;
  @include theme.poppins-font();
  color: theme.$gray-1;
  padding: 10px 6px;

  margin: 0 auto 1.5em;
  width: 100%;
  border-radius: 6px;
  border: 1px solid theme.$form-line-color;

  &:focus {
    outline: none;
    border: 1px solid #5a34e1;
  }

  &::placeholder {
    color: var(--Neutral-Grays-Gray-06, #afb7c6);
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
}

.input-with-label__right-icon {
  position: absolute;
  top: 64px;
  right: 8px;
  cursor: pointer;
}

.input-with-label.error input {
  border-color: theme.$red;
}
