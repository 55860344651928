  .meta-options-menu-toast {
    display: inline-flex;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
    width: 389px;
    animation: fadeInOut 3s;

    .meta-options-menu-toast__icon {
      width: 31px;
      height: 37px;
      background: #00E19A;
      border-radius: 8px 0px 0px 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .meta-options-menu-toast__text {
      font-family: 'Quicksand';
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #FFFFFF;
      padding: 0px 15px 0px 15px;
      display: flex;
      align-items: center;
      background: rgba(28, 41, 43, 0.75);
      border-radius: 0px 8px 8px 0px;
      flex-grow: 1;
    }
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    70% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
