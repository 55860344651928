.form-button__recover-password {
  color: #fff;
}
.email-sent {
  .submit-control {
    border-radius: 36px;
    background: var(--Blueberry-Primary-Medium-blue, #5a34e1);
    display: flex;
    padding: 6px 6px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    .form_button {
    }
    .form-button__text {
      color: var(--Grays-white, #fff);
      font-family: Poppins, sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }
}
