.activity-blueberry-component {
  select {
    -webkit-appearance: none;
  }

  input,
  label,
  select,
  button,
  textarea {
    margin: 0;
    border: 0;
    padding: 0;
    font-size: inherit;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    background: none;
    line-height: 1;
  }

  /* These elements are usually rendered a certain way by the browser */

  button,
  input[type='reset'],
  input[type='button'],
  input[type='submit'],
  input[type='checkbox'],
  input[type='radio'],
  select {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}
