.map-level-progress {
  display: flex;
  align-items: center;
  justify-content: space-between;


  .map-level-progress__progress-bar {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    box-sizing: border-box;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 254px;

    .map-level-progress__progress-bar-text {
      font-family: Poppins, sans serif;
      color: #FFFFFF;
      text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.20);
      font-size: 14px;
      font-weight: 700;
      position: absolute;
      z-index: 1;
      left: 50%;
      transform: translate(-50%, 0);
      margin-top: 1px;
    }

    .map-level-progress__progress-bar-bg {
      width: 100%;
      height: 30px;
      border-radius: 18px;
      background: linear-gradient(180deg, #3A1587 9%, #490DC8 99.99%, #3703A4 100%);
      position: relative;
    }

    .map-level-progress__progress-bar-progress {
      background: linear-gradient(180deg, #7C3DE5 22.56%, #3C3CBF 100%);
      height: 30px;
      border-radius: 22px;
      position: absolute;
      left: 0;
      transition: width ease-in-out 1s;
    }

  }

}
