@import '../../../../assets/styles/common';

.course-progress-container-wrapper {
  padding-right: 6px;
  padding-bottom: 6px;
  height: 100%;
  overflow: hidden;
}

.course-progress-container {
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  width: 90vw;
  height: calc(90vh - 83px - 24px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.25);
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding-top: 0px;

  @media only screen and (max-width: 768px) {
    width: 100vw;
    height: calc(100vh - 83px - 83px);
  }

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track-piece {
    width: initial;
    height: initial;
  }

  html body & {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track:horizontal {
      height: 5px;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      height: 5px;
    }

    &::-webkit-scrollbar-track-piece:horizontal {
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $white-1;
      border-radius: 12px;
    }
  }

  @-moz-document url-prefix() {
    // Ajustes para la barra de desplazamiento firefox
    scrollbar-width: thin;
    scrollbar-color: $white-1 transparent;
  }

  @media only screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    justify-items: center;
    align-items: start;
    padding: 15px;
  }

  @media only screen and (max-width: 600px) and (min-width: 359px) {
    grid-template-columns: 1fr;
    gap: 10px;
    height: calc(90% - 6px);
  }
}
