@import '../../../../../theme/colors';

.issue-report {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 28, 0.4);
  z-index: 102;

  .issue-report_frame {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: fit-content;
    max-height: calc(100% - 32px);
    padding: 32px 44px;
    background-color: #ffffff;
    border-radius: 24px;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);
    font-family: Poppins;
    text-align: center;

    .issue-report_header {
      display: flex;
      padding-bottom: 24px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      .issue-report_header_title {
        color: #303949;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .issue-report_header_close_x {
        width: 24px;
        height: 24px;
        margin-right: 5px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .issue-report_body {
      overflow-y: auto;
      padding-right: 6px;

      .issue-report_error-types {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 24px;
        padding-top: 24px;

        .issue-report_error-types_checkbox-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .issue-report_error-types_checkbox-text {
          flex: 1;
          text-align: left;
          color: #7a8598;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          cursor: pointer;

          &.issue-report_error-types_checkbox-text-checked {
            color: #303949;
            font-weight: 600;
          }
        }

        .issue-report_error-types_checkbox-box {
          width: 22px;
          height: 22px;
          cursor: pointer;
        }
      }

      .issue-report_user-comment {
        width: 100%;
        padding: 12px;
        height: 87px;
        overflow: auto;
        resize: none;
        color: #4d586c;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        display: flex;
        border-radius: 8px;
        border: 1px solid #afb7c6;
        margin-top: 24px;

        &:focus {
          outline: none;
          border-width: 2px;
          border-color: $gray-1;
        }

        &.issue-report-without-comment {
          border-color: $red;
        }

        &::placeholder {
          color: #afb7c6;
        }
      }
    }

    .issue-report__action-buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      gap: 16px;
      padding: 48px 0 0 0;

      @media screen and (max-width: 359px) {
        gap: 8px;
      }

      .practice-button {
        max-width: 148px;
        min-width: 148px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .issue-report_frame {
      width: calc(100% - 32px);
    }
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    .issue-report_frame {
      width: 70%;
    }
  }
}

@media screen and (max-width: 520px) {
  .issue-report {
    position: fixed;
    height: 100vh;
    border-radius: 24px;
    background: #ffffff;
    .issue-report_frame {
      width: 100vw;
      height: 100vh;
      border-radius: 0;
      box-shadow: none;

      .issue-report_body
        .issue-report_error-types
        .issue-report_error-types_checkbox-text {
        color: var(--Grays-gray-04, #7a8598);
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }
    }
  }
}
