.meta-options-menu-drawer__backdrop,
.meta-options-menu-drawer-new__backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(28, 41, 43, 0.5);
  z-index: 1000;
}

.meta-options-menu-drawer,
.meta-options-menu-drawer-new {
  // height: calc(100% - 222px);
  height: auto;
  width: 160px;
  background: var(--ui-bar-background);
  position: fixed;
  top: 88px;
  transition: right 0.5s ease-in-out;
  opacity: 0;
  right: -300px;
  border-radius: 8px;
  padding: 20px 0;
  z-index: 1002;
  display: none;

  .meta-options-menu-drawer__content,
  .meta-options-menu-drawer-new__content {
    font-family: $primary-font;

    .meta-options-menu-drawer__tab,
    .meta-options-menu-drawer-new__tab {
      cursor: pointer;
      padding: 4px 18px;
      margin-bottom: 16px;
      color: var(--ui-tab-text-color);
      font-size: 18px;
      min-height: 31px;
      display: flex;
      align-items: center;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .meta-options-menu-drawer__tab--active,
    .meta-options-menu-drawer-new__tab--active {
      background: #ffaf22;
      color: #1c292b;
    }
  }
}

.meta-options-menu-drawer--open,
.meta-options-menu-drawer-new--open {
  right: 25px;
  transition: right 0.5s ease-in-out 0.1s, opacity 0.1s linear;
  opacity: 1;
  display: block;
}

.meta-options-menu-drawer__tab-content-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  height: 90%;
  width: 90vw;
  max-width: 1030px;
  z-index: 1001;
  transition: right 0.5s ease-in-out;
  right: -100vw;
  opacity: 0;
  padding: 24px 30px 60px 24px;
  display: none;

  .meta-options-menu-drawer__tab-content-container-close-button {
    position: absolute;
    top: 16px;
    right: 24px;
    width: 54px;
    height: 54px;
    cursor: pointer;
    background-color: #9940c3;
    border-radius: 50%;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;

    svg path {
      fill: #ffffff;
    }
  }

  &.meta-options-menu-drawer__avatar {
    max-height: 480px;
  }

  > div.meta-options-menu-drawer__tab_content {
    background-color: #fff;
    border-radius: 16px;
    height: 100%;
    background-image: url(../../../assets/img/metaberry_ui/computer_bg.png);
    background-position: center;
    background-size: cover;
  }
}

.meta-options-menu-drawer-new__tab-content-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 55px 0px rgba(0, 0, 0, 0.25) inset,
    0px 4px 45px 0px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  height: 90%;
  width: 90vw;
  max-width: 1030px;
  z-index: 1001;
  transition: right 0.5s ease-in-out;
  right: -100vw;
  opacity: 0;
  display: none;

  .meta-options-menu-drawer-new__tab-content-container-close-button {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 54px;
    height: 54px;
    cursor: pointer;
    background-color: #9940c3;
    border-radius: 50%;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 17px;
      height: 17px;
      fill: #ffffff;
    }
  }

  &.meta-options-menu-drawer-new__avatar {
    max-height: 480px;
  }

  > div.meta-options-menu-drawer-new__tab_content {
    background-color: #fff;
    border-radius: 16px;
    height: 100%;
    background-image: url(../../../assets/img/metaberry_ui/computer_bg.png);
    background-position: center;
    background-size: cover;
  }
}

.meta-options-menu-drawer__tab-content-container--open,
.meta-options-menu-drawer-new__tab-content-container--open {
  right: 210px;
  transition: right 0.5s ease-in-out 0.1s, opacity 0.1s linear;
  opacity: 1;
  display: block;
}

.meta-options-menu-drawer__close-button,
.meta-options-menu-drawer-new__close-button {
  position: fixed;
  z-index: 1002;
  height: 50px;
  width: 50px;
  bottom: 30px;
  right: 34px;
  cursor: pointer;
}

@media screen and (max-width: 520px) {
  .meta-options-menu-drawer__tab-content-container--open,
  .meta-options-menu-drawer-new__tab-content-container--open {
    position: fixed;
    width: 100vw;
    height: 100vh;
    right: 0;
    top: 0;
    transform: none;
    left: 0;
    max-width: none;
  }

  .meta-options-menu-drawer-new__tab-content-container
    .meta-options-menu-drawer-new__tab-content-container-close-button {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 9px 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    top: 14px;
    right: 16px;
  }

  .meta-options-menu-drawer-new__tab-content-container
    .meta-options-menu-drawer-new__tab-content-container-close-button
    svg {
    display: flex;
    width: 13px;
    height: 13px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
}
