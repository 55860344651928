.abort-practice {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 28, 0.4);
  z-index: 200;

  .abort-practice_frame {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 390px;
    width: calc(100% - 16px);
    height: fit-content;
    padding: 0 16px;
    background-color: #ffffff;
    border-radius: 24px;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);
    font-family: Poppins;
    text-align: center;

    .abort-practice_message {
      .abort-practice_message-title {
        color: #303949;
        font-size: 20px;
        font-weight: 700;
        padding-top: 24px;
        line-height: normal;
      }

      .abort-practice_message-text-1 {
        color: #4d586c;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-top: 24px;
      }

      .abort-practice_message-text-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        color: #4d586c;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;

        .abort-practice_message-text-2-experience {
          font-weight: 700;
          line-height: 24px;
          color: #5a34e1;
          padding-left: 6px;
        }

        img {
          display: inline;
          height: 22px;
          padding: 0 6px;
        }
      }
    }

    .abort-practice__action-buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      gap: 16px;
      padding: 24px 0;

      @media screen and (max-width: 359px) {
        gap: 8px;        
      }

      .practice-button {
        max-width: 148px;
        min-width: 148px;
      }
    }
  }
}
