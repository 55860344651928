@import '../../../assets/styles/blueberry.scss';

.activity-blueberry-component {
  .activity-simple-button {
    @include activity-primary-font;

    font-style: normal;
    font-weight: bold;

    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 16px;

    &:hover {
      cursor: pointer;
    }

    &:active,
    &:target {
      box-shadow: inset 0 4px 0 map-get($activity-colors, shadow-02) !important;
      position: relative;
      top: 4px;
    }

    .activity-simple-button__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    // size

    &.button--size-default {
      height: 43px;
      font-size: 18px;
      line-height: 22px;
    }

    &.button--size-small {
      height: 39px;
      font-size: 18px;
      line-height: 22px;
    }

    &.button--size-large {
      height: 67px;
      font-size: 28px;
      line-height: 35px;
    }

    // display

    &.button--display-default {
      width: 100%;
      height: auto;
      display: flex;
    }

    &.button--display-inline {
      width: auto;
      display: inline-flex;
    }

    // variants and colors

    &.button-variant--default {
      color: map-get($activity-colors, white-bb);
    }

    &.button--color-default {
      border: 1px solid map-get($activity-colors, primary-medium);
      background-color: map-get($activity-colors, primary-medium);
      box-shadow: 0 4px 0 map-get($activity-colors, primary-dark);
    }

    &.button--color-red {
      border: 1px solid map-get($activity-colors, red-bb);
      background-color: map-get($activity-colors, red-bb);
      box-shadow: 0 4px 0 map-get($activity-colors, red-dark);
      background-color: green;
    }

    &.button--color-green {
      border: 1px solid map-get($activity-colors, green-bb);
      background-color: map-get($activity-colors, green-bb);
      box-shadow: 0 4px 0 map-get($activity-colors, green-dark);
    }

    &.button-variant--outline {
      border: 1px solid map-get($activity-colors, gray-05);
      background-color: map-get($activity-colors, white-bb);
    }

    &.button--color-default {
      border: 1px solid map-get($activity-colors, primary-medium);
      box-shadow: 0 4px 0 map-get($activity-colors, primary-dark);
      color: map-get($activity-colors, primary-medium);
      &:active,
      &:target {
        box-shadow: inset 0 4px 0
          rgba(map-get($activity-colors, primary-dark), 0.4) !important;
      }
    }

    &.button--color-red {
      border: 1px solid map-get($activity-colors, red-bb);
      box-shadow: 0 4px 0 map-get($activity-colors, red-dark);
      color: map-get($activity-colors, red-bb);
      &:active,
      &:target {
        box-shadow: inset 0 4px 0 rgba(map-get($activity-colors, red-dark), 0.4) !important;
      }
    }

    &.button--color-green {
      border: 1px solid map-get($activity-colors, green-bb);
      box-shadow: 0 4px 0 map-get($activity-colors, green-dark);
      color: map-get($activity-colors, green-bb);
      &:active,
      &:target {
        box-shadow: inset 0 4px 0
          rgba(map-get($activity-colors, green-dark), 0.4) !important;
      }
    }

    &.button-variant--minimal {
      border-radius: 1em;
      padding: 0.2em 1em;
      background-color: map-get($activity-colors, white-bb);
      color: map-get($activity-colors, white-bb);
      box-shadow: none !important;

      &:active,
      &:target {
        filter: brightness(85%);
      }
    }

    &.button--color-default {
      color: map-get($activity-colors, gray-03);
    }

    &.button--color-red {
      background-color: map-get($activity-colors, red-bb);
    }

    &.button--color-green {
      background-color: map-get($activity-colors, green-bb);
    }

    // disabled

    &.disabled {
      pointer-events: none;
      border: none !important;
      color: map-get($activity-colors, white-bb) !important;
      background-color: map-get($activity-colors, gray-05) !important;
      box-shadow: none !important;

      &.button-variant--outline {
        border: 1px solid map-get($activity-colors, gray-03) !important;
        color: map-get($activity-colors, gray-03) !important;
        background-color: map-get($activity-colors, white-bb) !important;
        box-shadow: none !important;
      }
    }
  }
}
