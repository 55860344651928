.form-steps {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin: 0 auto 32px;

  .form-steps__step {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    margin: 0 4px;
    background: #FFFFFF;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.15);
  }

  .form-steps__step--active {
    background: #6363FC;
    box-shadow: none;
  }
}
