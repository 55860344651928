@use 'sass:color';

* {
  box-sizing: border-box;
}

.email-form {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  form {
    width: 100%;
    display: block;
    padding: 0;
    margin: 0 auto;
  }

  .form-group {
    margin-bottom: 32px;
  }

  .submit-control {
    margin-top: 24px;
  }

  .form-title {
    color: var(--Grays-gray-02, #4d586c);
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .form-text {
    color: var(--Grays-gray-04, #7a8598);
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .btn {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    border-radius: 8px;

    background-color: #5a34e1;
    transition: background-color 0.1s;

    width: 100%;
    height: auto;
    display: flex;

    &:hover {
      cursor: pointer;
      background-color: #8181fd;
    }

    &:active,
    &:target {
      box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.25) !important;
      position: relative;
      top: 4px;
    }

    &:disabled {
      pointer-events: none;
      border: none !important;
      color: #fff !important;
      background-color: #acacf7 !important;
      box-shadow: none !important;
    }
  }

  .input-with-label input {
    margin: 0 auto 6px;
  }

  .form-input-error {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #ff3d30;
  }
}
